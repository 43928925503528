import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Cup from '../images/sprite/cup.gif';

export default function Rightside(props) {
  const [buttonStake, setbuttonStake] = useState(true);
  const [ssstake1, setSsstake1] = useState(0);
  const [ssstake2, setSsstake2] = useState(0);
  const [ssstake3, setSsstake3] = useState(0);
  const [ssstake4, setSsstake4] = useState(0);
  const [ssstake5, setSsstake5] = useState(0);
  const [ssstake6, setSsstake6] = useState(0);
  const [ssstake7, setSsstake7] = useState(0);
  const [ssstake8, setSsstake8] = useState(0);
  const [defaultStake, setdefaultStake] = useState('');
  const [Switch, setSwitch] = useState(true)
  const [Switch2, setSwitch2] = useState(true)
  const [Switch3, setSwitch3] = useState(true)
  const [Switch4, setSwitch4] = useState(true)




  const handleSwitch = () => {
    setSwitch(!Switch)
  }

  const handleSwitch2 = () => {
    setSwitch2(!Switch2)
  }
  const handleSwitch3 = () => {
    setSwitch3(!Switch3)
  }
  const handleSwitch4 = () => {
    setSwitch4(!Switch4)
  }
  useEffect(() => {

    axios.post('https://liveapi247.live/api1/ssgetclickstake', {

      id: props.userid,
      token: props.utoken,

    })
      .then(result => {
        if (result.status === 210) {


          setdefaultStake(result.data[0].defaultStake);
          setSsstake1(result.data[0].SSstake1);
          setSsstake2(result.data[0].SSstake2);
          setSsstake3(result.data[0].SSstake3);
          setSsstake4(result.data[0].SSstake4);
          setSsstake5(result.data[0].SSstake5);
          setSsstake6(result.data[0].SSstake6);
          setSsstake7(result.data[0].SSstake7);
          setSsstake8(result.data[0].SSstake8);

        }

      }

      ).catch(e => {
        //setIsError(true);
      });
  }, [])

  const handleEditClick = () => {
    // seteditStake(true);
    setbuttonStake(false);
  };

  const hadleSaveClick = (e) => {


    e.preventDefault();

    setbuttonStake(true);
  };

  const handleSaveClick = (e) => {


    e.preventDefault();

    setbuttonStake(true);

    // console.log('userid', props.userid);
    // console.log('token', props.utoken);
    axios.post('https://liveapi247.live/api1/sssaveclickstake', {
      id: props.userid,
      token: props.utoken,
      defaultStake: defaultStake,
      ssstake1: ssstake1,
      ssstake2: ssstake2,
      ssstake3: ssstake3,
      ssstake4: ssstake4,
      ssstake5: ssstake5,
      ssstake6: ssstake6,
      ssstake7: ssstake7,
      ssstake8: ssstake8,

    })
      .then(result => {

        if (result.status === 210) {
          // toast.warn('Bet stake settings saved succefully', {position:toast.POSITION.TOP_CENTER});

          // console.log('Updated successful')

        }

        if (result.status === 220) {
          // console.log('saveclick unsucessful');


        }
      }

      ).catch(e => {
        //setIsError(true);
      });
  };
  const handleChange1 = (event) => {
    setSsstake1(event.target.value);
  };

  const handleChange2 = (event) => {
    setSsstake2(event.target.value);
  };
  const handleChange3 = (event) => {
    setSsstake3(event.target.value);
  };
  const handleChange4 = (event) => {
    setSsstake4(event.target.value);
  };
  const handleChange5 = (event) => {
    setSsstake5(event.target.value);
  };

  const handleChange6 = (event) => {
    setSsstake6(event.target.value);
  };
  const handleChange7 = (event) => {
    setSsstake7(event.target.value);
  };
  const handleChange8 = (event) => {
    setSsstake8(event.target.value);
  };
  const changeDefaultStake = (event) => {
    // console.log('test');
    setdefaultStake(event.target.value);
  };


  return (
    <div>
      <div className="overlay right-side" id="settingDiv" style={{ display: 'block' }}>
        <div className="side-wrap setting-tab" id="settingSlide" >
          <div className="side-head">
            <h3 className="a-setting"><img src={Cup} alt="gif" />Setting</h3>
            <a className="close ui-link" onClick={props.handlerightsideclose} id="settingClose">test</a>
          </div>
          <div id="coinList" className="side-content">
            <h3 _ngcontent-njs-c67="" >Stake</h3>
            <dl _ngcontent-njs-c67="" className="setting-block">
              <dt _ngcontent-njs-c67="" >Default stake<input type="number" id="userCoin" onChange={(e) => { changeDefaultStake(e); }} value={defaultStake} pattern="[0-9]*" data-role="none" /></dt>
            </dl>

            {buttonStake === true &&
              <dl id="stakeSet" className="setting-block stake-setting">
                <dt _ngcontent-njs-c67="" >Quick Stakes</dt>
                <dd _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" id="coin_1" className="btn ui-link select"   >{ssstake1}</a></dd>
                <dd _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" id="coin_2" className="btn ui-link select"   >{ssstake2}</a></dd>
                <dd _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" id="coin_3" className="btn ui-link select"   >{ssstake3}</a></dd>
                <dd _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" id="coin_4" className="btn ui-link select"   >{ssstake4}</a></dd>
                <dd _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" id="coin_5" className="btn ui-link select"   >{ssstake5}</a></dd>
                <dd _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" id="coin_6" className="btn ui-link select"   >{ssstake6}</a></dd>
                <dd _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" id="coin_7" className="btn ui-link select"   >{ssstake7}</a></dd>
                <dd _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" id="coin_8" className="btn ui-link select"   >{ssstake8}</a></dd>
                <dd className="col-stake_edit"><a _ngcontent-njs-c67="" id="edit" className="btn-edit ui-link" onClick={(e) => { handleEditClick(e); }}>Edit Stakes</a></dd>
              </dl>}

            {buttonStake === false &&
              <dl id="editCustomizeStakeList" className="setting-block stake-setting" >
                <dt _ngcontent-njs-c67="" >Quick Stakes</dt>
                <dd><div _ngcontent-njs-c67="" className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset"><input _ngcontent-njs-c67="" id="stakeEdit_1" onChange={(e) => { handleChange1(e); }} type="text" value={ssstake1} pattern="[0-9]*" /></div></dd>
                <dd><div _ngcontent-njs-c67="" className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset"><input _ngcontent-njs-c67="" id="stakeEdit_2" onChange={(e) => { handleChange2(e); }} type="text" value={ssstake2} pattern="[0-9]*" /></div></dd>
                <dd><div _ngcontent-njs-c67="" className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset"><input _ngcontent-njs-c67="" id="stakeEdit_3" onChange={(e) => { handleChange3(e); }} type="text" value={ssstake3} pattern="[0-9]*" /></div></dd>
                <dd><div _ngcontent-njs-c67="" className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset"><input _ngcontent-njs-c67="" id="stakeEdit_4" onChange={(e) => { handleChange4(e); }} type="text" value={ssstake4} pattern="[0-9]*" /></div></dd>
                <dd><div _ngcontent-njs-c67="" className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset"><input _ngcontent-njs-c67="" id="stakeEdit_5" onChange={(e) => { handleChange5(e); }} type="text" value={ssstake5} pattern="[0-9]*" /></div></dd>
                <dd><div _ngcontent-njs-c67="" className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset"><input _ngcontent-njs-c67="" id="stakeEdit_6" onChange={(e) => { handleChange6(e); }} type="text" value={ssstake6} pattern="[0-9]*" /></div></dd>
                <dd><div _ngcontent-njs-c67="" className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset"><input _ngcontent-njs-c67="" id="stakeEdit_7" onChange={(e) => { handleChange7(e); }} type="text" value={ssstake7} pattern="[0-9]*" /></div></dd>
                <dd><div _ngcontent-njs-c67="" className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset"><input _ngcontent-njs-c67="" id="stakeEdit_8" onChange={(e) => { handleChange8(e); }} type="text" value={ssstake8} pattern="[0-9]*" /></div></dd>
                <dd className="col-stake_edit"><a _ngcontent-njs-c67="" id="ok" onClick={(e) => { hadleSaveClick(e); }} className="btn-send ui-link"   >OK</a></dd>
              </dl>
            }
            <h3 _ngcontent-njs-c67="" >Odds</h3>
            <dl _ngcontent-njs-c67="" className="setting-block">
              <dt _ngcontent-njs-c67="" >Highlight when odds change</dt>
              <dd>
                <a onClick={handleSwitch2} className={`switch${Switch2 ? "_on" : "_off"}`} id="enableSparkCheck"><input type="hidden" id="sparkValue" value="1" /><span></span></a></dd>
            </dl>

            <h3 _ngcontent-njs-c67="" >FancyBet</h3>
            <dl _ngcontent-njs-c67="" className="setting-block">
              <dt _ngcontent-njs-c67="" >Accept Any Odds</dt>
              <dd _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" id="fancyBetAcceptAnyOddsCheckBox" onClick={handleSwitch} className={`switch${Switch ? "_on" : "_off"}`}   ><span></span></a></dd>
            </dl>

            <h3 _ngcontent-njs-c67="" >SportsBook</h3>
            <dl _ngcontent-njs-c67="" className="setting-block">
              <dt _ngcontent-njs-c67="" >Accept Any Odds</dt>
              <dd _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" id="fancyBetAcceptAnyOddsCheckBox" onClick={handleSwitch3} className={`switch${Switch3 ? "_on" : "_off"}`}   ><span></span></a></dd>
            </dl>

            <h3 _ngcontent-njs-c67="" >Binary</h3>
            <dl _ngcontent-njs-c67="" className="setting-block">
              <dt _ngcontent-njs-c67="" >Accept Any Odds</dt>
              <dd _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" id="fancyBetAcceptAnyOddsCheckBox" onClick={handleSwitch4} className={`switch${Switch4 ? "_on" : "_off"}`}   ><span></span></a></dd>
            </dl>



            <ul _ngcontent-njs-c67="" className="btn-list">
              <li _ngcontent-njs-c67="" >
                <a _ngcontent-njs-c67="" id="settingCancel" onClick={props.handlerightsideclose} className="btn ui-link">Cancel</a>
              </li>
              <li _ngcontent-njs-c67="" >
                <a _ngcontent-njs-c67="" id="settingSave" onClick={(e) => { handleSaveClick(e); props.handlerightsideclose() }} className="btn-send ui-link">Save</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
