
import React, { useEffect, useState, memo, lazy, Suspense } from 'react'
import ConfirmBet from './ConfirmBet'
import FancyBook from './Fancybook'
import Axios from 'axios';
import Transparent from '../images/sprite/transparent.gif';
import moment from 'moment';
import Cookies from 'universal-cookie';
import { marketKeys, soketIoMarketKey, soketEndPoint, exposurePremiumLogic, pushAndUpdateReUseArrListObjValue } from './utils';
import { useList } from "react-use";

import { useQueryClient } from '@tanstack/react-query';

// components
// import Tvstream from './components/Livetvscore/Tvstream';
import RunnerHeader from './components/runner-header/runner-header';
import RunnerRow from './components/runner-row/runner-row';
import PlaceBetKeybord from './components/place-bet-keybord/place-bet-keybord';
import LoadingOverlay from './components/loading-overlay/loading-overlay';
import { useMutation } from "@tanstack/react-query";
import { refreshBalanceApiRq } from "../serives/react-query/api-actions";
import PremiumHeader from "./components/premium-header/premium-header";
import PremiumRunnerRow from "./components/premium-runner-row/premium-runner-row";
import { useHistory } from "react-router-dom";
import TabButtons from "./components/tab-buttons/tab-buttons";

require('moment-timezone');

const cookies = new Cookies();
var matchEndedMarket = false;
var matchEndedBook = false;
var matchEndedManual = false;
var matchEndedComMatch = false;
var matchEndedSuperMatch = false;
var matchEndedTieMatch = false;

cookies.set('dupBet', "2", { path: '/' });


const io = require('socket.io-client');

var socket;


// lazy components
const Tvstream = lazy(() => import('./components/Livetvscore/Tvstream'));

export const Newfullmarket = (props) => {
    const history = useHistory();
    const [defaultStakesFromApi, setDefaultStakesFromApi] = useState({});
    const [Maxstake, setMaxstake] = useState(false)
    const [Bookstake, setBookstake] = useState(false)
    const [Fancypin, setFancypin] = useState(false)
    const [bookpin, setbookpin] = useState(false)
    const [marketData, setmarketData] = useState({});
    const [fancyData, setfancyData] = useState([])
    const [defaultStake, setdefaultStake] = useState('');
    const [dStake, setdStake] = useState('');
    const [selectFancy, setselectFancy] = useState(null)
    const [bookData, setbookData] = useState({})
    const [selectFancyLay, setselectFancyLay] = useState(true)
    const [click, setclick] = useState(-1);
    const [bOdds, setbOdds] = useState(' ')
    const [mOdds, setmOdds] = useState(' ')
    const [Maodds, setMaodds] = useState(' ')
    const [COdds, setCOdds] = useState(' ')
    const [SOdds, setSOdds] = useState(' ')
    const [TOdds, setTOdds] = useState(' ')
    const [oddsType, setoddsType] = useState('Back')
    const [teamName, setteamName] = useState('A')
    const [bookData2, setbookData2] = useState({})
    const [field, setfield] = useState(0);

    const [matchOddClickKeybordRunner, setMatchOddClickKeybordRunner] = useState(null);
    const [selectMatchBack, setselectMatchBack] = useState(null)
    const [selectBack, setselectBack] = useState(null)

    const [showConfirmBet, setshowConfirmBet] = useState(false)
    const [showFancyBook, setshowFancyBook] = useState(false)
    const [fancySelection, setfancySelection] = useState(null)
    const [fancyRunner, setfancyRunner] = useState(null)

    const [Manualclick, setManualclick] = useState(false)
    const [Manualclick2, setManualclick2] = useState(false)
    const [Manualclick3, setManualclick3] = useState(false)
    const [SuperMatchclick, setSuperMatchclick] = useState(false)
    const [SuperMatchclick2, setSuperMatchclick2] = useState(false)
    const [SuperMatchclick3, setSuperMatchclick3] = useState(false)
    const [ComMatchclick, setComMatchclick] = useState(false)
    const [ComMatchclick2, setComMatchclick2] = useState(false)
    const [ComMatchclick3, setComMatchclick3] = useState(false)
    const [tieMatchClickKeybordRunner, setTieMatchClickKeybordRunner] = useState(null);
    const [comMatchClickKeybordRunner, setComMatchClickKeybordRunner] = useState(null);
    const [superMatchClickKeybordRunner, setSuperMatchClickKeybordRunner] = useState(null);
    const [WintossMatchClickKeybordRunner, setWintossMatchClickKeybordRunner] = useState(null);
    const [bookdataMatchClickKeybordRunner, setBookdataMatchClickKeybordRunner] = useState(null);
    const [selectManualBack, setselectManualBack] = useState(null)
    const [selectSuperMatchBack, setselectSuperMatchBack] = useState(null)
    const [selectCompMatchBack, setselectCompMatchBack] = useState(null)
    const [selectTieMatchBack, setselectTieMatchBack] = useState(null)

    const [showTieConfirmBet, setshowTieConfirmBet] = useState(false)
    const [showComConfirmBet, setshowComConfirmBet] = useState(false)
    const [showSupConfirmBet, setshowSupConfirmBet] = useState(false)
    const [showTossConfirmBet, setshowTossConfirmBet] = useState(false)

    const [pnlManual2, setpnlManual2] = useState({})
    const [pnlManual, setpnlManual] = useState({})

    const [pnlSuperMatch2, setpnlSuperMatch2] = useState({})
    const [pnlSuperMatch, setpnlSuperMatch] = useState({})
    const [pnlCompMatch2, setpnlCompMatch2] = useState({})
    const [pnlCompMatch, setpnlCompMatch] = useState({})
    const [pnlTieMatch2, setpnlTieMatch2] = useState({})
    const [pnlTieMatch, setpnlTieMatch] = useState({})

    const [ManualData, setManualData] = useState({});
    const [SuperMatchData, setSuperMatchData] = useState({});
    const [TieMatchData, setTieMatchData] = useState({});
    const [CompMatchData, setCompMatchData] = useState({});
    const [fOdds, setfOdds] = useState(' ')
    const [fsize, setfsize] = useState(' ')
    const [acceptAnyOdds, setacceptAnyOdds] = useState(false)

    const [Tierunner1BackRate1, setTierunner1BackRate1] = useState(' ');
    const [Tierunner1BackSize1, setTierunner1BackSize1] = useState(' ');
    const [Tierunner1LayRate1, setTierunner1LayRate1] = useState(' ');
    const [Tierunner1LaySize1, setTierunner1LaySize1] = useState(' ');
    const [Tierunner2BackRate1, setTierunner2BackRate1] = useState(' ');
    const [Tierunner2LayRate1, setTierunner2LayRate1] = useState(' ');
    const [Tierunner2BackSize1, setTierunner2BackSize1] = useState(' ');
    const [Tierunner2LaySize1, setTierunner2LaySize1] = useState(' ');

    const [Suprunner1BackRate1, setSuprunner1BackRate1] = useState(' ');
    const [Suprunner1BackSize1, setSuprunner1BackSize1] = useState(' ');
    const [Suprunner1LayRate1, setSuprunner1LayRate1] = useState(' ');
    const [Suprunner1LaySize1, setSuprunner1LaySize1] = useState(' ');
    const [Suprunner2BackRate1, setSuprunner2BackRate1] = useState(' ');
    const [Suprunner2LayRate1, setSuprunner2LayRate1] = useState(' ');
    const [Suprunner2BackSize1, setSuprunner2BackSize1] = useState(' ');
    const [Suprunner2LaySize1, setSuprunner2LaySize1] = useState(' ');
    const [SupmarketBetStatus, setSupmarketBetStatus] = useState(0);

    const [Comrunner1BackRate1, setComrunner1BackRate1] = useState(' ');
    const [Comrunner1BackSize1, setComrunner1BackSize1] = useState(' ');
    const [Comrunner1LayRate1, setComrunner1LayRate1] = useState(' ');
    const [Comrunner1LaySize1, setComrunner1LaySize1] = useState(' ');
    const [Comrunner2BackRate1, setComrunner2BackRate1] = useState(' ');
    const [Comrunner2LayRate1, setComrunner2LayRate1] = useState(' ');
    const [Comrunner2BackSize1, setComrunner2BackSize1] = useState(' ');
    const [Comrunner2LaySize1, setComrunner2LaySize1] = useState(' ');
    const [CommarketBetStatus, setCommarketBetStatus] = useState(0);
    const [TossBetstatus, setTossBetstatus] = useState(0);

    const [runner1BackRate1, setrunner1BackRate1] = useState(' ');
    const [runner1BackSize1, setrunner1BackSize1] = useState(' ');
    const [runner1LayRate1, setrunner1LayRate1] = useState(' ');
    const [runner1LaySize1, setrunner1LaySize1] = useState(' ');
    const [runner2BackRate1, setrunner2BackRate1] = useState(' ');
    const [runner2BackSize1, setrunner2BackSize1] = useState(' ');
    const [runner2LayRate1, setrunner2LayRate1] = useState(' ');
    const [runner2LaySize1, setrunner2LaySize1] = useState(' ');

    //    to win toss
    const [tossrunner1BackRate251, settossrunner1BackRate251] = useState(' ');
    const [tossrunner1BackSize251, settossrunner1BackSize251] = useState(' ');
    const [tossrunner1LayRate251, settossrunner1LayRate251] = useState(' ');
    const [tossrunner1LaySize251, settossrunner1LaySize251] = useState(' ');
    const [tossrunner2BackRate251, settossrunner2BackRate251] = useState(' ');
    const [tossrunner2BackSize251, settossrunner2BackSize251] = useState(' ');
    const [tossrunner2LayRate251, settossrunner2LayRate251] = useState(' ');
    const [tossrunner2LaySize251, settossrunner2LaySize251] = useState(' ');

    const [tieBackRate1, settieBackRate1] = useState(' ');
    const [tieLayRate1, settieLayRate1] = useState(' ');
    const [tieBackSize1, settieBackSize1] = useState(' ');
    const [tieLaySize1, settieLaySize1] = useState(' ');
    const [pnlMarket, setpnlMarket] = useState({})
    const [pnlBook, setpnlBook] = useState({})
    const [pnlFancy, setpnlFancy] = useState([])
    const [totalStake, settotalStake] = useState('');
    const [pnlMarket2, setpnlMarket2] = useState({})
    const [pnlBook2, setpnlBook2] = useState({})
    const [hidematchOdds, sethidematchOdds] = useState({})
    const [newrefresh, setnewrefresh] = useState(true);
    const [marketBetStatus, setmarketBetStatus] = useState(0);
    const [liquidityStatus, setliquidityStatus] = useState(0);
    const [fancyStakeInfo, setfancyStakeInfo] = useState(-1);
    const [coinFactor, setcoinFactor] = useState(1);
    const [onclickPremium, setonclickPremium] = useState(false)
    const [highlightTab, sethighlightTab] = useState(marketKeys.matchOdds);
    const [TiemarketBetStatus, setTiemarketBetStatus] = useState(0);
    const [loading, setloading] = useState(false);
    const [marketStartTime, setmarketStartTime] = useState('')
    const [chId, setchId] = useState(null);
    const [Premclick, setPremclick] = useState(-1);
    const [Premclick2, setPremclick2] = useState(-1);
    const [selectPremLay, setselectPremLay] = useState(true);
    const [PreOdds, setPreOdds] = useState(' ')
    const [premiumfield, setpremiumfield] = useState(0);
    const [ChannelId, setChannelId] = useState(null);
    const [StatusSS, setStatusSS] = useState(0);


    // Goals market socket data state
    const [goalsSoccerSocket05Data, setGoalsSoccerSocket05Data] = useState({});
    const [goalsSoccerSocket15Data, setGoalsSoccerSocket15Data] = useState({});
    const [goalsSoccerSocket25Data, setGoalsSoccerSocket25Data] = useState({});
    const [goalsSoccerSocket35Data, setGoalsSoccerSocket35Data] = useState({});
    const [goalsSoccerSocket45Data, setGoalsSoccerSocket45Data] = useState({});


    // Goals market event data state
    const [goalsSoccerApiMarketData, setGoalsSoccerApiMarketData] = useState({});
    const [goalsSoccerApiPlMarketData, setGoalsSoccerApiPlMarketData] = useState({});
    const [goalsSoccerPlMarket2Data, setGoalsSoccerPlMarket2Data] = useState({});

    // Goals market onClick handle
    const [goalsOnKeyBordOn, setGoalsOnKeyBordOn] = useState({});

    // show confirmation
    const [showConfirmGoalsBet, setShowConfirmGoalsBet] = useState(false);

    // premimum
    const [premPlData, setPremPlData] = useState([]);
    // const [premPnlArr, setPremPnlArr] = useState([]);
    const [premPnlListArr, { set, push: pushPremList, updateAt: updatePremList, insertAt, update, updateFirst, upsert, sort, filter, removeAt, clear: clearPremList, reset }] = useList([]);
    const localPnlStateListById = (id) => premPnlListArr.filter(i => i.id === id);


    const matchMarket = {
        matchOdds: marketData,
        bookmaker: bookData2,
        completedMatch: CompMatchData,
        superOver: SuperMatchData,
        tiedMatch: TieMatchData,
        winToss: ManualData,
    }

    // react query
    const queryClient = useQueryClient();


    const url = window.location.href;
    const para = url.split('/');

    const eventT = para[6];
    const eventid = para[7];
    const marketid = para[8];



    // set premium Rate get from api
    const [premiumRate, setpremiumRate] = useState([]);

    useEffect(() => {
        // if tab on prm
        if ((onclickPremium === true || eventT == 2 || eventT == 1) && props.isLoggedIn) {
            setInterval(() => {
                const isFullMarketPage = history.location.pathname.includes('/d/dash/fullmarket/');

                if (isFullMarketPage) {
                    Axios.post('https://liveapi247.live/api1/getprem', {
                        eventId: eventid
                    }).then((prmm) => {
                        const premData = prmm.data;
                        if (prmm.status === 200) {
                            // console.log('prmm', prmm)
                            // console.log('equalsCheck premData',premiumRate === premData)
                            if (premiumRate !== premData) {
                                setpremiumRate(premData);
                            }
                        } else {
                            setpremiumRate([]);
                        }
                    })
                }

            }, 1000)
        }

    }, [(onclickPremium === true || eventT == 2 || eventT == 1), props.isLoggedIn]);


    useEffect(() => {
        setloading(true);

        socket = io(soketEndPoint());
        // console.log('socket connected');
        socket.on('connect', (data) => {
            socket.emit('room1', eventid);
            setTimeout(() => {
                setloading(false);
            }, 1000);
        });
        return () => {
            socket.close();
            // console.log('socket disconnected');
        }
    }, [])


    useEffect(() => {
        if (!props.userid) return;

        Axios.post('https://liveapi247.live/api1/ssgetclickstake', {
            id: props.userid,
            token: props.utoken,
        }).then(result => {

            if (result.status === 210) {
                setcoinFactor(parseInt(result.data[0].coin_type));
                setdStake(result.data[0].defaultStake);
                setDefaultStakesFromApi({
                    ...result.data[0]
                })
            }
        })
    }, [props.userid]);


    useEffect(() => {
        async function fetchData() {

            if (!eventid) return;

            let getfullMarketArray = [];
            let getTieMarketArray = [];
            let getComMarketArray = [];
            let getSuperMarketArray = [];
            let getbookMarketArray = [];
            let getclientwintossArray = [];

            await Axios.post('https://liveapi247.live/api8/getEventData', {
                eventId: eventid,
            })
                .then(result => {
                    if (result.data.length > 0) {
                        getfullMarketArray = result.data.filter(x => x.marketName === ("Match Odds" || "Moneyline"))
                        getTieMarketArray = result.data.filter(x => x.marketName === "Tied Match")
                        getComMarketArray = result.data.filter(x => x.marketName === "Completed Match")
                        getSuperMarketArray = result.data.filter(x => x.marketName === "Super Over")
                        getbookMarketArray = result.data.filter(x => x.marketName === "Bookmaker")
                        getclientwintossArray = result.data.filter(x => x.marketName === "To Win the Toss")

                        // soccer data
                        if (eventT == 1) {
                            // filter goal market data
                            result.data.map(eData => {
                                // eslint-disable-next-line no-unused-expressions
                                if (eData.marketName === marketKeys.overUnderGoals('0.5')) {
                                    goalsSoccerApiMarketData[marketKeys.overUnderGoals('0.5')] = eData
                                }
                                if (eData.marketName === marketKeys.overUnderGoals('1.5')) {
                                    goalsSoccerApiMarketData[marketKeys.overUnderGoals('1.5')] = eData
                                }
                                if (eData.marketName === marketKeys.overUnderGoals('2.5')) {
                                    goalsSoccerApiMarketData[marketKeys.overUnderGoals('2.5')] = eData
                                }
                                if (eData.marketName === marketKeys.overUnderGoals('3.5')) {
                                    goalsSoccerApiMarketData[marketKeys.overUnderGoals('3.5')] = eData
                                }
                                if (eData.marketName === marketKeys.overUnderGoals('4.5')) {
                                    goalsSoccerApiMarketData[marketKeys.overUnderGoals('4.5')] = eData
                                }
                            })
                        }

                    }
                    //   console.log("getfullMarketArray", getfullMarketArray);
                })


            if ((getfullMarketArray.length > 0 && getfullMarketArray.matchStatus !== 1) || getfullMarketArray.length === 0) {
                matchEndedMarket = true;
                // debugger
            }
            // match odds
            if (getfullMarketArray[0]) {
                let obj = getfullMarketArray[0];
                setmarketStartTime(obj.marketStartTime)
                setrunner1BackRate1(obj.runner1BackRate1);
                setrunner1LayRate1(obj.runner1LayRate1);
                setrunner2BackRate1(obj.runner2BackRate1);
                setrunner2LayRate1(obj.runner2LayRate1);
                sethidematchOdds(obj.oddsHide)
                if (obj.runnerId3) {
                    settieBackRate1(obj.tieBackRate1);
                    settieLayRate1(obj.tieLayRate1);
                }

                obj.marketStartTime = moment.tz(obj.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss')
                setmarketData(obj);

            }

            // tied match
            if (getTieMarketArray[0]) {
                let obj = getTieMarketArray[0];
                setmarketStartTime(obj.marketStartTime)
                setTierunner1BackRate1(obj.runner1BackRate1);
                setTierunner1LayRate1(obj.runner1LayRate1);
                setTierunner2BackRate1(obj.runner2BackRate1);
                setTierunner2LayRate1(obj.runner2LayRate1);

                obj.marketStartTime = moment.tz(obj.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss')
                setTieMatchData(obj);
            }

            // complated match
            if (getComMarketArray[0]) {
                let obj = getComMarketArray[0];
                setmarketStartTime(obj.marketStartTime)
                setComrunner1BackRate1(obj.runner1BackRate1);
                setComrunner1LayRate1(obj.runner1LayRate1);
                setComrunner1LayRate1(obj.runner2BackRate1);
                setComrunner2LayRate1(obj.runner2LayRate1);

                obj.marketStartTime = moment.tz(obj.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss')
                setCompMatchData(obj);
            }

            // super over match
            if (getSuperMarketArray[0]) {
                let obj = getSuperMarketArray[0];
                setmarketStartTime(obj.marketStartTime)
                setSuprunner1BackRate1(obj.runner1BackRate1);
                setSuprunner1LayRate1(obj.runner1LayRate1);
                setSuprunner2BackRate1(obj.runner2BackRate1);
                setSuprunner2LayRate1(obj.runner2LayRate1);


                obj.marketStartTime = moment.tz(obj.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss')
                setSuperMatchData(obj);
            }


            // Bookmaker
            if (getbookMarketArray.length === 0 || (getbookMarketArray.length > 0 && getbookMarketArray[0].matchStatus != 1)) {
                matchEndedBook = true;
            }
            if (getbookMarketArray[0]) {
                let obj = getbookMarketArray[0];
                setbookData2(obj);
            }

            // to winn toss
            if ((getclientwintossArray.length > 0 && getclientwintossArray[0].matchStatus != 1) || getclientwintossArray.length === 0) {
                matchEndedMarket = true;
            }
            if (getclientwintossArray[0]) {
                let obj = getclientwintossArray[0];
                setmarketStartTime(obj.marketStartTime);
                settossrunner1BackRate251(obj.runner1BackRate1);
                settossrunner1LayRate251(obj.runner1LayRate1);
                settossrunner2BackRate251(obj.runner2BackRate1);
                settossrunner2LayRate251(obj.runner2LayRate1);

                obj.marketStartTime = moment.tz(obj.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss')
                setManualData(obj);

            }

            if (newrefresh) {
                setnewrefresh(false);
            }
        }
        fetchData()
    }, []);

    if (matchEndedBook && matchEndedMarket && matchEndedTieMatch && matchEndedComMatch) {
        window.location.href = '/';
    }

    useEffect(() => {

        socket.on(eventid, (val) => {

            var value = JSON.parse(val);
            //console.log(value,'dash');
            if (value.messageType === soketIoMarketKey.matchOdds) {
                settotalStake(value.totalMatched);
                setmarketBetStatus(value.status);
                setStatusSS(value.SSstatus);

                if (value.status == '0' && liquidityStatus == 0) {
                    setliquidityStatus(1);
                }
                // r1
                setrunner1BackRate1(value.runner1BackRate1)
                setrunner1BackSize1(value.runner1BackSize1)
                setrunner1LayRate1(value.runner1LayRate1);
                setrunner1LaySize1(value.runner1LaySize1);
                // r2
                setrunner2BackRate1(value.runner2BackRate1);
                setrunner2BackSize1(value.runner2BackSize1);
                setrunner2LayRate1(value.runner2LayRate1);
                setrunner2LaySize1(value.runner2LaySize1);
                // r3
                settieBackRate1(value.tieBackRate1);
                settieBackSize1(value.tieBackSize1);
                settieLayRate1(value.tieLayRate1);
                settieLaySize1(value.tieLaySize1);

            }

            if (value[0] && value[0].messageType === soketIoMarketKey.fancy) {
                let temp = value;
                if (value[0].RateMode == 1) {

                    temp.sort(function (a, b) {

                        return moment(a.selectionId) - moment(b.selectionId);
                    });
                }
                else if (value[0].skymode == 'skyRate') {
                    temp = temp.filter((item, index) => item.gameStatus !== 18)
                    temp.sort(function (a, b) {
                        return a.selectionId - b.selectionId;
                    });
                }
                setfancyData(temp);

            }
            // if (value[0] && value[0].messageType === soketIoMarketKey.premium) {
            //     let temp = value;
            //     //  console.log(temp);
            //     if (value[0].messageType === 'premium_Rate') {
            //         temp.sort(function (a, b) {
            //             return a.marketId - b.marketId;
            //         });
            //     }
            //     setpremiumRate(temp);
            //     console.log('temp', temp)
            // }

            if (value.messageType === soketIoMarketKey.bookmaker) {

                let temp = { ...bookData };
                temp.messageType = value.messageType;
                temp.gameStatus = value.status;
                temp.runner1Status = value.runner1Status;
                temp.runner2Status = value.runner2Status;
                temp.runner3Status = value.runner3Status;
                temp.runner1BackRate1 = value.runner1BackRate1;
                temp.runner1LayRate1 = value.runner1LayRate1;
                temp.runner2BackRate1 = value.runner2BackRate1;
                temp.runner2LayRate1 = value.runner2LayRate1;
                temp.tieBackRate1 = value.tieBackRate1;
                temp.tieLayRate1 = value.tieLayRate1;

                //console.log('book1',value.tieBackRate1,value.tieBackRate1);
                setbookData(temp);

            }
            // Completed_Match_rate

            if (value.messageType === soketIoMarketKey.superOver) {

                setSupmarketBetStatus(value.status);

                // r1
                setSuprunner1BackRate1(value.runner1BackRate1)
                setSuprunner1LayRate1(value.runner1LayRate1)
                setSuprunner1BackSize1(value.runner1BackSize1);
                setSuprunner1LaySize1(value.runner1LaySize1);
                // r2
                setSuprunner2BackRate1(value.runner2BackRate1);
                setSuprunner2LayRate1(value.runner2LayRate1);
                setSuprunner2BackSize1(value.runner2BackSize1);
                setSuprunner2LaySize1(value.runner2LaySize1);

            }

            if (value.messageType === soketIoMarketKey.completedMatch) {

                setCommarketBetStatus(value.status);

                // r1
                setComrunner1BackRate1(value.runner1BackRate1)
                setComrunner1LayRate1(value.runner1LayRate1)
                setComrunner1BackSize1(value.runner1BackSize1);
                setComrunner1LaySize1(value.runner1LaySize1);
                // r2
                setComrunner2BackRate1(value.runner2BackRate1);
                setComrunner2LayRate1(value.runner2LayRate1);
                setComrunner2BackSize1(value.runner2BackSize1);
                setComrunner2LaySize1(value.runner2LaySize1);

            }

            if (value.messageType === soketIoMarketKey.winToss) {

                setTossBetstatus(value.status);

                // r1
                settossrunner1BackRate251(value.runner1BackRate1)
                settossrunner1LayRate251(value.runner1LayRate1)
                settossrunner1BackSize251(value.runner1BackSize1);
                settossrunner1LaySize251(value.runner1LaySize1);
                // r2
                settossrunner2BackRate251(value.runner2BackRate1);
                settossrunner2LayRate251(value.runner2LayRate1);
                settossrunner2BackSize251(value.runner2BackSize1);
                settossrunner2LaySize251(value.runner2LaySize1);

            }

            if (value.messageType === soketIoMarketKey.tiedMatch) {

                setTiemarketBetStatus(value.status);

                // r1
                setTierunner1BackRate1(value.runner1BackRate1)
                setTierunner1LayRate1(value.runner1LayRate1)
                setTierunner1BackSize1(value.runner1BackSize1);
                setTierunner1LaySize1(value.runner1LaySize1);
                // r2
                setTierunner2BackRate1(value.runner2BackRate1);
                setTierunner2LayRate1(value.runner2LayRate1);
                setTierunner2BackSize1(value.runner2BackSize1);
                setTierunner2LaySize1(value.runner2LaySize1);

            }


            if (value.messageType === soketIoMarketKey[marketKeys.overUnderGoals('0.5')]) {
                setGoalsSoccerSocket05Data(value)
            }
            if (value.messageType === soketIoMarketKey[marketKeys.overUnderGoals('1.5')]) {
                setGoalsSoccerSocket15Data(value)
            }
            if (value.messageType === soketIoMarketKey[marketKeys.overUnderGoals('2.5')]) {
                setGoalsSoccerSocket25Data(value)
            }
            if (value.messageType === soketIoMarketKey[marketKeys.overUnderGoals('3.5')]) {
                setGoalsSoccerSocket35Data(value)
            }

            if (value.messageType === soketIoMarketKey[marketKeys.overUnderGoals('4.5')]) {
                setGoalsSoccerSocket45Data(value)
            }

        })

        return () => {
            socket.removeAllListeners(eventid);
            setrunner1BackRate1(' '); setrunner2BackRate1(' ');
            setrunner1LayRate1(' '); setrunner2LayRate1(' ');
            setrunner1BackSize1(' '); setrunner2BackSize1(' ');
            setrunner1LaySize1(' '); setrunner2LaySize1(' ');

            setTierunner1BackRate1(' '); setTierunner2BackRate1(' ');
            setTierunner1LayRate1(' '); setTierunner2LayRate1(' ');
            setTierunner1BackSize1(' '); setTierunner2BackSize1(' ');
            setTierunner1LaySize1(' '); setTierunner2LaySize1(' ');

            setfancyData([]);

            // soccer goal market
            setGoalsSoccerSocket05Data({})
            setGoalsSoccerSocket15Data({})
            setGoalsSoccerSocket25Data({})
            setGoalsSoccerSocket35Data({})
            setGoalsSoccerSocket45Data({})
        }

    }, [])

    // to win toss
    //completematch
    const changeTossBook = (val1, runner, val3) => {

        if (!props.isLoggedIn) {
            props.checkShowLogin(true);
            return;
        }
        setdefaultStake(dStake);
        if (runner === 2) {
            setWintossMatchClickKeybordRunner(2)

            setteamName('B');
        }
        else if (runner === 1) {

            setWintossMatchClickKeybordRunner(1)
            setteamName('A');
        }
        else if (runner === 3) {

            setWintossMatchClickKeybordRunner(3)
            setteamName('T');
        }
        setselectManualBack(val1);
        setMaodds(val3);

        if (val1 === 1 || val1 === 3 || val1 === 5) {
            setoddsType('LAGAI');
        }
        else if (val1 === 2 || val1 === 4 || val1 === 6) {
            setoddsType('KHAI');
        }

    }
    //completematch
    const changeCompMatchBook = (val1, runner, val3) => {

        if (!props.isLoggedIn) {
            props.checkShowLogin(true);
            return;
        }
        setdefaultStake(dStake);
        if (runner === 2) {
            setComMatchClickKeybordRunner(2)

            setteamName('B');
        }
        else if (runner === 1) {
            setComMatchClickKeybordRunner(1)
            setteamName('A');
        }
        else if (runner === 3) {
            setComMatchClickKeybordRunner(3)
            setteamName('T');
        }
        setselectCompMatchBack(val1);
        setCOdds(val3);

        if (val1 === 1 || val1 === 3 || val1 === 5) {
            setoddsType('LAGAI');
        }
        else if (val1 === 2 || val1 === 4 || val1 === 6) {
            setoddsType('KHAI');
        }

    }
    //super overmatch
    const changeSuperMatchBook = (val1, runner, val3) => {

        if (!props.isLoggedIn) {
            props.checkShowLogin(true);
            return;
        }
        setdefaultStake(dStake);
        if (runner === 2) {
            setSuperMatchClickKeybordRunner(2)
            setteamName('B');
        }
        else if (runner === 1) {
            setSuperMatchClickKeybordRunner(1)
            setteamName('A');
        }
        else if (runner === 3) {
            setSuperMatchClickKeybordRunner(3)
            setteamName('T');
        }
        setselectSuperMatchBack(val1);
        setSOdds(val3);

        if (val1 === 1 || val1 === 3 || val1 === 5) {
            setoddsType('LAGAI');
        }
        else if (val1 === 2 || val1 === 4 || val1 === 6) {
            setoddsType('KHAI');
        }

    }
    //Tie match
    const changeTieMatchBook = (val1, runner, val3) => {

        if (!props.isLoggedIn) {
            props.checkShowLogin(true);
            return;
        }
        setdefaultStake(dStake);
        if (runner === 2) {
            setTieMatchClickKeybordRunner(2)
            setteamName('B');
        }
        else if (runner === 1) {

            setTieMatchClickKeybordRunner(1)
            setteamName('A');
        }
        else if (runner === 3) {
            setTieMatchClickKeybordRunner(3)
            setteamName('T');
        }
        setselectTieMatchBack(val1);
        setTOdds(val3);

        if (val1 === 1 || val1 === 3 || val1 === 5) {
            setoddsType('LAGAI');
        }
        else if (val1 === 2 || val1 === 4 || val1 === 6) {
            setoddsType('KHAI');
        }

    }

    const changeBook = (val1, runner, val3) => {

        if (!props.isLoggedIn) {
            props.checkShowLogin(true);
            return;
        }
        setdefaultStake(dStake);
        if (runner === 2) {
            setBookdataMatchClickKeybordRunner(2)
            setteamName('B');
        }
        else if (runner === 1) {
            setBookdataMatchClickKeybordRunner(1)
            setteamName('A');
        }
        else if (runner === 3) {
            setBookdataMatchClickKeybordRunner(3)
            setteamName('T');
        }
        setselectBack(val1);
        setbOdds(val3);

        if (val1 === 1 || val1 === 3 || val1 === 5) {
            setoddsType('LAGAI');
        }
        else if (val1 === 2 || val1 === 4 || val1 === 6) {
            setoddsType('KHAI');
        }

    }

    const changeMatchodds = (val1, runner, val3) => {

        if (!props.isLoggedIn) {
            props.checkShowLogin(true);
            return;
        }

        setdefaultStake(dStake);
        if (runner === 2) {
            setteamName('B');
            setMatchOddClickKeybordRunner(2);
        }
        else if (runner === 1) {
            setMatchOddClickKeybordRunner(1);
            setteamName('A');
        }
        else if (runner === 3) {
            setMatchOddClickKeybordRunner(3);
            setteamName('T');
        }

        setselectMatchBack(val1);
        setmOdds(val3);

        if (val1 === 1 || val1 === 3 || val1 === 5) {
            setoddsType('LAGAI');
        }
        else if (val1 === 2 || val1 === 4 || val1 === 6) {
            setoddsType('KHAI');
        }

    }
    // goals market onClick layback
    const onClickGoalMarketLayBack = (isBack, runner, odd, currMarket) => {
        if (!props.isLoggedIn) {
            props.checkShowLogin(true);
            return;
        }

        setGoalsOnKeyBordOn({
            show: true,
            isBack,
            runner,
            runnerName: goalsSoccerApiMarketData[currMarket][`runnerName${runner}`],
            odd,
            currMarket,
            temName: runner === 1 ? 'A' : runner === 2 ? 'B' : ''
        });

        setoddsType(isBack ? 'LAGAI' : 'KHAI');
    }
    const tmpOnCloseGoalMarketLayBack = () => {
        setGoalsOnKeyBordOn({ ...goalsOnKeyBordOn, show: false, isBack: null });
        setGoalsSoccerPlMarket2Data({});
    }

    const onCloseGoalMarketLayBack = () => {
        setGoalsOnKeyBordOn({});
        setGoalsSoccerPlMarket2Data({});
        setdefaultStake(dStake);
    }


    const closeMatchoddsBet = () => {
        setselectMatchBack(0);
        setMatchOddClickKeybordRunner(null);
    }
    const closeBookBet = () => {
        setselectBack(0);
        setBookdataMatchClickKeybordRunner(null)

    }


    const closeManualBet = () => {
        setWintossMatchClickKeybordRunner(0);
        setselectManualBack(0);
        setManualclick2(false);
        setManualclick(false);
        setManualclick3(false);
    }
    const closeTieMatchBet = () => {
        setselectTieMatchBack(0);
        setTieMatchClickKeybordRunner(null);
    }
    const closeCompMatchBet = () => {
        setselectCompMatchBack(0);
        setComMatchClickKeybordRunner(null)
    }
    const closeSuperMatchBet = () => {
        setselectSuperMatchBack(0);
        setSuperMatchclick2(false);
        setSuperMatchclick(false);
        setSuperMatchclick3(false);
    }


    const changeDefaultStake = (event) => {
        setdefaultStake(event.target.value);
    }

    useEffect(() => {
        //console.log(defaultStake);

        if (matchOddClickKeybordRunner === 1 || matchOddClickKeybordRunner === 2 || matchOddClickKeybordRunner === 3) {

            var amount = defaultStake;
            var win_teamA = 0;
            var win_teamB = 0;
            var win_tie = 0;


            if (amount === ' ' || amount === '') return;

            if (pnlMarket?.teamA && pnlMarket?.teamB && pnlMarket?.tie && (pnlMarket?.teamA != 0 || pnlMarket?.teamB != 0 || pnlMarket?.tie != 0)) {
                win_teamA = parseFloat(pnlMarket?.teamA);
                win_teamB = parseFloat(pnlMarket?.teamB);
                win_tie = parseFloat(pnlMarket?.tie);

            }

            if (oddsType == 'LAGAI') {
                if (teamName == 'A') {
                    win_teamB = win_teamB - parseFloat(amount);
                    win_tie = win_tie - parseFloat(amount);
                    win_teamA = win_teamA + parseFloat(amount) * parseFloat(mOdds - 1);
                }
                else if (teamName == 'B') {

                    win_teamA = win_teamA - parseFloat(amount);
                    win_tie = win_tie - parseFloat(amount);
                    win_teamB = win_teamB + parseFloat(amount) * parseFloat(mOdds - 1);

                }
                else {

                    win_teamA = win_teamA - parseFloat(amount);
                    win_teamB = win_teamB - parseFloat(amount);
                    win_tie = win_tie + parseFloat(amount) * parseFloat(mOdds - 1);

                }
            }

            else {

                if (teamName == 'A') {

                    win_teamB = win_teamB + parseFloat(amount);
                    win_tie = win_tie + parseFloat(amount);
                    win_teamA = win_teamA - parseFloat(amount) * parseFloat(mOdds - 1);

                }
                else if (teamName == 'B') {

                    win_teamA = win_teamA + parseFloat(amount);
                    win_tie = win_tie + parseFloat(amount);
                    win_teamB = win_teamB - parseFloat(amount) * parseFloat(mOdds - 1);

                }
                else {

                    win_teamA = win_teamA + parseFloat(amount);
                    win_teamB = win_teamB + parseFloat(amount);
                    win_tie = win_tie - parseFloat(amount) * parseFloat(mOdds - 1);


                }

            }

            var obj = {};
            if (win_teamA.toFixed(2) == 'NaN') obj.teamA = 0; else obj.teamA = win_teamA.toFixed(2);
            if (win_teamB.toFixed(2) == 'NaN') obj.teamB = 0; else obj.teamB = win_teamB.toFixed(2);
            if (win_tie.toFixed(2) == 'NaN') obj.tie = 0; else obj.tie = win_tie.toFixed(2);

            //obj.teamA = win_teamA.toFixed(2);
            // obj.teamB = win_teamB.toFixed(2);
            // obj.tie = win_tie.toFixed(2);


            setpnlMarket2(obj);



        }

        else if (bookdataMatchClickKeybordRunner == 1 || bookdataMatchClickKeybordRunner == 2 || bookdataMatchClickKeybordRunner == 3) {

            var win_teamA = 0;
            var win_teamB = 0;
            var win_tie = 0;

            if (defaultStake === ' ' || defaultStake === '') return;

            if (pnlBook?.teamA && pnlBook.teamB && pnlBook.tie && (pnlBook?.teamA != 0 || pnlBook.teamB != 0 || pnlBook.tie != 0)) {
                win_teamA = parseFloat(pnlBook?.teamA);
                win_teamB = parseFloat(pnlBook.teamB);
                win_tie = parseFloat(pnlBook.tie);

            }

            if (oddsType == 'LAGAI') {
                if (teamName == 'A') {
                    win_teamB = win_teamB - parseFloat(defaultStake);
                    win_tie = win_tie - parseFloat(defaultStake);
                    win_teamA = win_teamA + parseFloat(defaultStake) * parseFloat(bOdds / 100);
                }
                else if (teamName == 'B') {

                    win_teamA = win_teamA - parseFloat(defaultStake);
                    win_tie = win_tie - parseFloat(defaultStake);
                    win_teamB = win_teamB + parseFloat(defaultStake) * parseFloat(bOdds / 100);

                }
                else {

                    win_teamA = win_teamA - parseFloat(defaultStake);
                    win_teamB = win_teamB - parseFloat(defaultStake);
                    win_tie = win_tie + parseFloat(defaultStake) * parseFloat(bOdds / 100);

                }
            }

            else {

                if (teamName == 'A') {

                    win_teamB = win_teamB + parseFloat(defaultStake);
                    win_tie = win_tie + parseFloat(defaultStake);
                    win_teamA = win_teamA - parseFloat(defaultStake) * parseFloat(bOdds / 100);

                }
                else if (teamName == 'B') {

                    win_teamA = win_teamA + parseFloat(defaultStake);
                    win_tie = win_tie + parseFloat(defaultStake);
                    win_teamB = win_teamB - parseFloat(defaultStake) * parseFloat(bOdds / 100);

                }
                else {

                    win_teamA = win_teamA + parseFloat(defaultStake);
                    win_teamB = win_teamB + parseFloat(defaultStake);
                    win_tie = win_tie - parseFloat(defaultStake) * parseFloat(bOdds / 100);


                }

            }

            var obj = {};
            // obj.teamA = win_teamA.toFixed(2);
            // obj.teamB = win_teamB.toFixed(2);
            // obj.tie = win_tie.toFixed(2);
            if (win_teamA.toFixed(2) == 'NaN') obj.teamA = 0; else obj.teamA = win_teamA.toFixed(2);
            if (win_teamB.toFixed(2) == 'NaN') obj.teamB = 0; else obj.teamB = win_teamB.toFixed(2);
            if (win_tie.toFixed(2) == 'NaN') obj.tie = 0; else obj.tie = win_tie.toFixed(2);

            setpnlBook2(obj);
        }
        //super over match
        else if (SuperMatchclick || SuperMatchclick2 || SuperMatchclick3) {

            var win_teamA = 0;
            var win_teamB = 0;
            var win_tie = 0;

            if (defaultStake === ' ' || defaultStake === '') return;

            if (pnlSuperMatch?.teamA && pnlSuperMatch?.teamB && pnlSuperMatch.tie && (pnlSuperMatch?.teamA != 0 || pnlSuperMatch?.teamB != 0 || pnlSuperMatch.tie != 0)) {
                win_teamA = parseFloat(pnlSuperMatch?.teamA);
                win_teamB = parseFloat(pnlSuperMatch?.teamB);
                win_tie = parseFloat(pnlSuperMatch.tie);

            }

            if (oddsType == 'LAGAI') {
                if (teamName == 'A') {
                    win_teamB = win_teamB - parseFloat(defaultStake);
                    win_tie = win_tie - parseFloat(defaultStake);
                    win_teamA = win_teamA + parseFloat(defaultStake) * parseFloat(SOdds / 100);
                }
                else if (teamName == 'B') {

                    win_teamA = win_teamA - parseFloat(defaultStake);
                    win_tie = win_tie - parseFloat(defaultStake);
                    win_teamB = win_teamB + parseFloat(defaultStake) * parseFloat(SOdds / 100);

                }
                else {

                    win_teamA = win_teamA - parseFloat(defaultStake);
                    win_teamB = win_teamB - parseFloat(defaultStake);
                    win_tie = win_tie + parseFloat(defaultStake) * parseFloat(SOdds / 100);

                }
            }

            else {

                if (teamName == 'A') {

                    win_teamB = win_teamB + parseFloat(defaultStake);
                    win_tie = win_tie + parseFloat(defaultStake);
                    win_teamA = win_teamA - parseFloat(defaultStake) * parseFloat(SOdds / 100);

                }
                else if (teamName == 'B') {

                    win_teamA = win_teamA + parseFloat(defaultStake);
                    win_tie = win_tie + parseFloat(defaultStake);
                    win_teamB = win_teamB - parseFloat(defaultStake) * parseFloat(SOdds / 100);

                }
                else {

                    win_teamA = win_teamA + parseFloat(defaultStake);
                    win_teamB = win_teamB + parseFloat(defaultStake);
                    win_tie = win_tie - parseFloat(defaultStake) * parseFloat(SOdds / 100);


                }

            }

            var obj = {};
            // obj.teamA = win_teamA.toFixed(2);
            // obj.teamB = win_teamB.toFixed(2);
            // obj.tie = win_tie.toFixed(2);
            if (win_teamA.toFixed(2) == 'NaN') obj.teamA = 0; else obj.teamA = win_teamA.toFixed(2);
            if (win_teamB.toFixed(2) == 'NaN') obj.teamB = 0; else obj.teamB = win_teamB.toFixed(2);
            if (win_tie.toFixed(2) == 'NaN') obj.tie = 0; else obj.tie = win_tie.toFixed(2);

            setpnlSuperMatch2(obj);
        }
        //complate match
        else if (ComMatchclick || ComMatchclick2 || ComMatchclick3) {

            var win_teamA = 0;
            var win_teamB = 0;
            var win_tie = 0;

            if (defaultStake === ' ' || defaultStake === '') return;

            if (pnlCompMatch?.teamA && pnlCompMatch?.teamB && pnlCompMatch.tie && (pnlCompMatch?.teamA != 0 || pnlCompMatch?.teamB != 0 || pnlCompMatch.tie != 0)) {
                win_teamA = parseFloat(pnlCompMatch?.teamA);
                win_teamB = parseFloat(pnlCompMatch.teamB);
                win_tie = parseFloat(pnlCompMatch.tie);

            }

            if (oddsType == 'LAGAI') {
                if (teamName == 'A') {
                    win_teamB = win_teamB - parseFloat(defaultStake);
                    win_tie = win_tie - parseFloat(defaultStake);
                    win_teamA = win_teamA + parseFloat(defaultStake) * parseFloat(COdds / 100);
                }
                else if (teamName == 'B') {

                    win_teamA = win_teamA - parseFloat(defaultStake);
                    win_tie = win_tie - parseFloat(defaultStake);
                    win_teamB = win_teamB + parseFloat(defaultStake) * parseFloat(COdds / 100);

                }
                else {

                    win_teamA = win_teamA - parseFloat(defaultStake);
                    win_teamB = win_teamB - parseFloat(defaultStake);
                    win_tie = win_tie + parseFloat(defaultStake) * parseFloat(COdds / 100);

                }
            }

            else {

                if (teamName == 'A') {

                    win_teamB = win_teamB + parseFloat(defaultStake);
                    win_tie = win_tie + parseFloat(defaultStake);
                    win_teamA = win_teamA - parseFloat(defaultStake) * parseFloat(COdds / 100);

                }
                else if (teamName == 'B') {

                    win_teamA = win_teamA + parseFloat(defaultStake);
                    win_tie = win_tie + parseFloat(defaultStake);
                    win_teamB = win_teamB - parseFloat(defaultStake) * parseFloat(COdds / 100);

                }
                else {

                    win_teamA = win_teamA + parseFloat(defaultStake);
                    win_teamB = win_teamB + parseFloat(defaultStake);
                    win_tie = win_tie - parseFloat(defaultStake) * parseFloat(COdds / 100);


                }

            }

            var obj = {};
            if (win_teamA.toFixed(2) == 'NaN') obj.teamA = 0; else obj.teamA = win_teamA.toFixed(2);
            if (win_teamB.toFixed(2) == 'NaN') obj.teamB = 0; else obj.teamB = win_teamB.toFixed(2);
            if (win_tie.toFixed(2) == 'NaN') obj.tie = 0; else obj.tie = win_tie.toFixed(2);

            setpnlCompMatch2(obj);
        }
        //Tie match
        else if (tieMatchClickKeybordRunner == 1 || tieMatchClickKeybordRunner == 2) {

            var win_teamA = 0;
            var win_teamB = 0;
            var win_tie = 0;

            if (defaultStake === ' ' || defaultStake === '') return;

            if (pnlTieMatch?.teamA && pnlTieMatch?.teamB && pnlTieMatch.tie && (pnlTieMatch?.teamA != 0 || pnlTieMatch?.teamB != 0 || pnlTieMatch.tie != 0)) {
                win_teamA = parseFloat(pnlTieMatch?.teamA);
                win_teamB = parseFloat(pnlTieMatch?.teamB);
                win_tie = parseFloat(pnlTieMatch.tie);

            }

            if (oddsType == 'LAGAI') {
                if (teamName == 'A') {
                    win_teamB = win_teamB - parseFloat(defaultStake);
                    win_tie = win_tie - parseFloat(defaultStake);
                    win_teamA = win_teamA + parseFloat(defaultStake) * parseFloat(TOdds / 100);
                }
                else if (teamName == 'B') {

                    win_teamA = win_teamA - parseFloat(defaultStake);
                    win_tie = win_tie - parseFloat(defaultStake);
                    win_teamB = win_teamB + parseFloat(defaultStake) * parseFloat(TOdds / 100);

                }
                else {

                    win_teamA = win_teamA - parseFloat(defaultStake);
                    win_teamB = win_teamB - parseFloat(defaultStake);
                    win_tie = win_tie + parseFloat(defaultStake) * parseFloat(TOdds / 100);

                }
            }

            else {

                if (teamName == 'A') {

                    win_teamB = win_teamB + parseFloat(defaultStake);
                    win_tie = win_tie + parseFloat(defaultStake);
                    win_teamA = win_teamA - parseFloat(defaultStake) * parseFloat(TOdds / 100);

                }
                else if (teamName == 'B') {

                    win_teamA = win_teamA + parseFloat(defaultStake);
                    win_tie = win_tie + parseFloat(defaultStake);
                    win_teamB = win_teamB - parseFloat(defaultStake) * parseFloat(TOdds / 100);

                }
                else {

                    win_teamA = win_teamA + parseFloat(defaultStake);
                    win_teamB = win_teamB + parseFloat(defaultStake);
                    win_tie = win_tie - parseFloat(defaultStake) * parseFloat(TOdds / 100);


                }

            }

            var obj = {};
            // obj.teamA = win_teamA.toFixed(2);
            // obj.teamB = win_teamB.toFixed(2);
            // obj.tie = win_tie.toFixed(2);
            if (win_teamA.toFixed(2) == 'NaN') obj.teamA = 0; else obj.teamA = win_teamA.toFixed(2);
            if (win_teamB.toFixed(2) == 'NaN') obj.teamB = 0; else obj.teamB = win_teamB.toFixed(2);
            if (win_tie.toFixed(2) == 'NaN') obj.tie = 0; else obj.tie = win_tie.toFixed(2);

            setpnlTieMatch2(obj);
        }
        // toss
        //Tie match
        else if (Manualclick || Manualclick2 || Manualclick3) {

            var win_teamA = 0;
            var win_teamB = 0;
            var win_tie = 0;

            if (defaultStake === ' ' || defaultStake === '') return;

            if (pnlManual?.teamA && pnlManual?.teamB && pnlManual.tie && (pnlManual?.teamA != 0 || pnlManual?.teamB != 0 || pnlManual.tie != 0)) {
                win_teamA = parseFloat(pnlManual?.teamA);
                win_teamB = parseFloat(pnlManual?.teamB);
                win_tie = parseFloat(pnlManual.tie);

            }

            if (oddsType == 'LAGAI') {
                if (teamName == 'A') {
                    win_teamB = win_teamB - parseFloat(defaultStake);
                    win_tie = win_tie - parseFloat(defaultStake);
                    win_teamA = win_teamA + parseFloat(defaultStake) * parseFloat(TOdds / 100);
                }
                else if (teamName == 'B') {

                    win_teamA = win_teamA - parseFloat(defaultStake);
                    win_tie = win_tie - parseFloat(defaultStake);
                    win_teamB = win_teamB + parseFloat(defaultStake) * parseFloat(TOdds / 100);

                }
                else {

                    win_teamA = win_teamA - parseFloat(defaultStake);
                    win_teamB = win_teamB - parseFloat(defaultStake);
                    win_tie = win_tie + parseFloat(defaultStake) * parseFloat(TOdds / 100);

                }
            }

            else {

                if (teamName == 'A') {

                    win_teamB = win_teamB + parseFloat(defaultStake);
                    win_tie = win_tie + parseFloat(defaultStake);
                    win_teamA = win_teamA - parseFloat(defaultStake) * parseFloat(TOdds / 100);

                }
                else if (teamName == 'B') {

                    win_teamA = win_teamA + parseFloat(defaultStake);
                    win_tie = win_tie + parseFloat(defaultStake);
                    win_teamB = win_teamB - parseFloat(defaultStake) * parseFloat(TOdds / 100);

                }
                else {

                    win_teamA = win_teamA + parseFloat(defaultStake);
                    win_teamB = win_teamB + parseFloat(defaultStake);
                    win_tie = win_tie - parseFloat(defaultStake) * parseFloat(TOdds / 100);


                }

            }

            var obj = {};
            // obj.teamA = win_teamA.toFixed(2);
            // obj.teamB = win_teamB.toFixed(2);
            // obj.tie = win_tie.toFixed(2);
            if (win_teamA.toFixed(2) == 'NaN') obj.teamA = 0; else obj.teamA = win_teamA.toFixed(2);
            if (win_teamB.toFixed(2) == 'NaN') obj.teamB = 0; else obj.teamB = win_teamB.toFixed(2);
            if (win_tie.toFixed(2) == 'NaN') obj.tie = 0; else obj.tie = win_tie.toFixed(2);

            setpnlManual2(obj);
        }
        // goals market
        else if (goalsOnKeyBordOn?.show && goalsOnKeyBordOn?.currMarket) {
            let amount = defaultStake;
            let win_teamA = 0;
            let win_teamB = 0;
            let win_tie = 0;


            if (amount === ' ' || amount === '') return;

            if (goalsSoccerApiMarketData[goalsOnKeyBordOn?.currMarket]?.teamA && goalsSoccerApiMarketData[goalsOnKeyBordOn?.currMarket]?.teamB && goalsSoccerApiMarketData[goalsOnKeyBordOn?.currMarket]?.tie && (goalsSoccerApiMarketData[goalsOnKeyBordOn?.currMarket]?.teamA != 0 || goalsSoccerApiMarketData[goalsOnKeyBordOn?.currMarket]?.teamB != 0 || goalsSoccerApiMarketData[goalsOnKeyBordOn?.currMarket]?.tie != 0)) {
                win_teamA = parseFloat(goalsSoccerApiMarketData[goalsOnKeyBordOn?.currMarket]?.teamA);
                win_teamB = parseFloat(goalsSoccerApiMarketData[goalsOnKeyBordOn?.currMarket]?.teamB);
                win_tie = parseFloat(goalsSoccerApiMarketData[goalsOnKeyBordOn?.currMarket]?.tie);
            }

            if (oddsType == 'LAGAI') {
                if (goalsOnKeyBordOn?.temName == 'A') {
                    win_teamB = win_teamB - parseFloat(amount);
                    win_tie = win_tie - parseFloat(amount);
                    win_teamA = win_teamA + parseFloat(amount) * parseFloat(goalsOnKeyBordOn.odd - 1);
                }
                else if (goalsOnKeyBordOn?.temName == 'B') {

                    win_teamA = win_teamA - parseFloat(amount);
                    win_tie = win_tie - parseFloat(amount);
                    win_teamB = win_teamB + parseFloat(amount) * parseFloat(goalsOnKeyBordOn.odd - 1);

                }
                else {

                    win_teamA = win_teamA - parseFloat(amount);
                    win_teamB = win_teamB - parseFloat(amount);
                    win_tie = win_tie + parseFloat(amount) * parseFloat(goalsOnKeyBordOn.odd - 1);

                }
            }

            else {

                if (goalsOnKeyBordOn?.temName == 'A') {

                    win_teamB = win_teamB + parseFloat(amount);
                    win_tie = win_tie + parseFloat(amount);
                    win_teamA = win_teamA - parseFloat(amount) * parseFloat(goalsOnKeyBordOn.odd - 1);

                }
                else if (goalsOnKeyBordOn?.temName == 'B') {

                    win_teamA = win_teamA + parseFloat(amount);
                    win_tie = win_tie + parseFloat(amount);
                    win_teamB = win_teamB - parseFloat(amount) * parseFloat(goalsOnKeyBordOn.odd - 1);

                }
                else {

                    win_teamA = win_teamA + parseFloat(amount);
                    win_teamB = win_teamB + parseFloat(amount);
                    win_tie = win_tie - parseFloat(amount) * parseFloat(goalsOnKeyBordOn.odd - 1);

                }

            }

            let obj = {};
            if (win_teamA.toFixed(2) == 'NaN') obj.teamA = 0; else obj.teamA = win_teamA.toFixed(2);
            if (win_teamB.toFixed(2) == 'NaN') obj.teamB = 0; else obj.teamB = win_teamB.toFixed(2);
            if (win_tie.toFixed(2) == 'NaN') obj.tie = 0; else obj.tie = win_tie.toFixed(2);

            goalsSoccerPlMarket2Data[goalsOnKeyBordOn?.currMarket] = obj

        }

    }, [defaultStake, goalsOnKeyBordOn])



    const handlebookpin = () => {
        if (!props.isLoggedIn) {
            props.checkShowLogin(true);
            return;
        } else {
            setbookpin(!bookpin)
        }
    }
    const handlefancypin = () => {
        if (!props.isLoggedIn) {
            props.checkShowLogin(true);
            return;
        } else {
            setFancypin(!Fancypin)
        }
    }



    const OpenfancyBet = (index) => {
        setdefaultStake(dStake);
        setclick(index);
        setselectFancy(1)
        setfield(1);

    }

    const OpenfancyBetLay = (index) => {
        setdefaultStake(dStake);
        setclick(index);
        setselectFancyLay(1);
        setfield(2);

    }
    const closeFancyBet = () => {
        setclick(-1);
        setselectFancy(0);
        setselectFancyLay(0);
    }

    const handleMaxStake = () => {
        setMaxstake(true)
    }

    const handleMaxStakeclose = () => {
        setMaxstake(false)
    }

    const handleFancyBook = (val) => {
        setshowFancyBook(val);
    }


    const refreshBalanceApiAction = useMutation({
        mutationFn: refreshBalanceApiRq,
        // onMutate: () => {
        //     // setloading(true);
        // },
        onSuccess: rsp => {
            queryClient.setQueryData(['refreshBalanceStatus'], rsp);
            // setloading(false);
        },
        // onError: () => {
        //     // setloading(false);
        // }
    });

    const handleRefresh = () => {
        const utoken = cookies.get('token');
        const user = cookies.get('id');
        refreshBalanceApiAction.mutate({ id: user, token: utoken });
    };

    const plMarket = async (currMarketData) => {
        // if (!props.userid && props.isLoggedIn !== true) return;
        return await Axios.post('https://liveapi247.live/api1/plmarket', {
            id: props.userid,
            token: props.utoken,
            eventId: eventid,
            marketId: currMarketData?.marketId
        }).then(result => {
            if (result.status === 210) {
                let obj = result.data;
                return obj;
            }
        })
    }
    const plPremiumMarket = async ({ eventId, marketId }) => {
        return await Axios.post('https://liveapi247.live/api6/getprembook', {
            clientId: props.userid,
            token: props.utoken,
            eventId,
            // marketId
        }).then(result => {
            if (result.status === 200) {
                let arr = result.data;
                return arr;
            } else {
                return [];
            }
        })
    }


    const plFancy = () => {
        // if (!props.userid || !eventid && props.isLoggedIn !== true) return;
        Axios.post('https://liveapi247.live/api1/plfancy', {
            id: props.userid,
            token: props.utoken,
            eventId: eventid,
        })
            .then(result => {
                if (result.status === 210) {

                    let obj = {};
                    result.data.map((item) => {
                        obj[item.marketId] = item.exposure;
                    });
                    setpnlFancy(obj);
                }

            })
    }



    useEffect(() => {

        if (props.isLoggedIn === true && props.userid && props.utoken) {
            if (marketData.marketId && marketData.matchStatus == 1 && highlightTab == marketKeys.matchOdds) plMarket(matchMarket[marketKeys.matchOdds]).then(obj => setpnlMarket(obj))
            if (bookData2.marketId && bookData2.matchStatus == 1 && (highlightTab == marketKeys.bookmaker || highlightTab == marketKeys.matchOdds)) plMarket(matchMarket[marketKeys.bookmaker]).then(obj => setpnlBook(obj))
            if (CompMatchData.matchStatus == 1 && highlightTab == marketKeys.completedMatch) plMarket(matchMarket[marketKeys.completedMatch]).then(obj => setpnlCompMatch(obj))
            if (SuperMatchData.matchStatus == 1 && highlightTab == marketKeys.superOver) plMarket(matchMarket[marketKeys.superOver]).then(obj => setpnlSuperMatch(obj))
            if (TieMatchData.matchStatus == 1 && highlightTab == marketKeys.tiedMatch) plMarket(matchMarket[marketKeys.tiedMatch]).then(obj => setpnlTieMatch(obj))
            if (ManualData.matchStatus == 1 && highlightTab == marketKeys.winToss) plMarket(matchMarket[marketKeys.winToss]).then(obj => setpnlManual(obj))

            // goals market pl
            if (goalsSoccerApiMarketData[marketKeys.overUnderGoals('0.5')]?.matchStatus && highlightTab == marketKeys.overUnderGoals('0.5')) {
                plMarket(goalsSoccerApiMarketData[marketKeys.overUnderGoals('0.5')]).then(obj => {
                    goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('0.5')] = obj
                })
            }
            if (goalsSoccerApiMarketData[marketKeys.overUnderGoals('1.5')]?.matchStatus && highlightTab == marketKeys.overUnderGoals('1.5')) {
                plMarket(goalsSoccerApiMarketData[marketKeys.overUnderGoals('1.5')]).then(obj => {
                    goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('1.5')] = obj
                })
            }
            if (goalsSoccerApiMarketData[marketKeys.overUnderGoals('2.5')]?.matchStatus && highlightTab == marketKeys.overUnderGoals('2.5')) {
                plMarket(goalsSoccerApiMarketData[marketKeys.overUnderGoals('2.5')]).then(obj => {
                    goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('2.5')] = obj
                })
            }
            if (goalsSoccerApiMarketData[marketKeys.overUnderGoals('3.5')]?.matchStatus && highlightTab == marketKeys.overUnderGoals('3.5')) {
                plMarket(goalsSoccerApiMarketData[marketKeys.overUnderGoals('3.5')]).then(obj => {
                    goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('3.5')] = obj
                })
            }
            if (goalsSoccerApiMarketData[marketKeys.overUnderGoals('4.5')]?.matchStatus && highlightTab == marketKeys.overUnderGoals('4.5')) {
                plMarket(goalsSoccerApiMarketData[marketKeys.overUnderGoals('4.5')]).then(obj => {
                    goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('4.5')] = obj
                })
            }

        }


    }, [props.isLoggedIn, props.userid, highlightTab, marketData.marketId, bookData2.marketId])

    useEffect(() => {
        if (props.isLoggedIn === true && props.userid && eventid && props.utoken) {
            plFancy();
            plPremiumMarket({
                // marketId: otherArg?.marketId,
                eventId: eventid,
            }).then((PPldata) => {
                setPremPlData(PPldata.data);
            })
        }
    }, [props.isLoggedIn, props.userid, eventid])


    // premium book
    const handlepremium = (vwall) => {
        setonclickPremium(vwall)
    }

    const placeBet = async (currBetKey, otherArg = {}) => {
        if (!props.userid) {
            return;
        }

        props.handleRefreshhh(5000, 'Placing bet...', 1);
        var dupBet = cookies.get('dupBet');

        let isGoalMarket = currBetKey === marketKeys.overUnderGoals('0.5') || currBetKey === marketKeys.overUnderGoals('1.5') || currBetKey === marketKeys.overUnderGoals('2.5') || currBetKey === marketKeys.overUnderGoals('3.5') || currBetKey === marketKeys.overUnderGoals('4.5');
        let apiUrl = 'https://liveapi247.live/api3/placebets';
        let currOdds = 0;
        let customPayload = {};

        if (currBetKey === marketKeys.matchOdds) {
            currOdds = mOdds;
        }
        if (currBetKey === marketKeys.bookmaker) {
            currOdds = parseFloat(bOdds / 100 + 1).toFixed(2).toString();
            customPayload = { anyOdds: acceptAnyOdds };
        }
        if (currBetKey === marketKeys.completedMatch) {
            currOdds = COdds
        }
        if (currBetKey === marketKeys.superOver) {
            currOdds = SOdds
        }
        if (currBetKey === marketKeys.tiedMatch) {
            currOdds = TOdds
        }
        if (currBetKey === marketKeys.winToss) {
            currOdds = Maodds
        }
        if (isGoalMarket) {
            customPayload = { odds: goalsOnKeyBordOn?.odd, marketId: goalsSoccerApiMarketData[currBetKey]?.marketId, teamName: goalsOnKeyBordOn.temName };
        }
        if (currBetKey === marketKeys.premium) {
            apiUrl = 'https://liveapi247.live/api6/ppbet';
            currOdds = PreOdds;
            customPayload = {
                oddsType: 'LAGAI',
                anyOdds: acceptAnyOdds,
                betType: marketKeys.premium,
                teamName: otherArg?.teamName,
                marketId: otherArg?.marketId,
                marketName: otherArg?.marketName,
                eventId: otherArg?.eventId
            }
        }

        const fancyOddType = otherArg?.fld === 1 ? 'NO' : otherArg?.fld === 2 ? 'YES' : '';
        if (currBetKey === marketKeys.fancy) {
            customPayload = {
                odds: fOdds,
                oddsType: fancyOddType,
                marketId: otherArg?.selectionId,
                betType: marketKeys.fancy,
                teamName: fsize,
                anyOdds: acceptAnyOdds
            }
        }

        await Axios.post(apiUrl, {
            clientId: props.userid,
            token: props.utoken,
            eventId: eventid,
            marketId: matchMarket[currBetKey]?.marketId,
            odds: currOdds,
            oddsType: oddsType,
            betType: 'match',
            coin: defaultStake,
            teamName: teamName,
            dupbet: dupBet,
            ...customPayload,
        }, { withCredentials: true }
        ).then(response => {
            props.handleRefreshhh(false);
            let result = { ...response, data: response?.data };
            if (currBetKey === marketKeys.premium) {
                result.data = response?.data?.value;
            }

            if (result.data == 6) {
                props.changeMsgNumber(1);
                setTimeout(() => {
                    props.changeMsgNumber(0);
                }, 1500);
                handleRefresh();

                if (eventT == 1 && isGoalMarket) {
                    props.changeBetInfo({ oddstype: oddsType === 'KHAI' ? 'LAY' : 'BACK', runner: goalsOnKeyBordOn?.runnerName, amount: defaultStake, rate: goalsOnKeyBordOn.odd });
                    plMarket(goalsSoccerApiMarketData[currBetKey]).then(obj => {
                        // console.log('goalsSoccerApiMarketData',goalsSoccerApiMarketData)
                        // console.log('obj', obj)
                        goalsSoccerApiPlMarketData[currBetKey] = obj
                    })
                } else if (currBetKey === marketKeys.fancy) {
                    props.changeBetInfo({ oddstype: fancyOddType, runner: otherArg?.runnerName, amount: defaultStake, rate: fOdds + '/' + fsize });
                    plFancy();
                } else {
                    props.changeBetInfo({ oddstype: oddsType === 'KHAI' ? 'LAY' : 'BACK', runner: teamName === 'A' ? matchMarket[currBetKey]?.runnerName1 : matchMarket[currBetKey]?.runnerName2, amount: defaultStake, rate: currOdds });
                    if (currBetKey === marketKeys.premium) {
                        plPremiumMarket({
                            eventId: eventid,
                        }).then((PPldata) => {
                            setPremPlData(PPldata.data);
                        })
                    } else {
                        plMarket(matchMarket[currBetKey]).then(obj => {
                            if (currBetKey === marketKeys.matchOdds) setpnlMarket(obj)
                            if (currBetKey === marketKeys.bookmaker) setpnlBook(obj)
                            if (currBetKey === marketKeys.completedMatch) setpnlCompMatch(obj)
                            if (currBetKey === marketKeys.superOver) setpnlSuperMatch(obj)
                            if (currBetKey === marketKeys.tiedMatch) setpnlTieMatch(obj)
                            if (currBetKey === marketKeys.winToss) setpnlManual(obj)
                        });
                    }

                }

            }
            else if (result.data == 1) {
                props.changeMsgNumber(3);
                setTimeout(() => {
                    props.changeMsgNumber(0);
                }, 1500);
            }

            else if (result.data == 2) {
                props.changeMsgNumber(4);
                setTimeout(() => {
                    props.changeMsgNumber(0);
                }, 1500);
            }
            else if (result.data == 3) {
                props.changeMsgNumber(5);
                setTimeout(() => {
                    props.changeMsgNumber(0);
                }, 1500);
            }
            else if (result.data == 4) {
                props.changeMsgNumber(6);
                setTimeout(() => {
                    props.changeMsgNumber(0);
                }, 1500);
            }
            else if (result.data == 5) {
                props.changeMsgNumber(7);
                setTimeout(() => {
                    props.changeMsgNumber(0);
                }, 1500);
            }
            else if (result.data == 7) {
                props.changeMsgNumber(8);
                setTimeout(() => {
                    props.changeMsgNumber(0);
                }, 1500);
            }
            else if (result.data == 123) {
                props.changeMsgNumber(9);
                setTimeout(() => {
                    props.changeMsgNumber(0);
                }, 1500);
            }
            else if (result.data == 13) {
                props.changeMsgNumber(10);
                setTimeout(() => {
                    props.changeMsgNumber(0);
                }, 1500);
            }
            else if (result.data.charAt(0) == '8') {
                props.changeMsgNumber(11);
                setTimeout(() => {
                    props.changeMsgNumber(0);
                }, 1500);
                props.changeStakeInfo({ minStake: result.data.split("_")[1], maxStake: result.data.split("_")[1] });
            }
            else if (result.data.charAt(0) == 9) {
                props.changeMsgNumber(12);
                setTimeout(() => {
                    props.changeMsgNumber(0);
                }, 1500);
                props.changeStakeInfo({ minStake: result.data.split("_")[1], maxStake: result.data.split("_")[1] });
            } else {
                props.changeMsgNumber(7);
                setTimeout(() => {
                    props.changeMsgNumber(0);
                }, 1500);
            }


        }).catch(e => {
            console.log(e);
        });
    };



    //marketConfirmBox
    const marketBetClicked = () => {
        setshowConfirmBet(true);
    }
    const closeConfirmBet = () => {
        setshowConfirmBet(false);
    }
    const TossCloseBetClicked = () => {
        setshowTossConfirmBet(false);
    }

    //TieMatch ConfirmBox
    const TieMatchBetClicked = () => {
        setshowTieConfirmBet(true);
    }
    const TieMatchcloseConfirmBet = () => {
        setshowTieConfirmBet(false);
    }

    //superMatch confrimBox

    const SuperMatchBetClicked = () => {
        setshowSupConfirmBet(true);
    }
    const TossBetClicked = () => {
        setshowTossConfirmBet(true);
    }
    const SuperMatchcloseConfirmBet = () => {
        setshowSupConfirmBet(false);
    }

    //Com match

    const ComMatchBetClicked = () => {
        setshowComConfirmBet(true);
    }
    const ComMatchcloseConfirmBet = () => {
        setshowComConfirmBet(false);
    }

    const handleNewRefresh = () => {
        setloading(true);
        setTimeout(() => { setloading(false); }, 600);
    }


    const OpenPremiumBetLay = (index, index2) => {
        setdefaultStake(dStake);
        setPremclick(index);
        setPremclick2(index2);
        setselectPremLay(1);
        setpremiumfield(1);

    }

    const closePremiumBet = () => {
        setPremclick(-1);
        setPremclick2(-1);
        setselectPremLay(0);
    }


    return (
        <React.Fragment>
            {/*<LoadingOverlay*/}
            {/*    show={(loading || newrefresh)}*/}
            {/*/>*/}

            <Suspense fallback={<></>}>
                <Tvstream {...props} marketStartTime={marketStartTime} />
            </Suspense>


            {newrefresh === false && <React.Fragment>
                {showConfirmGoalsBet && <ConfirmBet handleRefreshhh={props.handleRefreshhh} mOdds={goalsOnKeyBordOn?.odd} coin={defaultStake} oddsType={oddsType === 'LAGAI' ? 'BACK' : 'LAY'} runnerName={goalsOnKeyBordOn?.runnerName} closeConfirmBet={() => {
                    setShowConfirmGoalsBet(false);
                    onCloseGoalMarketLayBack({});
                }} marketBet={() => placeBet(goalsOnKeyBordOn?.currMarket)} />}
                {showConfirmBet && <ConfirmBet handleRefreshhh={props.handleRefreshhh} mOdds={mOdds} coin={defaultStake} oddsType={oddsType === 'LAGAI' ? 'BACK' : 'LAY'} runnerName={teamName === 'A' ? marketData.runnerName1 : marketData.runnerName2} closeConfirmBet={closeConfirmBet} marketBet={() => placeBet(marketKeys.matchOdds)} />}
                {/* TieMarket */}
                {showTieConfirmBet && <ConfirmBet handleRefreshhh={props.handleRefreshhh} mOdds={TOdds} coin={defaultStake} oddsType={oddsType === 'LAGAI' ? 'BACK' : 'LAY'} runnerName={teamName === 'A' ? 'Yes' : 'No'} closeConfirmBet={TieMatchcloseConfirmBet} marketBet={() => placeBet(marketKeys.tiedMatch)} />}
                {/* // ComMarket */}
                {showComConfirmBet && <ConfirmBet handleRefreshhh={props.handleRefreshhh} mOdds={COdds} coin={defaultStake} oddsType={oddsType === 'LAGAI' ? 'BACK' : 'LAY'} runnerName={teamName === 'A' ? 'Yes' : 'No'} closeConfirmBet={ComMatchcloseConfirmBet} marketBet={() => placeBet(marketKeys.completedMatch)} />}
                {/* //SuperMarket */}
                {showSupConfirmBet && <ConfirmBet handleRefreshhh={props.handleRefreshhh} mOdds={SOdds} coin={defaultStake} oddsType={oddsType === 'LAGAI' ? 'BACK' : 'LAY'} runnerName={teamName === 'A' ? SuperMatchData.runnerName1 : SuperMatchData.runnerName2} closeConfirmBet={SuperMatchcloseConfirmBet} marketBet={() => placeBet(marketKeys.superOver)} />}

                {/* //TossMarket */}
                {showTossConfirmBet && <ConfirmBet handleRefreshhh={props.handleRefreshhh} mOdds={Maodds} coin={defaultStake} oddsType={oddsType === 'LAGAI' ? 'BACK' : 'LAY'} runnerName={teamName === 'A' ? ManualData.runnerName1 : ManualData.runnerName2} closeConfirmBet={TossCloseBetClicked} marketBet={() => placeBet(marketKeys.winToss)} />}

                <div id="mob-main-content" className="mob-main-content" eventtype={4} eventid={31102920} marketid="1.192573657">
                    {eventT == '138' && <>
                        <div className="game-wrap">
                            <h4 id="gameInfo" className="game-info">
                                Kabaddi
                                <ul id="infoIcon" className="info-icon">
                                    <li id="inPlayIcon" style={{}}>
                                        <img className="info-inplay" src={Transparent} />In-Play
                                    </li>
                                </ul>
                            </h4>

                            <table id="gameTeam" className="game-team" style={{}}>
                                <tbody><tr>
                                    <th>
                                        <h4 id="teamHome" style={{ color: '#fff' }}>{marketData.runnerName1} - {marketData.runnerName2}</h4>
                                        <ul id="time" className="scores-time"><li>{marketData.marketStartTime}</li></ul>
                                    </th>
                                    <td className="team-refresh"><a id="refresh" className="refresh" href="#" /></td>
                                </tr>
                                </tbody></table>
                        </div>
                    </>}


                    <TabButtons
                        handleRefreshhh={props.handleRefreshhh}
                        sethighlightTab={sethighlightTab}
                        highlightTab={highlightTab}
                        marketData={marketData}
                        ManualData={ManualData}
                        CompMatchData={CompMatchData}
                        TieMatchData={TieMatchData}
                        SuperMatchData={SuperMatchData}
                        goalsSoccerApiMarketData={goalsSoccerApiMarketData}
                        onCloseGoalMarketLayBack={onCloseGoalMarketLayBack}
                    />

                    {/* {TiedCric === 2 && <>
                    <React.Fragment> */}
                    {loading === false &&
                        <React.Fragment>

                            {/* matchodd market */}
                            {highlightTab === marketKeys.matchOdds && hidematchOdds != '1' &&
                                <div id="marketBetsWrap" style={(marketBetStatus != 1) ? { pointerEvents: 'none' } : null} className={`bets-wrap ${(marketBetStatus != 1) ? "disabled" : ""}`}>

                                    <RunnerHeader
                                        totalStake={totalStake}
                                        onHideMaxStack={handleMaxStakeclose}
                                        maxStake={Maxstake && marketData.maxStack}
                                        coinFactor={coinFactor}
                                    />

                                    {marketData.matchStatus == 1 && <section style={{ display: 'flex', flexDirection: 'column' }}>
                                        {/* r1 */}
                                        <RunnerRow
                                            blockStatus={
                                                StatusSS === 1 || StatusSS === 0 || StatusSS === 2 &&
                                                <dd id="suspend" className="suspend" style={StatusSS != 1 ? { display: 'flex', zIndex: 22 } : { display: 'none' }}><p>Suspend
                                                </p></dd>
                                            }
                                            firstPl={pnlMarket?.teamA && (pnlMarket?.teamA != 0 || pnlMarket?.teamB != 0 || pnlMarket?.tie != 0) && <li id="withoutBet" className={`${pnlMarket?.teamA > 0 ? "win" : "lose"}`} >{pnlMarket?.teamA > 0 ? pnlMarket?.teamA : '(' + Math.abs(pnlMarket?.teamA) + ')'}</li>}
                                            secondPl={(matchOddClickKeybordRunner) && defaultStake != ' ' && defaultStake != '' && <li id="after" className={`${pnlMarket2.teamA > 0 ? "to-win" : "to-lose"}`} >{pnlMarket2.teamA > 0 ? pnlMarket2.teamA : '(' + Math.abs(pnlMarket2.teamA) + ')'}</li>}
                                            runnerName={marketData.runnerName1}
                                            backSelected={selectMatchBack === 1}
                                            laySelected={selectMatchBack === 2}
                                            runnerBackRate={runner1BackRate1}
                                            runnerBackSize={runner1BackSize1}
                                            runnerLayRate={runner1LayRate1}
                                            runnerLaySize={runner1LaySize1}
                                            onClick={(isLayOrBack, rate) => !(runner1BackRate1 >= 25) ? changeMatchodds(isLayOrBack == 'back' ? 1 : 2, 1, rate) : null}
                                            style={{
                                                order: 1,
                                            }}
                                            className={(runner1BackRate1 >= 25) ? "disabled" : "null"}
                                        />
                                        {(matchOddClickKeybordRunner === 1 || matchOddClickKeybordRunner === 2 || matchOddClickKeybordRunner === 3) &&
                                            <PlaceBetKeybord
                                                layBack={(selectMatchBack === 1 || selectMatchBack === 3 || selectMatchBack === 5) ? "back" : "lay"}
                                                odds={mOdds}
                                                onClickOddMinus={() => (mOdds != '' || mOdds != ' ') ? setmOdds(parseFloat((parseFloat(mOdds) - 0.01).toFixed(2)).toString()) : null}
                                                onClickOddPlus={() => (mOdds != '' || mOdds != ' ') ? setmOdds(parseFloat((parseFloat(mOdds) + 0.01).toFixed(2)).toString()) : null}
                                                onClickStackMinus={() => (defaultStake === '' || defaultStake === ' ') ? setdefaultStake((parseInt(marketData.minStack) * coinFactor).toString()) : (parseInt(defaultStake) > 0) ? setdefaultStake((parseInt(defaultStake) - 1).toString()) : null}
                                                onClickStackPlus={() => (defaultStake === '' || defaultStake === ' ') ? setdefaultStake((parseInt(marketData.minStack) * coinFactor).toString()) : setdefaultStake((parseInt(defaultStake) + 1).toString())}
                                                defaultStake={defaultStake}
                                                onInputChnageStack={e => setdefaultStake(e)}
                                                onCancel={() => { closeMatchoddsBet(); setdefaultStake(dStake); }}
                                                onPlace={() => { marketBetClicked(); closeMatchoddsBet(); }}
                                                ssStack={defaultStakesFromApi}
                                                minStack={marketData.minStack}
                                                coinFactor={coinFactor}
                                                style={{
                                                    order: (matchOddClickKeybordRunner + 1)
                                                }}
                                            />}

                                        {/* r2 */}
                                        <RunnerRow
                                            blockStatus={StatusSS === 1 || StatusSS === 0 || StatusSS === 2 &&
                                                <dd id="suspend" className="suspend" style={StatusSS != 1 ? { display: 'flex', zIndex: 22 } : { display: 'none' }}><p>Suspend
                                                </p></dd>}
                                            firstPl={pnlMarket?.teamA && (pnlMarket?.teamA != 0 || pnlMarket?.teamB != 0 || pnlMarket?.tie != 0) && <li id="withoutBet" className={`${pnlMarket?.teamB > 0 ? "win" : "lose"}`} >{pnlMarket?.teamB > 0 ? pnlMarket?.teamB : '(' + Math.abs(pnlMarket?.teamB) + ')'}</li>}
                                            secondPl={(matchOddClickKeybordRunner) && defaultStake != ' ' && defaultStake != '' && <li id="after" className={`${pnlMarket2.teamB > 0 ? "to-win" : "to-lose"}`} >{pnlMarket2.teamB > 0 ? pnlMarket2.teamB : '(' + Math.abs(pnlMarket2.teamB) + ')'}</li>}
                                            runnerName={marketData.runnerName2}
                                            backSelected={selectMatchBack === 3}
                                            laySelected={selectMatchBack === 4}
                                            runnerBackRate={runner2BackRate1}
                                            runnerBackSize={runner2BackSize1}
                                            runnerLayRate={runner2LayRate1}
                                            runnerLaySize={runner2LaySize1}
                                            onClick={(isLayOrBack, rate) => !(runner2BackRate1 >= 25) ? changeMatchodds(isLayOrBack == 'back' ? 3 : 4, 2, rate) : null}
                                            style={{
                                                order: 2,
                                            }}
                                            className={(runner2BackRate1 >= 25) ? "disabled" : "null"}
                                        />

                                        {/* r3 */}
                                        {marketData.runnerId3 && marketData.runnerId3 != ' ' &&
                                            <RunnerRow
                                                blockStatus={StatusSS === 1 || StatusSS === 0 || StatusSS === 2 &&
                                                    <dd id="suspend" className="suspend" style={StatusSS != 1 ? { display: 'flex', zIndex: 22 } : { display: 'none' }}><p>Suspend
                                                    </p></dd>}
                                                firstPl={pnlMarket?.teamA && (pnlMarket?.teamA != 0 || pnlMarket?.teamB != 0 || pnlMarket?.tie != 0) && <li id="withoutBet" className={`${pnlMarket?.tie > 0 ? "win" : "lose"}`} >{pnlMarket?.tie > 0 ? pnlMarket?.tie : '(' + Math.abs(pnlMarket?.tie) + ')'}</li>}
                                                secondPl={(matchOddClickKeybordRunner) && defaultStake != ' ' && defaultStake != '' && <li id="after" className={`${pnlMarket2?.tie > 0 ? "to-win" : "to-lose"}`} >{pnlMarket2?.tie > 0 ? pnlMarket2?.tie : '(' + Math.abs(pnlMarket2?.tie) + ')'}</li>}
                                                runnerName="The Draw"
                                                backSelected={selectMatchBack === 5}
                                                laySelected={selectMatchBack === 6}
                                                runnerBackRate={tieBackRate1}
                                                runnerBackSize={tieBackSize1}
                                                runnerLayRate={tieLayRate1}
                                                runnerLaySize={tieLaySize1}
                                                onClick={(isLayOrBack, rate) => !(tieBackRate1 >= 25) ? changeMatchodds(isLayOrBack == 'back' ? 5 : 6, 3, rate) : null}
                                                style={{
                                                    order: 3,
                                                }}
                                                className={(tieBackRate1 >= 25) ? "disabled" : "null"}
                                            />
                                        }

                                    </section>}
                                </div>
                            }

                            {/* tidematch market */}
                            {highlightTab === marketKeys.tiedMatch &&
                                <div id="marketBetsWrap" style={(TiemarketBetStatus != 1) ? { pointerEvents: 'none' } : null} className={`bets-wrap ${(TiemarketBetStatus != 1) ? "disabled" : ""}`}>
                                    <RunnerHeader
                                        totalStake={totalStake}
                                        onHideMaxStack={handleMaxStakeclose}
                                        maxStake={Maxstake && TieMatchData.maxStack}
                                        coinFactor={coinFactor}
                                    />

                                    {TieMatchData.matchStatus == 1 && <section style={{ display: 'flex', flexDirection: 'column' }}>
                                        {/* r1 */}
                                        <RunnerRow
                                            blockStatus={<dd id="suspend" className="suspend" style={TiemarketBetStatus != 1 ? { display: 'flex' } : { display: 'none' }}><p>Suspend
                                            </p></dd>}
                                            firstPl={pnlTieMatch?.teamA && (pnlTieMatch?.teamA != 0 || pnlTieMatch?.teamB != 0 || pnlTieMatch.tie != 0) && <li id="withoutBet" className={`${pnlTieMatch?.teamA > 0 ? "win" : "lose"}`} >{pnlTieMatch?.teamA > 0 ? pnlTieMatch?.teamA : '(' + Math.abs(pnlTieMatch?.teamA) + ')'}</li>}
                                            secondPl={(tieMatchClickKeybordRunner) && defaultStake != ' ' && defaultStake != '' && <li id="after" className={`${pnlTieMatch2.teamA > 0 ? "to-win" : "to-lose"}`} >{pnlTieMatch2.teamA > 0 ? pnlTieMatch2.teamA : '(' + Math.abs(pnlTieMatch2.teamA) + ')'}</li>}
                                            runnerName="Yes"
                                            backSelected={selectTieMatchBack === 1}
                                            laySelected={selectTieMatchBack === 2}
                                            runnerBackRate={Tierunner1BackRate1}
                                            runnerBackSize={Tierunner1BackSize1}
                                            runnerLayRate={Tierunner1LayRate1}
                                            runnerLaySize={Tierunner1LaySize1}
                                            onClick={(isLayOrBack, rate) => changeTieMatchBook(isLayOrBack == 'back' ? 1 : 2, 1, rate)}
                                            style={{
                                                order: 1,
                                            }}
                                        />
                                        {(tieMatchClickKeybordRunner === 1 || tieMatchClickKeybordRunner === 2) &&
                                            <PlaceBetKeybord
                                                layBack={(selectTieMatchBack === 1 || selectTieMatchBack === 3 || selectTieMatchBack === 5) ? "back" : "lay"}
                                                odds={TOdds}
                                                onClickOddMinus={() => (TOdds != '' || TOdds != ' ') ? setTOdds(parseFloat((parseFloat(TOdds) - 0.01).toFixed(2)).toString()) : null}
                                                onClickOddPlus={() => (TOdds != '' || TOdds != ' ') ? setTOdds(parseFloat((parseFloat(TOdds) + 0.01).toFixed(2)).toString()) : null}
                                                onClickStackMinus={() => (defaultStake === '' || defaultStake === ' ') ? setdefaultStake((parseInt(TieMatchData.minStack) * coinFactor).toString()) : (parseInt(defaultStake) > 0) ? setdefaultStake((parseInt(defaultStake) - 1).toString()) : null}
                                                onClickStackPlus={() => (defaultStake === '' || defaultStake === ' ') ? setdefaultStake((parseInt(TieMatchData.minStack) * coinFactor).toString()) : setdefaultStake((parseInt(defaultStake) + 1).toString())}
                                                defaultStake={defaultStake}
                                                onInputChnageStack={e => setdefaultStake(e)}
                                                onCancel={() => { closeTieMatchBet(); setdefaultStake(dStake); }}
                                                onPlace={() => { TieMatchBetClicked(); closeTieMatchBet(); }}
                                                ssStack={defaultStakesFromApi}
                                                minStack={TieMatchData.minStack}
                                                coinFactor={coinFactor}
                                                style={{
                                                    order: (tieMatchClickKeybordRunner + 1)
                                                }}
                                            />}

                                        {/* r2 */}
                                        <RunnerRow
                                            blockStatus={<dd id="suspend" className="suspend" style={TiemarketBetStatus != 1 ? { display: 'flex' } : { display: 'none' }}><p>Suspend
                                            </p></dd>}
                                            firstPl={pnlTieMatch?.teamA && (pnlTieMatch?.teamA != 0 || pnlTieMatch?.teamB != 0 || pnlTieMatch.tie != 0) && <li id="withoutBet" className={`${pnlTieMatch?.teamB > 0 ? "win" : "lose"}`} >{pnlTieMatch?.teamB > 0 ? pnlTieMatch?.teamB : '(' + Math.abs(pnlTieMatch?.teamB) + ')'}</li>}
                                            secondPl={tieMatchClickKeybordRunner && defaultStake != ' ' && defaultStake != '' && <li id="after" className={`${pnlTieMatch2?.teamB > 0 ? "to-win" : "to-lose"}`} >{pnlTieMatch2?.teamB > 0 ? pnlTieMatch2?.teamB : '(' + Math.abs(pnlTieMatch2?.teamB) + ')'}</li>}
                                            runnerName="No"
                                            backSelected={selectTieMatchBack === 3}
                                            laySelected={selectTieMatchBack === 4}
                                            runnerBackRate={Tierunner2BackRate1}
                                            runnerBackSize={Tierunner2BackSize1}
                                            runnerLayRate={Tierunner2LayRate1}
                                            runnerLaySize={Tierunner2LaySize1}
                                            onClick={(isLayOrBack, rate) => changeTieMatchBook(isLayOrBack == 'back' ? 3 : 4, 2, rate)}
                                            style={{
                                                order: 2
                                            }}
                                        />



                                    </section>}


                                </div>
                            }
                            {/* tierate componantend */}


                            {/* complated market */}
                            {highlightTab === marketKeys.completedMatch &&
                                <div id="marketBetsWrap" style={(CommarketBetStatus != 1) ? { pointerEvents: 'none' } : null} className={`bets-wrap ${(CommarketBetStatus != 1) ? "disabled" : ""}`}>
                                    <RunnerHeader
                                        totalStake={totalStake}
                                        onHideMaxStack={handleMaxStakeclose}
                                        maxStake={Maxstake && CompMatchData.maxStack}
                                        coinFactor={coinFactor}
                                    />

                                    {CompMatchData.matchStatus == 1 && <section style={{ display: 'flex', flexDirection: 'column' }}>
                                        {/* r1 */}
                                        <RunnerRow
                                            firstPl={pnlCompMatch?.teamA && (pnlCompMatch?.teamA != 0 || pnlCompMatch?.teamB != 0 || pnlCompMatch.tie != 0) && <li id="withoutBet" className={`${pnlCompMatch?.teamA > 0 ? "win" : "lose"}`} >{pnlCompMatch?.teamA > 0 ? pnlCompMatch?.teamA : '(' + Math.abs(pnlCompMatch?.teamA) + ')'}</li>}
                                            secondPl={(ComMatchclick2 || ComMatchclick || ComMatchclick3) && defaultStake != ' ' && defaultStake != '' && <li id="after" className={`${pnlCompMatch2.teamA > 0 ? "to-win" : "to-lose"}`} >{pnlCompMatch2.teamA > 0 ? pnlCompMatch2.teamA : '(' + Math.abs(pnlCompMatch2.teamA) + ')'}</li>}
                                            runnerName="Yes"
                                            backSelected={selectCompMatchBack === 1}
                                            laySelected={selectCompMatchBack === 2}
                                            runnerBackRate={Comrunner1BackRate1}
                                            runnerBackSize={Comrunner1BackSize1}
                                            runnerLayRate={Comrunner1LayRate1}
                                            runnerLaySize={Comrunner1LaySize1}
                                            onClick={(isLayOrBack, rate) => changeCompMatchBook(isLayOrBack == 'back' ? 1 : 2, 1, rate)}
                                            style={{
                                                order: 1
                                            }}
                                        />
                                        {(comMatchClickKeybordRunner === 1 || comMatchClickKeybordRunner === 2 || comMatchClickKeybordRunner === 3) &&
                                            <PlaceBetKeybord
                                                layBack={(selectCompMatchBack === 1 || selectCompMatchBack === 3 || selectCompMatchBack === 5) ? "back" : "lay"}
                                                odds={COdds}
                                                onClickOddMinus={() => (COdds != '' || COdds != ' ') ? setCOdds(parseFloat((parseFloat(COdds) - 0.01).toFixed(2)).toString()) : null}
                                                onClickOddPlus={() => (COdds != '' || COdds != ' ') ? setCOdds(parseFloat((parseFloat(COdds) + 0.01).toFixed(2)).toString()) : null}
                                                onClickStackMinus={() => (defaultStake === '' || defaultStake === ' ') ? setdefaultStake((parseInt(CompMatchData.minStack) * coinFactor).toString()) : (parseInt(defaultStake) > 0) ? setdefaultStake((parseInt(defaultStake) - 1).toString()) : null}
                                                onClickStackPlus={() => (defaultStake === '' || defaultStake === ' ') ? setdefaultStake((parseInt(CompMatchData.minStack) * coinFactor).toString()) : setdefaultStake((parseInt(defaultStake) + 1).toString())}
                                                defaultStake={defaultStake}
                                                onInputChnageStack={e => setdefaultStake(e)}
                                                onCancel={() => { closeCompMatchBet(); setdefaultStake(dStake); }}
                                                onPlace={() => { ComMatchBetClicked(); closeCompMatchBet(); }}
                                                ssStack={defaultStakesFromApi}
                                                minStack={CompMatchData.minStack}
                                                coinFactor={coinFactor}
                                                style={{
                                                    order: (comMatchClickKeybordRunner + 1)
                                                }}
                                            />}

                                        {/* r2 */}
                                        <RunnerRow
                                            firstPl={pnlCompMatch?.teamA && (pnlCompMatch?.teamA != 0 || pnlCompMatch?.teamB != 0 || pnlCompMatch.tie != 0) && <li id="withoutBet" className={`${pnlCompMatch?.teamB > 0 ? "win" : "lose"}`} >{pnlCompMatch?.teamB > 0 ? pnlCompMatch?.teamB : '(' + Math.abs(pnlCompMatch.teamB) + ')'}</li>}
                                            secondPl={(ComMatchclick2 || ComMatchclick || ComMatchclick3) && defaultStake != ' ' && defaultStake != '' && <li id="after" className={`${pnlCompMatch2?.teamB > 0 ? "to-win" : "to-lose"}`} >{pnlCompMatch2?.teamB > 0 ? pnlCompMatch2?.teamB : '(' + Math.abs(pnlCompMatch2?.teamB) + ')'}</li>}
                                            runnerName="No"
                                            backSelected={selectCompMatchBack === 3}
                                            laySelected={selectCompMatchBack === 4}
                                            runnerBackRate={Comrunner2BackRate1}
                                            runnerBackSize={Comrunner2BackSize1}
                                            runnerLayRate={Comrunner2LayRate1}
                                            runnerLaySize={Comrunner2LaySize1}
                                            onClick={(isLayOrBack, rate) => changeCompMatchBook(isLayOrBack == 'back' ? 3 : 4, 2, rate)}
                                            style={{
                                                order: 2
                                            }}
                                        />

                                    </section>}


                                </div>
                            }

                            {/* superover market */}
                            {highlightTab === marketKeys.superOver &&
                                <div id="marketBetsWrap" style={(SupmarketBetStatus != 1) ? { pointerEvents: 'none' } : null} className={`bets-wrap ${(SupmarketBetStatus != 1) ? "disabled" : ""}`}>
                                    <RunnerHeader
                                        totalStake={totalStake}
                                        onHideMaxStack={handleMaxStakeclose}
                                        maxStake={Maxstake && SuperMatchData.maxStack}
                                        coinFactor={coinFactor}
                                    />

                                    {SuperMatchData.matchStatus == 1 && <section style={{ display: 'flex', flexDirection: 'column' }}>
                                        {/* r1 */}
                                        <RunnerRow
                                            firstPl={pnlSuperMatch?.teamA && (pnlSuperMatch?.teamA != 0 || pnlSuperMatch?.teamB != 0 || pnlSuperMatch.tie != 0) && <li id="withoutBet" className={`${pnlSuperMatch?.teamA > 0 ? "win" : "lose"}`} >{pnlSuperMatch?.teamA > 0 ? pnlSuperMatch?.teamA : '(' + Math.abs(pnlSuperMatch?.teamA) + ')'}</li>}
                                            secondPl={(SuperMatchclick2 || SuperMatchclick || SuperMatchclick3) && defaultStake != ' ' && defaultStake != '' && <li id="after" className={`${pnlSuperMatch2.teamA > 0 ? "to-win" : "to-lose"}`} >{pnlSuperMatch2.teamA > 0 ? pnlSuperMatch2.teamA : '(' + Math.abs(pnlSuperMatch2.teamA) + ')'}</li>}
                                            runnerName={SuperMatchData.runnerName1}
                                            backSelected={selectSuperMatchBack === 1}
                                            laySelected={selectSuperMatchBack === 2}
                                            runnerBackRate={Suprunner1BackRate1}
                                            runnerBackSize={Suprunner1BackSize1}
                                            runnerLayRate={Suprunner1LayRate1}
                                            runnerLaySize={Suprunner1LaySize1}
                                            onClick={(isLayOrBack, rate) => changeSuperMatchBook(isLayOrBack == 'back' ? 1 : 2, 1, rate)}
                                            style={{
                                                order: 1
                                            }}
                                        />
                                        {(superMatchClickKeybordRunner === 1 || superMatchClickKeybordRunner === 2 || superMatchClickKeybordRunner === 3) &&
                                            <PlaceBetKeybord
                                                layBack={(selectSuperMatchBack === 1 || selectSuperMatchBack === 3 || selectSuperMatchBack === 5) ? "back" : "lay"}
                                                odds={SOdds}
                                                onClickOddMinus={() => (SOdds != '' || SOdds != ' ') ? setSOdds(parseFloat((parseFloat(SOdds) - 0.01).toFixed(2)).toString()) : null}
                                                onClickOddPlus={() => (SOdds != '' || SOdds != ' ') ? setSOdds(parseFloat((parseFloat(SOdds) + 0.01).toFixed(2)).toString()) : null}
                                                onClickStackMinus={() => (defaultStake === '' || defaultStake === ' ') ? setdefaultStake((parseInt(SuperMatchData.minStack) * coinFactor).toString()) : (parseInt(defaultStake) > 0) ? setdefaultStake((parseInt(defaultStake) - 1).toString()) : null}
                                                onClickStackPlus={() => (defaultStake === '' || defaultStake === ' ') ? setdefaultStake((parseInt(SuperMatchData.minStack) * coinFactor).toString()) : setdefaultStake((parseInt(defaultStake) + 1).toString())}
                                                defaultStake={defaultStake}
                                                onInputChnageStack={e => setdefaultStake(e)}
                                                onCancel={() => { closeSuperMatchBet(); setdefaultStake(dStake); }}
                                                onPlace={() => { SuperMatchBetClicked(); closeSuperMatchBet(); }}
                                                ssStack={defaultStakesFromApi}
                                                minStack={SuperMatchData.minStack}
                                                coinFactor={coinFactor}
                                                style={{
                                                    order: (superMatchClickKeybordRunner + 1)
                                                }}
                                            />}

                                        {/* r2 */}
                                        <RunnerRow
                                            firstPl={pnlSuperMatch?.teamA && (pnlSuperMatch?.teamA != 0 || pnlSuperMatch?.teamB != 0 || pnlSuperMatch.tie != 0) && <li id="withoutBet" className={`${pnlSuperMatch?.teamB > 0 ? "win" : "lose"}`} >{pnlSuperMatch?.teamB > 0 ? pnlSuperMatch?.teamB : '(' + Math.abs(pnlSuperMatch?.teamB) + ')'}</li>}
                                            secondPl={(SuperMatchclick2 || SuperMatchclick || SuperMatchclick3) && defaultStake != ' ' && defaultStake != '' && <li id="after" className={`${pnlSuperMatch2?.teamB > 0 ? "to-win" : "to-lose"}`} >{pnlSuperMatch2?.teamB > 0 ? pnlSuperMatch2?.teamB : '(' + Math.abs(pnlSuperMatch2?.teamB) + ')'}</li>}
                                            runnerName={SuperMatchData.runnerName2}
                                            backSelected={selectSuperMatchBack === 3}
                                            laySelected={selectSuperMatchBack === 4}
                                            runnerBackRate={Suprunner2BackRate1}
                                            runnerBackSize={Suprunner2BackSize1}
                                            runnerLayRate={Suprunner2LayRate1}
                                            runnerLaySize={Suprunner2LaySize1}
                                            onClick={(isLayOrBack, rate) => changeMatchodds(isLayOrBack == 'back' ? 3 : 4, 2, rate)}
                                            style={{
                                                order: 2
                                            }}
                                        />

                                    </section>}

                                </div>
                            }

                            {/* wintoss market */}
                            {highlightTab === marketKeys.winToss &&
                                <div id="marketBetsWrap" style={(TossBetstatus != 1) ? { pointerEvents: 'none' } : null} className={`bets-wrap ${(TossBetstatus != 1) ? "disabled" : ""}`}>
                                    <RunnerHeader
                                        totalStake={totalStake}
                                        onHideMaxStack={handleMaxStakeclose}
                                        maxStake={Maxstake && SuperMatchData.maxStack}
                                        coinFactor={coinFactor}
                                    />

                                    {ManualData.matchStatus == 1 && <section style={{ display: 'flex', flexDirection: 'column' }}>
                                        {/* r1 */}
                                        <RunnerRow
                                            firstPl={pnlManual?.teamA && (pnlManual?.teamA != 0 || pnlManual?.teamB != 0 || pnlManual.tie != 0) && <li id="withoutBet" className={`${pnlManual?.teamA > 0 ? "win" : "lose"}`} >{pnlManual?.teamA > 0 ? pnlManual?.teamA : '(' + Math.abs(pnlManual?.teamA) + ')'}</li>}
                                            secondPl={(Manualclick2 || Manualclick || Manualclick3) && defaultStake != ' ' && defaultStake != '' && <li id="after" className={`${pnlManual2.teamA > 0 ? "to-win" : "to-lose"}`} >{pnlManual2.teamA > 0 ? pnlManual2.teamA : '(' + Math.abs(pnlManual2.teamA) + ')'}</li>}
                                            runnerName={ManualData.runnerName1}
                                            backSelected={selectManualBack === 1}
                                            laySelected={selectManualBack === 2}
                                            runnerBackRate={tossrunner1BackRate251}
                                            runnerBackSize={tossrunner1BackRate251}
                                            runnerLayRate={tossrunner1LayRate251}
                                            runnerLaySize={tossrunner1LaySize251}
                                            onClick={(isLayOrBack, rate) => changeTossBook(isLayOrBack == 'back' ? 1 : 2, 1, rate)}
                                            style={{
                                                order: 1
                                            }}
                                        />
                                        {(WintossMatchClickKeybordRunner === 1 || WintossMatchClickKeybordRunner === 2 || WintossMatchClickKeybordRunner === 3) &&
                                            <PlaceBetKeybord
                                                layBack={(selectManualBack === 1 || selectManualBack === 3 || selectManualBack === 5) ? "back" : "lay"}
                                                odds={Maodds}
                                                onClickOddMinus={() => (Maodds != '' || Maodds != ' ') ? setMaodds(parseFloat((parseFloat(Maodds) - 0.01).toFixed(2)).toString()) : null}
                                                onClickOddPlus={() => (Maodds != '' || Maodds != ' ') ? setMaodds(parseFloat((parseFloat(Maodds) + 0.01).toFixed(2)).toString()) : null}
                                                onClickStackMinus={() => (defaultStake === '' || defaultStake === ' ') ? setdefaultStake((parseInt(ManualData.minStack) * coinFactor).toString()) : (parseInt(defaultStake) > 0) ? setdefaultStake((parseInt(defaultStake) - 1).toString()) : null}
                                                onClickStackPlus={() => (defaultStake === '' || defaultStake === ' ') ? setdefaultStake((parseInt(ManualData.minStack) * coinFactor).toString()) : setdefaultStake((parseInt(defaultStake) + 1).toString())}
                                                defaultStake={defaultStake}
                                                onInputChnageStack={e => setdefaultStake(e)}
                                                onCancel={() => { closeManualBet(); setdefaultStake(dStake); }}
                                                onPlace={() => { TossBetClicked(); closeManualBet(); }}
                                                ssStack={defaultStakesFromApi}
                                                minStack={ManualData.minStack}
                                                coinFactor={coinFactor}
                                                style={{
                                                    order: (WintossMatchClickKeybordRunner + 1)
                                                }}
                                            />}

                                        {/* r2 */}
                                        <RunnerRow
                                            firstPl={pnlManual?.teamA && (pnlManual?.teamA != 0 || pnlManual?.teamB != 0 || pnlManual.tie != 0) && <li id="withoutBet" className={`${pnlManual?.teamB > 0 ? "win" : "lose"}`} >{pnlManual?.teamB > 0 ? pnlManual?.teamB : '(' + Math.abs(pnlManual?.teamB) + ')'}</li>}
                                            secondPl={(Manualclick2 || Manualclick || Manualclick3) && defaultStake != ' ' && defaultStake != '' && <li id="after" className={`${pnlManual2?.teamB > 0 ? "to-win" : "to-lose"}`} >{pnlManual2?.teamB > 0 ? pnlManual2?.teamB : '(' + Math.abs(pnlManual2?.teamB) + ')'}</li>}
                                            runnerName={ManualData.runnerName2}
                                            backSelected={selectManualBack === 3}
                                            laySelected={selectManualBack === 4}
                                            runnerBackRate={tossrunner2BackRate251}
                                            runnerBackSize={tossrunner2BackSize251}
                                            runnerLayRate={tossrunner2LayRate251}
                                            runnerLaySize={tossrunner2LaySize251}
                                            onClick={(isLayOrBack, rate) => changeTossBook(isLayOrBack == 'back' ? 3 : 4, 2, rate)}
                                            style={{
                                                order: 2
                                            }}
                                        />


                                    </section>}



                                </div>

                            }
                            {/* Copletedmatchratecom */}

                            {/* bookmaker market */}
                            {bookData2.matchStatus == 1 && <div id="bookMakerWrap" >

                                <div id="bookMakerMarket_30062362_44598" className="bets-wrap bets-bookmaker" style={{ display: 'block' }}>
                                    <h4>
                                        <a id="multiMarketPin" onClick={() => { handlebookpin(); }} className={`pin${bookpin ? "-on" : "-off"}`}>Pin to Muilti Markets</a>
                                        <span>Bookmaker Market</span>
                                        {/* <p>| Zero Commission</p> */}
                                        <a id="openBookMakerInfo" onClick={() => { setBookstake(true); }} className="btn-fancy_info" ></a>
                                        {Bookstake === true &&
                                            <div id="bookMakerMinMax" className="fancy_info-popup" style={{ display: 'flex' }}>
                                                <dl>
                                                    <dt  >Min / Max</dt>
                                                    <dd id="minMax"> {parseInt(bookData2.minStack) * coinFactor} / {parseInt(bookData2.maxStack) * coinFactor}</dd>
                                                </dl>
                                                <a id="closeBookMakerInfo" onClick={() => { setBookstake(false); }} className="close">Close</a>
                                            </div>}
                                    </h4>
                                    <dl className="bets-selections-head">
                                        <dt  >
                                        </dt>
                                        <dd className="mode-land"></dd>
                                        <dd className="mode-land"></dd>
                                        <dd>Back</dd>
                                        <dd>Lay</dd>
                                        <dd className="mode-land"></dd>
                                        <dd className="mode-land"></dd>
                                    </dl>

                                    <section style={{ display: 'flex', flexDirection: 'column' }}>

                                        {/* r1 */}
                                        <RunnerRow
                                            blockStatus={(bookData.gameStatus != 2 || bookData.runner1Status == 2) && <dd className="suspend-fancy" ><p id="info">{bookData.gameStatus == 10 && bookData.runner1Status == 1 ? 'Ball Running' : 'Suspend'}</p></dd>}
                                            firstPl={pnlBook?.teamA && (pnlBook?.teamA != 0 || pnlBook.teamB != 0 || pnlBook.tie != 0) && <li id="before" className={`${pnlBook?.teamA > 0 ? "win" : "lose"}`} >{pnlBook?.teamA > 0 ? pnlBook?.teamA : '(' + Math.abs(pnlBook?.teamA) + ')'}</li>}
                                            secondPl={(bookdataMatchClickKeybordRunner) && defaultStake != ' ' && defaultStake != '' && <li id="after" className={`${pnlBook2.teamA > 0 ? "to-win" : "to-lose"}`} >{pnlBook2.teamA > 0 ? pnlBook2.teamA : '(' + Math.abs(pnlBook2?.teamA) + ')'}</li>}
                                            runnerName={bookData2.runnerName1}
                                            backSelected={selectBack === 1}
                                            laySelected={selectBack === 2}
                                            runnerBackRate={bookData.runner1BackRate1}
                                            runnerLayRate={bookData.runner1LayRate1}
                                            onClick={(isLayOrBack, rate) => changeBook(isLayOrBack == 'back' ? 1 : 2, 1, rate)}
                                            veriant={marketKeys.bookmaker}
                                            style={{
                                                order: 1
                                            }}
                                        />

                                        {(bookdataMatchClickKeybordRunner && !(bookData.gameStatus != 2) && (bookData.runner1Status == 2 || bookData.runner2Status == 2 || bookData.runner3Status == 2)) &&
                                            <PlaceBetKeybord
                                                layBack={(selectBack === 1 || selectBack === 3 || selectBack === 5) ? "back" : "lay"}
                                                odds={bOdds}
                                                onClickOddMinus={() => (bOdds != '' || bOdds != ' ') ? setbOdds(parseFloat((parseFloat(bOdds) - 0.01).toFixed(2)).toString()) : null}
                                                onClickOddPlus={() => (bOdds != '' || bOdds != ' ') ? setbOdds(parseFloat((parseFloat(bOdds) + 0.01).toFixed(2)).toString()) : null}
                                                onClickStackMinus={() => (defaultStake === '' || defaultStake === ' ') ? setdefaultStake((parseInt(ManualData.minStack) * coinFactor).toString()) : (parseInt(defaultStake) > 0) ? setdefaultStake((parseInt(defaultStake) - 1).toString()) : null}
                                                onClickStackPlus={() => (defaultStake === '' || defaultStake === ' ') ? setdefaultStake((parseInt(ManualData.minStack) * coinFactor).toString()) : setdefaultStake((parseInt(defaultStake) + 1).toString())}
                                                defaultStake={defaultStake}
                                                onInputChnageStack={e => setdefaultStake(e)}
                                                onCancel={() => { closeBookBet(); setdefaultStake(dStake); }}
                                                onPlace={(e) => { e.preventDefault(); placeBet(marketKeys.bookmaker); closeBookBet(); setdefaultStake(dStake); }}
                                                ssStack={defaultStakesFromApi}
                                                minStack={bookData2.minStack}
                                                coinFactor={coinFactor}
                                                style={{
                                                    order: (bookdataMatchClickKeybordRunner + 1)
                                                }}
                                                oddInputDisable={true}
                                                renderBottom={(
                                                    <div className="bet-setting" style={{ display: ' flex' }}>
                                                        <a onClick={() => setacceptAnyOdds(!acceptAnyOdds)} className={`bet-check ${acceptAnyOdds === true ? "checked" : ""}`}>Accept Any Odds</a>
                                                        <span id="sportsBookMaxStake" style={{ display: ' none' }}><a id="getSportsBookMaxStakeBtn" className="max-bet"  >Max</a></span>
                                                    </div>
                                                )}
                                            />
                                        }

                                        {/* r2 */}
                                        <RunnerRow
                                            blockStatus={((bookData.gameStatus != 2) || bookData.runner2Status == 2) && <dd id="suspend" className="suspend-fancy" ><p id="info">{bookData.gameStatus == 10 && bookData.runner2Status == 1 ? 'Ball Running' : 'Suspend'}</p></dd>}
                                            firstPl={pnlBook.teamB && (pnlBook?.teamA != 0 || pnlBook.teamB != 0 || pnlBook.tie != 0) && <li id="before" className={`${pnlBook.teamB > 0 ? "win" : "lose"}`} >{pnlBook.teamB > 0 ? pnlBook.teamB : '(' + Math.abs(pnlBook.teamB) + ')'}</li>}
                                            secondPl={(bookdataMatchClickKeybordRunner) && defaultStake != ' ' && defaultStake != '' && <li id="after" className={`${pnlBook2.teamB > 0 ? "to-win" : "to-lose"}`} >{pnlBook2.teamB > 0 ? pnlBook2.teamB : '(' + Math.abs(pnlBook2.teamB) + ')'}</li>}
                                            runnerName={bookData2.runnerName2}
                                            backSelected={selectBack === 3}
                                            laySelected={selectBack === 4}
                                            runnerBackRate={bookData.runner2BackRate1}
                                            runnerLayRate={bookData.runner2LayRate1}
                                            onClick={(isLayOrBack, rate) => changeBook(isLayOrBack == 'back' ? 3 : 4, 2, rate)}
                                            veriant={marketKeys.bookmaker}
                                            style={{
                                                order: 2
                                            }}
                                        />

                                        {/* r3 */}
                                        {bookData2.runnerId3 && bookData2.runnerId3 != ' ' &&
                                            <RunnerRow
                                                blockStatus={((bookData.gameStatus != 2) || bookData.runner3Status == 2) && <dd id="suspend" className="suspend-fancy" ><p id="info">{bookData.gameStatus == 10 && bookData.runner3Status == 1 ? 'Ball Running' : 'Suspend'}</p></dd>}
                                                firstPl={pnlBook.tie && (pnlBook?.teamA != 0 || pnlBook.teamB != 0 || pnlBook.tie != 0) && <li id="before" className={`${pnlBook.tie > 0 ? "win" : "lose"}`} >{pnlBook.tie > 0 ? pnlBook.tie : '(' + Math.abs(pnlBook.tie) + ')'}</li>}
                                                secondPl={(bookdataMatchClickKeybordRunner) && defaultStake != ' ' && defaultStake != '' && <li id="after" className={`${pnlBook2.tie > 0 ? "to-win" : "to-lose"}`} >{pnlBook2.tie > 0 ? pnlBook2.tie : '(' + Math.abs(pnlBook2.tie) + ')'}</li>}
                                                runnerName="The Draw"
                                                backSelected={selectBack === 5}
                                                laySelected={selectBack === 6}
                                                runnerBackRate={bookData.tieBackRate1}
                                                runnerLayRate={bookData.tieLayRate1}
                                                onClick={(isLayOrBack, rate) => changeBook(isLayOrBack == 'back' ? 5 : 6, 3, rate)}
                                                veriant={marketKeys.bookmaker}
                                                style={{
                                                    order: 3
                                                }}
                                            />}
                                    </section>


                                </div>
                            </div>}

                            {eventT == 1 && (
                                <>
                                    {goalsSoccerApiMarketData[marketKeys.overUnderGoals('0.5')]?.eventName && highlightTab == marketKeys.overUnderGoals("0.5") && (
                                        <div style={(goalsSoccerSocket05Data?.status != 1) ? { pointerEvents: 'none' } : null} className={`bets-wrap ${(goalsSoccerSocket05Data?.status != 1) ? "disabled" : ""}`}>
                                            {goalsSoccerApiMarketData[marketKeys.overUnderGoals('0.5')].matchStatus == 1 && (
                                                <RunnerHeader
                                                    totalStake={goalsSoccerSocket05Data?.totalMatched}
                                                    onHideMaxStack={handleMaxStakeclose}
                                                    maxStake={Maxstake && goalsSoccerApiMarketData[marketKeys.overUnderGoals('0.5')]?.maxStack}
                                                    coinFactor={coinFactor}
                                                />
                                            )}
                                            {goalsSoccerApiMarketData[marketKeys.overUnderGoals('0.5')].matchStatus == 1 && <section style={{ display: 'flex', flexDirection: 'column' }}>
                                                {/* r1 */}
                                                <RunnerRow
                                                    blockStatus={
                                                        goalsSoccerSocket05Data.status === 1 || goalsSoccerSocket05Data.status === 0 || goalsSoccerSocket05Data.status === 2 &&
                                                        <dd className="suspend" style={goalsSoccerSocket05Data.status != 1 ? { display: 'flex', zIndex: 22 } : { display: 'none' }}><p>Suspend
                                                        </p></dd>
                                                    }
                                                    firstPl={goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('0.5')]?.teamA && (goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('0.5')]?.teamA != 0 || goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('0.5')]?.teamB != 0 || goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('0.5')]?.tie != 0) && <li id="withoutBet" className={`${goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('0.5')]?.teamA > 0 ? "win" : "lose"}`} >{goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('0.5')]?.teamA > 0 ? goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('0.5')]?.teamA : '(' + Math.abs(goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('0.5')]?.teamA) + ')'}</li>}
                                                    secondPl={(goalsOnKeyBordOn?.show) && defaultStake != ' ' && defaultStake != '' && <li id="after" className={`${goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('0.5')]?.teamA > 0 ? "to-win" : "to-lose"}`} >{goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('0.5')]?.teamA > 0 ? goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('0.5')]?.teamA : '(' + Math.abs(goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('0.5')]?.teamA) + ')'}</li>}
                                                    runnerName={goalsSoccerApiMarketData[marketKeys.overUnderGoals('0.5')]?.runnerName1}
                                                    backSelected={goalsOnKeyBordOn?.isBack && goalsOnKeyBordOn?.runner == 1}
                                                    laySelected={!goalsOnKeyBordOn?.isBack && goalsOnKeyBordOn?.runner == 1}
                                                    runnerBackRate={goalsSoccerSocket05Data.runner1BackRate1}
                                                    runnerBackSize={goalsSoccerSocket05Data.runner1BackSize1}
                                                    runnerLayRate={goalsSoccerSocket05Data.runner1LayRate1}
                                                    runnerLaySize={goalsSoccerSocket05Data.runner1LaySize1}
                                                    onClick={(isLayOrBack, rate) => onClickGoalMarketLayBack(isLayOrBack == 'back', 1, rate, marketKeys.overUnderGoals('0.5'))}
                                                    style={{
                                                        order: 1,
                                                    }}
                                                />

                                                {((goalsOnKeyBordOn?.show && goalsOnKeyBordOn?.currMarket == marketKeys.overUnderGoals('0.5')) && (goalsSoccerSocket05Data.gameStatus != 2 || goalsSoccerSocket05Data.runner1Status == 2 || goalsSoccerSocket05Data.runner2Status == 2)) &&
                                                    <PlaceBetKeybord
                                                        layBack={goalsOnKeyBordOn?.isBack ? "back" : "lay"}
                                                        odds={goalsOnKeyBordOn?.odd}
                                                        onClickOddMinus={() => (goalsOnKeyBordOn?.odd != '' || goalsOnKeyBordOn?.odd != ' ') ? setGoalsOnKeyBordOn({ ...goalsOnKeyBordOn, odd: parseFloat((parseFloat(goalsOnKeyBordOn?.odd) - 0.01).toFixed(2)).toString() }) : null}
                                                        onClickOddPlus={() => (goalsOnKeyBordOn?.odd != '' || goalsOnKeyBordOn?.odd != ' ') ? setGoalsOnKeyBordOn({ ...goalsOnKeyBordOn, odd: parseFloat((parseFloat(goalsOnKeyBordOn?.odd) + 0.01).toFixed(2)).toString() }) : null}
                                                        onClickStackMinus={() => (defaultStake === '' || defaultStake === ' ') ? setdefaultStake((parseInt(goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('0.5')].minStack) * coinFactor).toString()) : (parseInt(defaultStake) > 0) ? setdefaultStake((parseInt(defaultStake) - 1).toString()) : null}
                                                        onClickStackPlus={() => (defaultStake === '' || defaultStake === ' ') ? setdefaultStake((parseInt(goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('0.5')].minStack) * coinFactor).toString()) : setdefaultStake((parseInt(defaultStake) + 1).toString())}
                                                        defaultStake={defaultStake}
                                                        onInputChnageStack={e => setdefaultStake(e)}
                                                        onCancel={() => { onCloseGoalMarketLayBack(); setdefaultStake(dStake); }}
                                                        onPlace={(e) => { e.preventDefault(); setShowConfirmGoalsBet(true); tmpOnCloseGoalMarketLayBack(); }}
                                                        ssStack={defaultStakesFromApi}
                                                        minStack={goalsSoccerApiMarketData[marketKeys.overUnderGoals('0.5')].minStack}
                                                        coinFactor={coinFactor}
                                                        style={{
                                                            order: (goalsOnKeyBordOn?.runner + 1)
                                                        }}
                                                        oddInputDisable={true}
                                                    />
                                                }
                                                {/* r2 */}
                                                <RunnerRow
                                                    blockStatus={goalsSoccerSocket05Data.status === 1 || goalsSoccerSocket05Data.status === 0 || goalsSoccerSocket05Data.status === 2 &&
                                                        <dd className="suspend" style={goalsSoccerSocket05Data.status != 1 ? { display: 'flex', zIndex: 22 } : { display: 'none' }}><p>Suspend
                                                        </p></dd>}
                                                    firstPl={goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('0.5')]?.teamA && (goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('0.5')]?.teamA != 0 || goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('0.5')]?.teamB != 0 || goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('0.5')]?.tie != 0) && <li id="withoutBet" className={`${goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('0.5')]?.teamB > 0 ? "win" : "lose"}`} >{goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('0.5')]?.teamB > 0 ? goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('0.5')]?.teamB : '(' + Math.abs(goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('0.5')]?.teamB) + ')'}</li>}
                                                    secondPl={(goalsOnKeyBordOn?.show) && defaultStake != ' ' && defaultStake != '' && <li id="after" className={`${goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('0.5')]?.teamB > 0 ? "to-win" : "to-lose"}`} >{goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('0.5')]?.teamB > 0 ? goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('0.5')]?.teamB : '(' + Math.abs(goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('0.5')]?.teamB) + ')'}</li>}
                                                    runnerName={goalsSoccerApiMarketData[marketKeys.overUnderGoals('0.5')]?.runnerName2}
                                                    backSelected={goalsOnKeyBordOn?.isBack && goalsOnKeyBordOn?.runner == 2}
                                                    laySelected={!goalsOnKeyBordOn?.isBack && goalsOnKeyBordOn?.runner == 2}
                                                    runnerBackRate={goalsSoccerSocket05Data.runner2BackRate1}
                                                    runnerBackSize={goalsSoccerSocket05Data.runner2BackSize1}
                                                    runnerLayRate={goalsSoccerSocket05Data.runner2LayRate1}
                                                    runnerLaySize={goalsSoccerSocket05Data.runner2LaySize1}
                                                    onClick={(isLayOrBack, rate) => onClickGoalMarketLayBack(isLayOrBack == 'back', 2, rate, marketKeys.overUnderGoals('0.5'))}
                                                    style={{
                                                        order: 2,
                                                    }}
                                                />

                                            </section>}
                                        </div>
                                    )}
                                    {goalsSoccerApiMarketData[marketKeys.overUnderGoals('1.5')]?.eventName && highlightTab == marketKeys.overUnderGoals("1.5") && (
                                        <div style={(goalsSoccerSocket15Data?.status != 1) ? { pointerEvents: 'none' } : null} className={`bets-wrap ${(goalsSoccerSocket15Data?.status != 1) ? "disabled" : ""}`}>
                                            {goalsSoccerApiMarketData[marketKeys.overUnderGoals('1.5')].matchStatus == 1 && (
                                                <RunnerHeader
                                                    totalStake={goalsSoccerSocket15Data?.totalMatched}
                                                    onHideMaxStack={handleMaxStakeclose}
                                                    maxStake={Maxstake && goalsSoccerApiMarketData[marketKeys.overUnderGoals('1.5')]?.maxStack}
                                                    coinFactor={coinFactor}
                                                />
                                            )}
                                            {goalsSoccerApiMarketData[marketKeys.overUnderGoals('1.5')].matchStatus == 1 && <section style={{ display: 'flex', flexDirection: 'column' }}>
                                                {/* r1 */}
                                                <RunnerRow
                                                    blockStatus={
                                                        goalsSoccerSocket15Data.status === 1 || goalsSoccerSocket15Data.status === 0 || goalsSoccerSocket15Data.status === 2 &&
                                                        <dd className="suspend" style={goalsSoccerSocket15Data.status != 1 ? { display: 'flex', zIndex: 22 } : { display: 'none' }}><p>Suspend
                                                        </p></dd>
                                                    }
                                                    firstPl={goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('1.5')]?.teamA && (goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('1.5')]?.teamA != 0 || goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('1.5')]?.teamB != 0 || goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('1.5')]?.tie != 0) && <li id="withoutBet" className={`${goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('1.5')]?.teamA > 0 ? "win" : "lose"}`} >{goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('1.5')]?.teamA > 0 ? goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('1.5')]?.teamA : '(' + Math.abs(goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('1.5')]?.teamA) + ')'}</li>}
                                                    secondPl={(goalsOnKeyBordOn?.show) && defaultStake != ' ' && defaultStake != '' && <li id="after" className={`${goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('1.5')]?.teamA > 0 ? "to-win" : "to-lose"}`} >{goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('1.5')]?.teamA > 0 ? goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('1.5')]?.teamA : '(' + Math.abs(goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('1.5')]?.teamA) + ')'}</li>}
                                                    runnerName={goalsSoccerApiMarketData[marketKeys.overUnderGoals('1.5')]?.runnerName1}
                                                    backSelected={goalsOnKeyBordOn?.isBack && goalsOnKeyBordOn?.runner == 1}
                                                    laySelected={!goalsOnKeyBordOn?.isBack && goalsOnKeyBordOn?.runner == 1}
                                                    runnerBackRate={goalsSoccerSocket15Data.runner1BackRate1}
                                                    runnerBackSize={goalsSoccerSocket15Data.runner1BackSize1}
                                                    runnerLayRate={goalsSoccerSocket15Data.runner1LayRate1}
                                                    runnerLaySize={goalsSoccerSocket15Data.runner1LaySize1}
                                                    onClick={(isLayOrBack, rate) => onClickGoalMarketLayBack(isLayOrBack == 'back', 1, rate, marketKeys.overUnderGoals('1.5'))}
                                                    style={{
                                                        order: 1,
                                                    }}
                                                />

                                                {((goalsOnKeyBordOn?.show && goalsOnKeyBordOn?.currMarket == marketKeys.overUnderGoals('1.5')) && (goalsSoccerSocket15Data.gameStatus != 2 || goalsSoccerSocket15Data.runner1Status == 2 || goalsSoccerSocket15Data.runner2Status == 2)) &&
                                                    <PlaceBetKeybord
                                                        layBack={goalsOnKeyBordOn?.isBack ? "back" : "lay"}
                                                        odds={goalsOnKeyBordOn?.odd}
                                                        onClickOddMinus={() => (goalsOnKeyBordOn?.odd != '' || goalsOnKeyBordOn?.odd != ' ') ? setGoalsOnKeyBordOn({ ...goalsOnKeyBordOn, odd: parseFloat((parseFloat(goalsOnKeyBordOn?.odd) - 0.01).toFixed(2)).toString() }) : null}
                                                        onClickOddPlus={() => (goalsOnKeyBordOn?.odd != '' || goalsOnKeyBordOn?.odd != ' ') ? setGoalsOnKeyBordOn({ ...goalsOnKeyBordOn, odd: parseFloat((parseFloat(goalsOnKeyBordOn?.odd) + 0.01).toFixed(2)).toString() }) : null}
                                                        onClickStackMinus={() => (defaultStake === '' || defaultStake === ' ') ? setdefaultStake((parseInt(goalsSoccerApiMarketData[marketKeys.overUnderGoals('1.5')].minStack) * coinFactor).toString()) : (parseInt(defaultStake) > 0) ? setdefaultStake((parseInt(defaultStake) - 1).toString()) : null}
                                                        onClickStackPlus={() => (defaultStake === '' || defaultStake === ' ') ? setdefaultStake((parseInt(goalsSoccerApiMarketData[marketKeys.overUnderGoals('1.5')].minStack) * coinFactor).toString()) : setdefaultStake((parseInt(defaultStake) + 1).toString())}
                                                        defaultStake={defaultStake}
                                                        onInputChnageStack={e => setdefaultStake(e)}
                                                        onCancel={() => { onCloseGoalMarketLayBack(); setdefaultStake(dStake); }}
                                                        onPlace={(e) => { e.preventDefault(); setShowConfirmGoalsBet(true); tmpOnCloseGoalMarketLayBack(); }}
                                                        ssStack={defaultStakesFromApi}
                                                        minStack={goalsSoccerApiMarketData[marketKeys.overUnderGoals('1.5')].minStack}
                                                        coinFactor={coinFactor}
                                                        style={{
                                                            order: (goalsOnKeyBordOn?.runner + 1)
                                                        }}
                                                    />
                                                }
                                                {/* r2 */}
                                                <RunnerRow
                                                    blockStatus={goalsSoccerSocket15Data.status === 1 || goalsSoccerSocket15Data.status === 0 || goalsSoccerSocket15Data.status === 2 &&
                                                        <dd className="suspend" style={goalsSoccerSocket15Data.status != 1 ? { display: 'flex', zIndex: 22 } : { display: 'none' }}><p>Suspend
                                                        </p></dd>}
                                                    firstPl={goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('1.5')]?.teamA && (goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('1.5')]?.teamA != 0 || goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('1.5')]?.teamB != 0 || goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('1.5')]?.tie != 0) && <li id="withoutBet" className={`${goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('1.5')]?.teamB > 0 ? "win" : "lose"}`} >{goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('1.5')]?.teamB > 0 ? goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('1.5')]?.teamB : '(' + Math.abs(goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('1.5')]?.teamB) + ')'}</li>}
                                                    secondPl={(goalsOnKeyBordOn?.show) && defaultStake != ' ' && defaultStake != '' && <li id="after" className={`${goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('1.5')]?.teamB > 0 ? "to-win" : "to-lose"}`} >{goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('1.5')]?.teamB > 0 ? goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('1.5')]?.teamB : '(' + Math.abs(goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('1.5')]?.teamB) + ')'}</li>}
                                                    runnerName={goalsSoccerApiMarketData[marketKeys.overUnderGoals('1.5')]?.runnerName2}
                                                    backSelected={goalsOnKeyBordOn?.isBack && goalsOnKeyBordOn?.runner == 2}
                                                    laySelected={!goalsOnKeyBordOn?.isBack && goalsOnKeyBordOn?.runner == 2}
                                                    runnerBackRate={goalsSoccerSocket15Data.runner2BackRate1}
                                                    runnerBackSize={goalsSoccerSocket15Data.runner2BackSize1}
                                                    runnerLayRate={goalsSoccerSocket15Data.runner2LayRate1}
                                                    runnerLaySize={goalsSoccerSocket15Data.runner2LaySize1}
                                                    onClick={(isLayOrBack, rate) => onClickGoalMarketLayBack(isLayOrBack == 'back', 2, rate, marketKeys.overUnderGoals('1.5'))}
                                                    style={{
                                                        order: 2,
                                                    }}
                                                />

                                            </section>}
                                        </div>
                                    )}
                                    {goalsSoccerApiMarketData[marketKeys.overUnderGoals('2.5')]?.eventName && highlightTab == marketKeys.overUnderGoals("2.5") && (
                                        <div style={(goalsSoccerSocket25Data?.status != 1) ? { pointerEvents: 'none' } : null} className={`bets-wrap ${(goalsSoccerSocket25Data?.status != 1) ? "disabled" : ""}`}>
                                            {goalsSoccerApiMarketData[marketKeys.overUnderGoals('2.5')].matchStatus == 1 && (
                                                <RunnerHeader
                                                    totalStake={goalsSoccerSocket25Data?.totalMatched}
                                                    onHideMaxStack={handleMaxStakeclose}
                                                    maxStake={Maxstake && goalsSoccerApiMarketData[marketKeys.overUnderGoals('2.5')]?.maxStack}
                                                    coinFactor={coinFactor}
                                                />
                                            )}
                                            {goalsSoccerApiMarketData[marketKeys.overUnderGoals('2.5')].matchStatus == 1 && <section style={{ display: 'flex', flexDirection: 'column' }}>
                                                {/* r1 */}
                                                <RunnerRow
                                                    blockStatus={
                                                        goalsSoccerSocket25Data.status === 1 || goalsSoccerSocket25Data.status === 0 || goalsSoccerSocket25Data.status === 2 &&
                                                        <dd className="suspend" style={goalsSoccerSocket25Data.status != 1 ? { display: 'flex', zIndex: 22 } : { display: 'none' }}><p>Suspend
                                                        </p></dd>
                                                    }
                                                    firstPl={goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('2.5')]?.teamA && (goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('2.5')]?.teamA != 0 || goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('2.5')]?.teamB != 0 || goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('2.5')]?.tie != 0) && <li id="withoutBet" className={`${goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('2.5')]?.teamA > 0 ? "win" : "lose"}`} >{goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('2.5')]?.teamA > 0 ? goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('2.5')]?.teamA : '(' + Math.abs(goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('2.5')]?.teamA) + ')'}</li>}
                                                    secondPl={(goalsOnKeyBordOn?.show) && defaultStake != ' ' && defaultStake != '' && <li id="after" className={`${goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('2.5')]?.teamA > 0 ? "to-win" : "to-lose"}`} >{goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('2.5')]?.teamA > 0 ? goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('2.5')]?.teamA : '(' + Math.abs(goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('2.5')]?.teamA) + ')'}</li>}
                                                    runnerName={goalsSoccerApiMarketData[marketKeys.overUnderGoals('2.5')]?.runnerName1}
                                                    backSelected={goalsOnKeyBordOn?.isBack && goalsOnKeyBordOn?.runner == 1}
                                                    laySelected={!goalsOnKeyBordOn?.isBack && goalsOnKeyBordOn?.runner == 1}
                                                    runnerBackRate={goalsSoccerSocket25Data.runner1BackRate1}
                                                    runnerBackSize={goalsSoccerSocket25Data.runner1BackSize1}
                                                    runnerLayRate={goalsSoccerSocket25Data.runner1LayRate1}
                                                    runnerLaySize={goalsSoccerSocket25Data.runner1LaySize1}
                                                    onClick={(isLayOrBack, rate) => onClickGoalMarketLayBack(isLayOrBack == 'back', 1, rate, marketKeys.overUnderGoals('2.5'))}
                                                    style={{
                                                        order: 1,
                                                    }}
                                                />

                                                {((goalsOnKeyBordOn?.show && goalsOnKeyBordOn?.currMarket == marketKeys.overUnderGoals('2.5')) && (goalsSoccerSocket25Data.gameStatus != 2 || goalsSoccerSocket25Data.runner1Status == 2 || goalsSoccerSocket25Data.runner2Status == 2)) &&
                                                    <PlaceBetKeybord
                                                        layBack={goalsOnKeyBordOn?.isBack ? "back" : "lay"}
                                                        odds={goalsOnKeyBordOn?.odd}
                                                        onClickOddMinus={() => (goalsOnKeyBordOn?.odd != '' || goalsOnKeyBordOn?.odd != ' ') ? setGoalsOnKeyBordOn({ ...goalsOnKeyBordOn, odd: parseFloat((parseFloat(goalsOnKeyBordOn?.odd) - 0.01).toFixed(2)).toString() }) : null}
                                                        onClickOddPlus={() => (goalsOnKeyBordOn?.odd != '' || goalsOnKeyBordOn?.odd != ' ') ? setGoalsOnKeyBordOn({ ...goalsOnKeyBordOn, odd: parseFloat((parseFloat(goalsOnKeyBordOn?.odd) + 0.01).toFixed(2)).toString() }) : null}
                                                        onClickStackMinus={() => (defaultStake === '' || defaultStake === ' ') ? setdefaultStake((parseInt(goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('2.5')]?.minStack) * coinFactor).toString()) : (parseInt(defaultStake) > 0) ? setdefaultStake((parseInt(defaultStake) - 1).toString()) : null}
                                                        onClickStackPlus={() => (defaultStake === '' || defaultStake === ' ') ? setdefaultStake((parseInt(goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('2.5')]?.minStack) * coinFactor).toString()) : setdefaultStake((parseInt(defaultStake) + 1).toString())}
                                                        defaultStake={defaultStake}
                                                        onInputChnageStack={e => setdefaultStake(e)}
                                                        onCancel={() => { onCloseGoalMarketLayBack(); setdefaultStake(dStake); }}
                                                        onPlace={(e) => { e.preventDefault(); setShowConfirmGoalsBet(true); tmpOnCloseGoalMarketLayBack(); }}
                                                        ssStack={defaultStakesFromApi}
                                                        minStack={goalsSoccerApiMarketData[marketKeys.overUnderGoals('2.5')].minStack}
                                                        coinFactor={coinFactor}
                                                        style={{
                                                            order: (goalsOnKeyBordOn?.runner + 1)
                                                        }}
                                                    />
                                                }
                                                {/* r2 */}
                                                <RunnerRow
                                                    blockStatus={goalsSoccerSocket25Data.status === 1 || goalsSoccerSocket25Data.status === 0 || goalsSoccerSocket25Data.status === 2 &&
                                                        <dd className="suspend" style={goalsSoccerSocket25Data.status != 1 ? { display: 'flex', zIndex: 22 } : { display: 'none' }}><p>Suspend
                                                        </p></dd>}
                                                    firstPl={goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('2.5')]?.teamA && (goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('2.5')]?.teamA != 0 || goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('2.5')]?.teamB != 0 || goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('2.5')]?.tie != 0) && <li id="withoutBet" className={`${goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('2.5')]?.teamB > 0 ? "win" : "lose"}`} >{goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('2.5')]?.teamB > 0 ? goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('2.5')]?.teamB : '(' + Math.abs(goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('2.5')]?.teamB) + ')'}</li>}
                                                    secondPl={(goalsOnKeyBordOn?.show) && defaultStake != ' ' && defaultStake != '' && <li id="after" className={`${goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('2.5')]?.teamB > 0 ? "to-win" : "to-lose"}`} >{goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('2.5')]?.teamB > 0 ? goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('2.5')]?.teamB : '(' + Math.abs(goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('2.5')]?.teamB) + ')'}</li>}
                                                    runnerName={goalsSoccerApiMarketData[marketKeys.overUnderGoals('2.5')]?.runnerName2}
                                                    backSelected={goalsOnKeyBordOn?.isBack && goalsOnKeyBordOn?.runner == 2}
                                                    laySelected={!goalsOnKeyBordOn?.isBack && goalsOnKeyBordOn?.runner == 2}
                                                    runnerBackRate={goalsSoccerSocket25Data.runner2BackRate1}
                                                    runnerBackSize={goalsSoccerSocket25Data.runner2BackSize1}
                                                    runnerLayRate={goalsSoccerSocket25Data.runner2LayRate1}
                                                    runnerLaySize={goalsSoccerSocket25Data.runner2LaySize1}
                                                    onClick={(isLayOrBack, rate) => onClickGoalMarketLayBack(isLayOrBack == 'back', 2, rate, marketKeys.overUnderGoals('2.5'))}
                                                    style={{
                                                        order: 2,
                                                    }}
                                                />

                                            </section>}
                                        </div>
                                    )}
                                    {goalsSoccerApiMarketData[marketKeys.overUnderGoals('3.5')]?.eventName && highlightTab == marketKeys.overUnderGoals("3.5") && (
                                        <div style={(goalsSoccerSocket35Data?.status != 1) ? { pointerEvents: 'none' } : null} className={`bets-wrap ${(goalsSoccerSocket35Data?.status != 1) ? "disabled" : ""}`}>
                                            {goalsSoccerApiMarketData[marketKeys.overUnderGoals('3.5')]?.matchStatus == 1 && (
                                                <RunnerHeader
                                                    totalStake={goalsSoccerSocket35Data?.totalMatched}
                                                    onHideMaxStack={handleMaxStakeclose}
                                                    maxStake={Maxstake && goalsSoccerApiMarketData[marketKeys.overUnderGoals('3.5')]?.maxStack}
                                                    coinFactor={coinFactor}
                                                />
                                            )}
                                            {goalsSoccerApiMarketData[marketKeys.overUnderGoals('3.5')]?.matchStatus == 1 && <section style={{ display: 'flex', flexDirection: 'column' }}>
                                                {/* r1 */}
                                                <RunnerRow
                                                    blockStatus={goalsSoccerSocket35Data.status === 1 || goalsSoccerSocket35Data.status === 0 || goalsSoccerSocket35Data.status === 2 &&
                                                        <dd className="suspend" style={goalsSoccerSocket35Data.status != 1 ? { display: 'flex', zIndex: 22 } : { display: 'none' }}><p>Suspend
                                                        </p></dd>}
                                                    firstPl={goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('3.5')]?.teamA && (goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('3.5')]?.teamA != 0 || goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('3.5')]?.teamB != 0 || goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('3.5')]?.tie != 0) && <li id="withoutBet" className={`${goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('3.5')]?.teamA > 0 ? "win" : "lose"}`} >{goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('3.5')]?.teamA > 0 ? goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('3.5')]?.teamA : '(' + Math.abs(goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('3.5')]?.teamA) + ')'}</li>}
                                                    secondPl={(goalsOnKeyBordOn?.show) && defaultStake != ' ' && defaultStake != '' && <li id="after" className={`${goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('3.5')]?.teamA > 0 ? "to-win" : "to-lose"}`} >{goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('3.5')]?.teamA > 0 ? goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('3.5')]?.teamA : '(' + Math.abs(goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('3.5')]?.teamA) + ')'}</li>}
                                                    runnerName={goalsSoccerApiMarketData[marketKeys.overUnderGoals('3.5')]?.runnerName1}
                                                    backSelected={goalsOnKeyBordOn?.isBack && goalsOnKeyBordOn?.runner == 1}
                                                    laySelected={!goalsOnKeyBordOn?.isBack && goalsOnKeyBordOn?.runner == 1}
                                                    runnerBackRate={goalsSoccerSocket35Data.runner1BackRate1}
                                                    runnerBackSize={goalsSoccerSocket35Data.runner1BackSize1}
                                                    runnerLayRate={goalsSoccerSocket35Data.runner1LayRate1}
                                                    runnerLaySize={goalsSoccerSocket35Data.runner1LaySize1}
                                                    onClick={(isLayOrBack, rate) => onClickGoalMarketLayBack(isLayOrBack == 'back', 1, rate, marketKeys.overUnderGoals('3.5'))}
                                                    style={{
                                                        order: 1,
                                                    }}
                                                />

                                                {((goalsOnKeyBordOn?.show && goalsOnKeyBordOn?.currMarket == marketKeys.overUnderGoals('3.5')) && (goalsSoccerSocket35Data.gameStatus != 2 || goalsSoccerSocket35Data.runner1Status == 2 || goalsSoccerSocket35Data.runner2Status == 2)) &&
                                                    <PlaceBetKeybord
                                                        layBack={goalsOnKeyBordOn?.isBack ? "back" : "lay"}
                                                        odds={goalsOnKeyBordOn?.odd}
                                                        onClickOddMinus={() => (goalsOnKeyBordOn?.odd != '' || goalsOnKeyBordOn?.odd != ' ') ? setGoalsOnKeyBordOn({ ...goalsOnKeyBordOn, odd: parseFloat((parseFloat(goalsOnKeyBordOn?.odd) - 0.01).toFixed(2)).toString() }) : null}
                                                        onClickOddPlus={() => (goalsOnKeyBordOn?.odd != '' || goalsOnKeyBordOn?.odd != ' ') ? setGoalsOnKeyBordOn({ ...goalsOnKeyBordOn, odd: parseFloat((parseFloat(goalsOnKeyBordOn?.odd) + 0.01).toFixed(2)).toString() }) : null}
                                                        onClickStackMinus={() => (defaultStake === '' || defaultStake === ' ') ? setdefaultStake((parseInt(goalsSoccerApiMarketData[marketKeys.overUnderGoals('3.5')]?.minStack) * coinFactor).toString()) : (parseInt(defaultStake) > 0) ? setdefaultStake((parseInt(defaultStake) - 1).toString()) : null}
                                                        onClickStackPlus={() => (defaultStake === '' || defaultStake === ' ') ? setdefaultStake((parseInt(goalsSoccerApiMarketData[marketKeys.overUnderGoals('3.5')]?.minStack) * coinFactor).toString()) : setdefaultStake((parseInt(defaultStake) + 1).toString())}
                                                        defaultStake={defaultStake}
                                                        onInputChnageStack={e => setdefaultStake(e)}
                                                        onCancel={() => { onCloseGoalMarketLayBack(); setdefaultStake(dStake); }}
                                                        onPlace={(e) => { e.preventDefault(); setShowConfirmGoalsBet(true); tmpOnCloseGoalMarketLayBack(); }}
                                                        ssStack={defaultStakesFromApi}
                                                        minStack={goalsSoccerApiMarketData[marketKeys.overUnderGoals('3.5')].minStack}
                                                        coinFactor={coinFactor}
                                                        style={{
                                                            order: (goalsOnKeyBordOn?.runner + 1)
                                                        }}
                                                    />
                                                }
                                                {/* r2 */}
                                                <RunnerRow
                                                    blockStatus={goalsSoccerSocket35Data.status === 1 || goalsSoccerSocket35Data.status === 0 || goalsSoccerSocket35Data.status === 2 &&
                                                        <dd className="suspend" style={goalsSoccerSocket35Data.status != 1 ? { display: 'flex', zIndex: 22 } : { display: 'none' }}><p>Suspend
                                                        </p></dd>}
                                                    firstPl={goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('3.5')]?.teamA && (goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('3.5')]?.teamA != 0 || goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('3.5')]?.teamB != 0 || goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('3.5')]?.tie != 0) && <li id="withoutBet" className={`${goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('3.5')]?.teamB > 0 ? "win" : "lose"}`} >{goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('3.5')]?.teamB > 0 ? goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('3.5')]?.teamB : '(' + Math.abs(goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('3.5')]?.teamB) + ')'}</li>}
                                                    secondPl={(goalsOnKeyBordOn?.show) && defaultStake != ' ' && defaultStake != '' && <li id="after" className={`${goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('3.5')]?.teamB > 0 ? "to-win" : "to-lose"}`} >{goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('3.5')]?.teamB > 0 ? goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('3.5')]?.teamB : '(' + Math.abs(goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('3.5')]?.teamB) + ')'}</li>}
                                                    runnerName={goalsSoccerApiMarketData[marketKeys.overUnderGoals('3.5')]?.runnerName2}
                                                    backSelected={goalsOnKeyBordOn?.isBack && goalsOnKeyBordOn?.runner == 2}
                                                    laySelected={!goalsOnKeyBordOn?.isBack && goalsOnKeyBordOn?.runner == 2}
                                                    runnerBackRate={goalsSoccerSocket35Data.runner2BackRate1}
                                                    runnerBackSize={goalsSoccerSocket35Data.runner2BackSize1}
                                                    runnerLayRate={goalsSoccerSocket35Data.runner2LayRate1}
                                                    runnerLaySize={goalsSoccerSocket35Data.runner2LaySize1}
                                                    onClick={(isLayOrBack, rate) => onClickGoalMarketLayBack(isLayOrBack == 'back', 2, rate, marketKeys.overUnderGoals('3.5'))}
                                                    style={{
                                                        order: 2,
                                                    }}
                                                />

                                            </section>}
                                        </div>
                                    )}
                                    {goalsSoccerApiMarketData[marketKeys.overUnderGoals('4.5')]?.eventName && highlightTab == marketKeys.overUnderGoals("4.5") && (
                                        <div style={(goalsSoccerSocket45Data?.status != 1) ? { pointerEvents: 'none' } : null} className={`bets-wrap ${(goalsSoccerSocket45Data?.status != 1) ? "disabled" : ""}`}>
                                            {goalsSoccerApiMarketData[marketKeys.overUnderGoals('4.5')].matchStatus == 1 && (
                                                <RunnerHeader
                                                    totalStake={goalsSoccerSocket45Data?.totalMatched}
                                                    onHideMaxStack={handleMaxStakeclose}
                                                    maxStake={Maxstake && goalsSoccerApiMarketData[marketKeys.overUnderGoals('4.5')]?.maxStack}
                                                    coinFactor={coinFactor}
                                                />
                                            )}
                                            {goalsSoccerApiMarketData[marketKeys.overUnderGoals('4.5')].matchStatus == 1 && <section style={{ display: 'flex', flexDirection: 'column' }}>
                                                {/* r1 */}
                                                <RunnerRow
                                                    blockStatus={
                                                        goalsSoccerSocket45Data.status === 1 || goalsSoccerSocket45Data.status === 0 || goalsSoccerSocket45Data.status === 2 &&
                                                        <dd className="suspend" style={goalsSoccerSocket45Data.status != 1 ? { display: 'flex', zIndex: 22 } : { display: 'none' }}><p>Suspend
                                                        </p></dd>
                                                    }
                                                    firstPl={goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('4.5')]?.teamA && (goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('4.5')]?.teamA != 0 || goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('4.5')]?.teamB != 0 || goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('4.5')]?.tie != 0) && <li id="withoutBet" className={`${goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('4.5')]?.teamA > 0 ? "win" : "lose"}`} >{goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('4.5')]?.teamA > 0 ? goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('4.5')]?.teamA : '(' + Math.abs(goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('4.5')]?.teamA) + ')'}</li>}
                                                    secondPl={(goalsOnKeyBordOn?.show) && defaultStake != ' ' && defaultStake != '' && <li id="after" className={`${goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('4.5')]?.teamA > 0 ? "to-win" : "to-lose"}`} >{goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('4.5')]?.teamA > 0 ? goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('4.5')]?.teamA : '(' + Math.abs(goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('4.5')]?.teamA) + ')'}</li>}
                                                    runnerName={goalsSoccerApiMarketData[marketKeys.overUnderGoals('4.5')]?.runnerName1}
                                                    backSelected={goalsOnKeyBordOn?.isBack && goalsOnKeyBordOn?.runner == 1}
                                                    laySelected={!goalsOnKeyBordOn?.isBack && goalsOnKeyBordOn?.runner == 1}
                                                    runnerBackRate={goalsSoccerSocket45Data.runner1BackRate1}
                                                    runnerBackSize={goalsSoccerSocket45Data.runner1BackSize1}
                                                    runnerLayRate={goalsSoccerSocket45Data.runner1LayRate1}
                                                    runnerLaySize={goalsSoccerSocket45Data.runner1LaySize1}
                                                    onClick={(isLayOrBack, rate) => onClickGoalMarketLayBack(isLayOrBack == 'back', 1, rate, marketKeys.overUnderGoals('4.5'))}
                                                    style={{
                                                        order: 1,
                                                    }}
                                                />

                                                {((goalsOnKeyBordOn?.show && goalsOnKeyBordOn?.currMarket == marketKeys.overUnderGoals('4.5')) && (goalsSoccerSocket45Data.gameStatus != 2 || goalsSoccerSocket45Data.runner1Status == 2 || goalsSoccerSocket45Data.runner2Status == 2)) &&
                                                    <PlaceBetKeybord
                                                        layBack={goalsOnKeyBordOn?.isBack ? "back" : "lay"}
                                                        odds={goalsOnKeyBordOn?.odd}
                                                        onClickOddMinus={() => (goalsOnKeyBordOn?.odd != '' || goalsOnKeyBordOn?.odd != ' ') ? setGoalsOnKeyBordOn({ ...goalsOnKeyBordOn, odd: parseFloat((parseFloat(goalsOnKeyBordOn?.odd) - 0.01).toFixed(2)).toString() }) : null}
                                                        onClickOddPlus={() => (goalsOnKeyBordOn?.odd != '' || goalsOnKeyBordOn?.odd != ' ') ? setGoalsOnKeyBordOn({ ...goalsOnKeyBordOn, odd: parseFloat((parseFloat(goalsOnKeyBordOn?.odd) + 0.01).toFixed(2)).toString() }) : null}
                                                        onClickStackMinus={() => (defaultStake === '' || defaultStake === ' ') ? setdefaultStake((parseInt(goalsSoccerApiMarketData[marketKeys.overUnderGoals('4.5')]?.minStack) * coinFactor).toString()) : (parseInt(defaultStake) > 0) ? setdefaultStake((parseInt(defaultStake) - 1).toString()) : null}
                                                        onClickStackPlus={() => (defaultStake === '' || defaultStake === ' ') ? setdefaultStake((parseInt(goalsSoccerApiMarketData[marketKeys.overUnderGoals('4.5')]?.minStack) * coinFactor).toString()) : setdefaultStake((parseInt(defaultStake) + 1).toString())}
                                                        defaultStake={defaultStake}
                                                        onInputChnageStack={e => setdefaultStake(e)}
                                                        onCancel={() => { onCloseGoalMarketLayBack(); setdefaultStake(dStake); }}
                                                        onPlace={(e) => { e.preventDefault(); setShowConfirmGoalsBet(true); tmpOnCloseGoalMarketLayBack(); }}
                                                        ssStack={defaultStakesFromApi}
                                                        minStack={goalsSoccerApiMarketData[marketKeys.overUnderGoals('4.5')].minStack}
                                                        coinFactor={coinFactor}
                                                        style={{
                                                            order: (goalsOnKeyBordOn?.runner + 1)
                                                        }}
                                                    />
                                                }
                                                {/* r2 */}
                                                <RunnerRow
                                                    blockStatus={goalsSoccerSocket45Data.status === 1 || goalsSoccerSocket45Data.status === 0 || goalsSoccerSocket45Data.status === 2 &&
                                                        <dd className="suspend" style={goalsSoccerSocket45Data.status != 1 ? { display: 'flex', zIndex: 22 } : { display: 'none' }}><p>Suspend
                                                        </p></dd>}
                                                    firstPl={goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('4.5')]?.teamA && (goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('4.5')]?.teamA != 0 || goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('4.5')]?.teamB != 0 || goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('4.5')]?.tie != 0) && <li id="withoutBet" className={`${goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('4.5')]?.teamB > 0 ? "win" : "lose"}`} >{goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('4.5')]?.teamB > 0 ? goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('4.5')]?.teamB : '(' + Math.abs(goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('4.5')]?.teamB) + ')'}</li>}
                                                    secondPl={(goalsOnKeyBordOn?.show) && defaultStake != ' ' && defaultStake != '' && <li id="after" className={`${goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('4.5')]?.teamB > 0 ? "to-win" : "to-lose"}`} >{goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('4.5')]?.teamB > 0 ? goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('4.5')]?.teamB : '(' + Math.abs(goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('4.5')]?.teamB) + ')'}</li>}
                                                    runnerName={goalsSoccerApiMarketData[marketKeys.overUnderGoals('4.5')]?.runnerName2}
                                                    backSelected={goalsOnKeyBordOn?.isBack && goalsOnKeyBordOn?.runner == 2}
                                                    laySelected={!goalsOnKeyBordOn?.isBack && goalsOnKeyBordOn?.runner == 2}
                                                    runnerBackRate={goalsSoccerSocket45Data.runner2BackRate1}
                                                    runnerBackSize={goalsSoccerSocket45Data.runner2BackSize1}
                                                    runnerLayRate={goalsSoccerSocket45Data.runner2LayRate1}
                                                    runnerLaySize={goalsSoccerSocket45Data.runner2LaySize1}
                                                    onClick={(isLayOrBack, rate) => onClickGoalMarketLayBack(isLayOrBack == 'back', 2, rate, marketKeys.overUnderGoals('4.5'))}
                                                    style={{
                                                        order: 2,
                                                    }}
                                                />

                                            </section>}
                                        </div>
                                    )}
                                </>
                            )}


                            {/* fancyyyy */}
                            {(eventT == 4 || eventT == 2378962) &&
                                <React.Fragment>
                                    {onclickPremium === false &&
                                        <div id="fancyBetTable_30062362" name="multiMarketItem">

                                            {showFancyBook && <FancyBook runner={fancyRunner} userid={props.userid} token={props.utoken} handleFancyBook={handleFancyBook} eventId={eventid} selection={fancySelection} />}

                                            <div id="fancyBetHead" className="fancy_bet-head" style={{}}>
                                                {/*a id="multiMarketPin" className="pin-off" href="#">Pin to Muilti Markets</a*/}
                                                <h4 className="in-play">
                                                    <a id="multiMarketPin" onClick={() => { handlefancypin(); }} className={`pin${Fancypin ? "-on" : "-off"}`} >Pin to Muilti Markets</a>
                                                    <span><pre>in-play</pre>{eventT == 4 ? "Fancy Bet" : " Election Fancy Bet"}</span>
                                                    <a className="btn-head_rules" >Rules</a>
                                                </h4>
                                                {(eventT == 4 && props.isLoggedIn === true) &&
                                                    <a id="showSportsBookBtn" className="other-tab" onClick={() => { props.handleRefreshhh(2000); handlepremium(true) }} style={{}}>
                                                        <span className="tag-new">New</span>
                                                        Premium Cricket
                                                    </a>}
                                            </div>


                                            {/*<div id="fancyBetTabWrap" className="fancy_bet_tab-wrap ps ps--theme_default ps--active-x" style={{ display: 'none' }} data-ps-id="cace61a7-33b8-1ce3-037a-56d58b59ed92">*/}
                                            {/*    <ul className="special_bets-tab" style={{ overflowY: 'scroll' }}>*/}
                                            {/*        <li className={`${(fancybettabtype == 1) ? "select" : "null"}`}><a onClick={() => { setfancybettabtype(1) }}>All</a></li>*/}
                                            {/*        <li className={`${(fancybettabtype == 2) ? "select" : "null"}`}><a onClick={() => { setfancybettabtype(2) }}>Fancy</a></li>*/}
                                            {/*        <li className={`${(fancybettabtype == 3) ? "select" : "null"}`}><a onClick={() => { setfancybettabtype(3) }}>Ball by Ball</a></li>*/}
                                            {/*        <li className={`${(fancybettabtype == 4) ? "select" : "null"}`}><a onClick={() => { setfancybettabtype(4) }}>Khadda</a></li>*/}
                                            {/*        <li className={`${(fancybettabtype == 5) ? "select" : "null"}`}><a onClick={() => { setfancybettabtype(5) }}>Lottery</a></li>*/}
                                            {/*        <li className={`${(fancybettabtype == 6) ? "select" : "null"}`}><a onClick={() => { setfancybettabtype(6) }}>Odd/Even</a></li>*/}
                                            {/*    </ul>*/}
                                            {/*    <div className="ps__scrollbar-x-rail" style={{ width: 430, left: 7, bottom: 0 }}><div className="ps__scrollbar-x" tabIndex={0} style={{ left: 7, width: 422 }} /> </div>*/}
                                            {/*    <div className="ps__scrollbar-y-rail" style={{ top: 0, right: '-7px' }}><div className="ps__scrollbar-y" tabIndex={0} style={{ top: 0, height: 0 }} /> </div>*/}
                                            {/*</div>*/}

                                            {moment() <= moment(marketStartTime) && fancyData.length == 0 &&
                                                <div id="noGameWrap" className="bets-wrap" style={{ display: ' block' }}>
                                                    <p className="no-game">Fancybet market will be available after Event at In-Play</p>
                                                </div>
                                            }

                                            {fancyData.length > 0 &&
                                                <div id="fancyBetField" className="bets-wrap fancy-bet" >
                                                    <dl className="bets-selections-head">
                                                        <dt  >
                                                        </dt>
                                                        <dd className="mode-land"></dd>
                                                        <dd className="mode-land"></dd>
                                                        <dd>No</dd>
                                                        <dd>Yes</dd>
                                                        <dd className="mode-land"></dd>
                                                        <dd className="mode-land"></dd>
                                                    </dl>
                                                </div>}

                                            <div id="fancyBetTag">
                                                {fancyData.map(function (item, index) {

                                                    var pnl = null;
                                                    var pnl2 = null;


                                                    if (pnlFancy[item.selectionId]) {
                                                        pnl = parseFloat(pnlFancy[item.selectionId]).toFixed(2);
                                                    }
                                                    if (click === index) {
                                                        if (field === 1 && defaultStake != ' ' && defaultStake != '') {
                                                            pnl2 = -(parseFloat(fsize / 100) * parseFloat(defaultStake)).toFixed(2);
                                                        }
                                                        else if (field === 2 && defaultStake != ' ' && defaultStake != '') {
                                                            pnl2 = -Math.abs(defaultStake);
                                                        }
                                                    }


                                                    return (
                                                        <React.Fragment key={index}>

                                                            <div id="fancyBetMarket_176347" className="bets-wrap fancy-bet" >
                                                                <h5>
                                                                    <span id="marketName">{item.runnerName}</span>
                                                                    <a onClick={() => { setfancyStakeInfo(index); }} id="open-fancy_info" className="btn-fancy_info">fancybet info</a>
                                                                    {fancyStakeInfo === index && <div id="fancy_popup2" className="fancy_info-popup" style={{ display: 'flex' }}>
                                                                        <dl>
                                                                            <dt>Min / Max</dt>
                                                                            <dd id="minMax"> {parseInt(item.minStack) * coinFactor} / {parseInt(item.maxStack) * coinFactor}</dd>
                                                                        </dl>
                                                                        <a onClick={() => { setfancyStakeInfo(-1); }} id="close-fancy_info" className="close">Close</a>
                                                                    </div>}
                                                                </h5>
                                                                <RunnerRow
                                                                    blockStatus={
                                                                        (item.gameStatus != 2) && <dd id="suspend" className="suspend-fancy"><p id="info">{item.gameStatus == 10 ? 'Ball Running' : 'Suspend'}</p></dd>
                                                                    }
                                                                    firstPl={pnl && <span id="before" className={`${pnl > 0 ? "win" : "lose"}`}>{pnl > 0 ? pnl : '(' + Math.abs(pnl) + ')'}</span>}
                                                                    backSelected={selectFancyLay === 1 && click === index && field === 2}
                                                                    laySelected={selectFancy === 1 && click === index && field === 1}
                                                                    runnerBackRate={item.backPrice}
                                                                    runnerBackSize={item.backSize}
                                                                    runnerLayRate={item.layPrice}
                                                                    runnerLaySize={item.laySize}
                                                                    onClick={(isLayOrBack, rate) => {
                                                                        if (!props.isLoggedIn) {
                                                                            props.checkShowLogin(true);
                                                                            return;
                                                                        } else {
                                                                            if (isLayOrBack === 'back') {
                                                                                OpenfancyBetLay(index)
                                                                                setfOdds(item.backPrice)
                                                                                setfsize(item.backSize);
                                                                            } else {
                                                                                OpenfancyBet(index);
                                                                                setfOdds(item.layPrice)
                                                                                setfsize(item.laySize);
                                                                            }
                                                                        }
                                                                    }}
                                                                    veriant={marketKeys.fancy}
                                                                    bookBtn={pnl && <a id="fancyBetBookBtn" className="btn-book" onClick={() => {
                                                                        setfancySelection(item.selectionId);
                                                                        setfancyRunner(item.runnerName); handleFancyBook(true);
                                                                    }}>Book</a>}
                                                                />

                                                            </div>

                                                            {click === index && (
                                                                <PlaceBetKeybord
                                                                    layBack={field === 1 ? "lay" : "back"}
                                                                    odds={`${fOdds}/${fsize}`}
                                                                    oddInputDisable={true}
                                                                    onClickStackMinus={() => (defaultStake === '' || defaultStake === ' ') ? setdefaultStake((parseInt(marketData.minStack) * coinFactor).toString()) : (parseInt(defaultStake) > 0) ? setdefaultStake((parseInt(defaultStake) - 1).toString()) : null}
                                                                    onClickStackPlus={() => (defaultStake === '' || defaultStake === ' ') ? setdefaultStake((parseInt(marketData.minStack) * coinFactor).toString()) : setdefaultStake((parseInt(defaultStake) + 1).toString())}
                                                                    defaultStake={defaultStake}
                                                                    onInputChnageStack={e => setdefaultStake(e)}
                                                                    onCancel={() => { closeFancyBet(); setdefaultStake(dStake) }}
                                                                    onPlace={() => {
                                                                        placeBet(marketKeys.fancy, { selectionId: item.selectionId, fld: field, runnerName: item.runnerName })
                                                                        closeFancyBet(); setdefaultStake(dStake);
                                                                    }}
                                                                    ssStack={defaultStakesFromApi}
                                                                    minStack={item.minStack}
                                                                    coinFactor={coinFactor}
                                                                    renderBottom={
                                                                        <div id="acceptAnyOddsBox"
                                                                            className="bet-setting"
                                                                            style={{ display: ' flex' }}>
                                                                            <a id="acceptAnyOdds"
                                                                                onClick={() => setacceptAnyOdds(!acceptAnyOdds)}
                                                                                className={`bet-check ${acceptAnyOdds === true ? "checked" : ""}`}>Accept
                                                                                Any Odds</a>
                                                                        </div>
                                                                    }
                                                                />
                                                            )}


                                                        </React.Fragment>)
                                                })}
                                            </div>
                                        </div>

                                    }

                                </React.Fragment>
                            }
                            {/* All Premium */}
                            {(onclickPremium === true || eventT == 2 || eventT == 1) &&
                                <div id="sportsBookWrap" style={{ display: 'block' }}>
                                    <PremiumHeader onClickFancy={() => handlepremium(false)} sportsId={eventT} />
                                    {(!premiumRate || premiumRate.length === 0) &&
                                        <div id="feedingSiteNoBet" className="bets-wrap">
                                            <p className="no-game">Sports Book has No market</p>
                                        </div>}

                                    {(props.isLoggedIn === true && premiumRate && premiumRate?.length !== 0) && premiumRate.map(function (item, index) {
                                        // set prm game status
                                        let gameStatus = item.marketStatus;
                                        if (item.apiSiteStatus === 'ACTIVE') {
                                            gameStatus = '2'
                                        } else {
                                            gameStatus = '0'
                                        }
                                        if (item.marketStatus === 2) {
                                            gameStatus = 10;
                                        }
                                        return (
                                            <React.Fragment key={index}>

                                                <div id="sportsBookMarket_2_1886757_97016100" className="bets-wrap sports-book" style={{}}>
                                                    <h4>
                                                        <a id="multiMarketPin" className="pin-off" title="Add to Multi Markets" />
                                                        <span id="marketName" className="to-collapse">{item.marketName}</span>
                                                    </h4>

                                                    {<React.Fragment>

                                                        {item?.sportsBookSelection?.length !== 0 && item?.sportsBookSelection?.map((item2, index2) => {
                                                            const isKeybordActive = Premclick === index2 && Premclick2 === index;
                                                            let mainPl = null;
                                                            if (premPlData.length !== 0) {
                                                                const filterPlData = premPlData?.filter(i => (item2?.betfairEventId == i?.eventId && item2?.marketId == i?.marketId));
                                                                if (filterPlData.length !== 0) {
                                                                    const dataIndex = filterPlData.length - 1;
                                                                    const lastData = filterPlData[dataIndex];
                                                                    const getExpo = exposurePremiumLogic({
                                                                        updatedBetRecordsData: lastData?.dataset,
                                                                        teamName: item2?.id,
                                                                        clientId: props.userid
                                                                    });
                                                                    const findExpoById = getExpo.find(i => i.id == item2.id);

                                                                    if (findExpoById) {
                                                                        mainPl = findExpoById.profitLoss
                                                                    }
                                                                }
                                                            }

                                                            const uniqId = item.betfairEventId + item.id;
                                                            let secondPl = null;

                                                            if (premPnlListArr.length !== 0) {
                                                                const getCurrMarketPl = localPnlStateListById(uniqId);
                                                                // console.log('getCurrMarketPl', getCurrMarketPl)
                                                                if (getCurrMarketPl.length !== 0) {
                                                                    secondPl = isKeybordActive ? getCurrMarketPl[0]?.runnerPl?.currInputVal : getCurrMarketPl[0]?.runnerPl?.notCurrInputVal;
                                                                }
                                                            }
                                                            // console.log('secondPl', secondPl)
                                                            // const winAmmout = mainPl > 0 ? mainPl : 0;

                                                            return (
                                                                <React.Fragment key={index2}>
                                                                    <PremiumRunnerRow
                                                                        firstPl={mainPl && <li id="withoutBet" className={`${mainPl > 0 ? "win" : "lose"}`} >{mainPl > 0 ? mainPl?.toFixed(2) : '(' + Math.abs(mainPl)?.toFixed(2) + ')'}</li>}
                                                                        secondPl={secondPl && <li id="after" className={`${secondPl > 0 ? "to-win" : "to-lose"}`} >{secondPl > 0 ? secondPl?.toFixed(2) : '(' + Math.abs(secondPl)?.toFixed(2) + ')'}</li>}
                                                                        selectionName={item2.selectionName}
                                                                        gameStatus={gameStatus}
                                                                        odds={item2.odds}
                                                                        isSelect={(selectPremLay === 1 && Premclick === index2 && Premclick2 === index && premiumfield === 1)}
                                                                        onClickBack={() => { if (!props.isLoggedIn) { props.checkShowLogin(true); return; } OpenPremiumBetLay(index2, index); setPreOdds(item2.odds); }}
                                                                    />
                                                                    {isKeybordActive &&
                                                                        <PlaceBetKeybord
                                                                            // layBack={(selectManualBack === 1 || selectManualBack === 3 || selectManualBack === 5) ? "back" : "lay"}
                                                                            odds={PreOdds}
                                                                            onClickOddMinus={() => (PreOdds != '' || PreOdds != ' ') ? setPreOdds(parseFloat((parseFloat(PreOdds) - 0.01).toFixed(2)).toString()) : null}
                                                                            onClickOddPlus={() => (PreOdds != '' || PreOdds != ' ') ? setPreOdds(parseFloat((parseFloat(PreOdds) + 0.01).toFixed(2)).toString()) : null}
                                                                            onClickStackMinus={() => (defaultStake === '' || defaultStake === ' ') ? setdefaultStake((parseInt(ManualData.minStack) * coinFactor).toString()) : (parseInt(defaultStake) > 0) ? setdefaultStake((parseInt(defaultStake) - 1).toString()) : null}
                                                                            onClickStackPlus={() => (defaultStake === '' || defaultStake === ' ') ? setdefaultStake((parseInt(ManualData.minStack) * coinFactor).toString()) : setdefaultStake((parseInt(defaultStake) + 1).toString())}
                                                                            defaultStake={defaultStake}
                                                                            onInputChnageStack={e => setdefaultStake(e)}

                                                                            onChnageStakeValue={stackVal => {
                                                                                pushAndUpdateReUseArrListObjValue({
                                                                                    arr: premPnlListArr,
                                                                                    push: pushPremList,
                                                                                    updateAt: updatePremList,
                                                                                    id: uniqId,
                                                                                    key: 'runnerPl',
                                                                                    value: {
                                                                                        currInputVal: mainPl > 0 ? mainPl + parseFloat(stackVal) * parseFloat(item2.odds - 1) : parseFloat(stackVal) * parseFloat(item2.odds - 1),
                                                                                        notCurrInputVal: ((Math.abs(mainPl) + parseFloat(stackVal)) - ((Math.abs(mainPl) + parseFloat(stackVal)) * 2)),
                                                                                    }

                                                                                })

                                                                            }}
                                                                            onCancel={() => { clearPremList(); closePremiumBet(); setdefaultStake(dStake); }}
                                                                            onPlace={(e) => {
                                                                                e.preventDefault();
                                                                                placeBet(marketKeys.premium, {
                                                                                    marketId: item?.id,
                                                                                    teamName: item2?.id,
                                                                                    marketName: item?.marketName,
                                                                                    eventId: `${item?.betfairEventId}`
                                                                                });
                                                                                closePremiumBet();
                                                                                clearPremList();
                                                                            }}
                                                                            ssStack={defaultStakesFromApi}
                                                                            minStack={item?.minStack}
                                                                            coinFactor={coinFactor}
                                                                            renderBottom={(
                                                                                <div id="acceptAnyOddsBox" className="bet-setting" style={{ display: 'flex', background: '#c7dbe9' }}>
                                                                                    <a id="acceptAnyOdds" onClick={() => setacceptAnyOdds(!acceptAnyOdds)} className={`bet-check ${acceptAnyOdds === true ? "checked" : ""}`} >Accept Any Odds
                                                                                    </a>
                                                                                    <span id="sportsBookMaxStake"><a id="getSportsBookMaxStakeBtn" className="max-bet">Max</a></span>
                                                                                </div>
                                                                            )}
                                                                        />
                                                                    }


                                                                </React.Fragment>

                                                            )
                                                        })}
                                                    </React.Fragment>}
                                                </div>

                                            </React.Fragment>)
                                    })}

                                </div>
                            }


                        </React.Fragment>}
                </div>

            </React.Fragment>}

        </React.Fragment>
    )
}
