import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import Transparent from '../images/sprite/transparent.gif'
import Cookies from 'universal-cookie';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai'
import { suid } from 'rand-token';
import axios from 'axios';
import FirsttimeLogin from './Mobilefirstlogin'



const cookies = new Cookies();

window.flag = 0;

var cc;
var tt;


export default function Loginmobile(props) {
	const [Socialicon, setSocialicon] = useState(0);
	const [isLoggedIn, setLoggedIn] = useState(false);
	const [isError, setIsError] = useState(false);
	const [userid, setId] = useState("");
	const [password, setPassword] = useState("");
	const [captchaSuccess, setCaptcha] = useState(false);
	const [loading, setloading] = useState(false);
	const [firstlogin, setfirstlogin] = useState(0)
	const [viewpass, setViewpass] = useState(false)







	function createCaptcha() {
		if (document.getElementById('captch')) {
			document.getElementById("captch").remove();
		}

		var captcha = [];
		while (captcha.length < 4) {
			//below code will not allow Repetition of Characters
			var index = Math.floor(Math.random() * 10); //get the next character from the array

			captcha.push(index);

		}
		var canv = document.createElement("canvas");
		canv.style.width = 'inherit';
		canv.id = "captch";
		canv.width = 70;
		canv.height = 50;

		var ctx = canv.getContext("2d");
		ctx.font = "600 30px Arial";
		ctx.fillText(captcha.join(""), 0, 30)
		//ctx.strokeText(captcha.join(""), 0, 30);
		//storing captcha so that can validate you can save it somewhere else according to your specific requirements
		cc = captcha.join("");

		if (document.getElementById("popupcaptcha")) {
			document.getElementById("popupcaptcha").appendChild(canv);
		}
		else {
			document.getElementById("captcha").appendChild(canv);
		}




	}


	function validateCaptcha(event) {
		//event.preventDefault();

		if (event.target.value === cc) {
			setCaptcha(true);

		} else {
			setCaptcha(false);
		}

	}

	useEffect(() => {

		createCaptcha();

	}, []);



	const handleLogin = () => {


		if (userid === '') {

			createCaptcha();
			//toast.warn('Username can not be blank!', {position:toast.POSITION.TOP_CENTER})
			document.getElementById("errorMsg").innerHTML = "Username is empty";


			return;
		}
		if (password === '') {
			createCaptcha();
			//toast.warn('Password can not be blank!', {position:toast.POSITION.TOP_CENTER})
			document.getElementById("errorMsg").innerHTML = "Password is empty";
			return;
		}

		if (!captchaSuccess) {

			createCaptcha();
			// toast.warn('Captcha is not valid!', {position:toast.POSITION.TOP_CENTER})
			document.getElementById("errorMsg").innerHTML = "Captcha is not valid!";
			return;
		}


		var demoid1 = 'demo01';
		//var demoid2 = 'demo02'
		var token = suid(16);
		if (userid.toUpperCase() === demoid1.toUpperCase()) {
			token = '123456789';
		}
		tt = token;

		cookies.set('id', userid, { path: '/' });
		cookies.set('token', token, { path: '/' });
		cookies.set('password', password, { path: '/' });

		axios.post('https://liveapi247.live/api1/userlogin', {
			id: userid,
			password: password,
			token: token
		})
			.then(result => {

				if (result.status === 201) {

					setfirstlogin(1);

				}
				else if (result.status === 202) {

					setfirstlogin(2);

				}

				else if (result.status === 200) {

					setLoggedIn(true);
					//props.checkLogin(true);
					setCaptcha(false);
					window.location.href = '/home';
					//window.location.reload();


				}
				else if (result.status === 205) {
					setCaptcha(false);
					//toast.warn('User Blocked!', {position:toast.POSITION.TOP_CENTER})
					document.getElementById("errorMsg").innerHTML = "User Blocked!";
					createCaptcha();
				}
				else {
					setCaptcha(false);
					//toast.warn('Username or password incorrect!', {position:toast.POSITION.TOP_CENTER})
					document.getElementById("errorMsg").innerHTML = "Username or password incorrect!";
					createCaptcha();
				}
			}

			).catch(e => {
				setIsError(true);
			});



	};



	return (
		<React.Fragment>
			{firstlogin == 0 &&
				<React.Fragment>

					<body _ngcontent-njs-c67="" style={{
						color: '#222',
						backgroundColor: 'rgb(255, 184, 10)',
						backgroundImage: 'linear-gradient(56deg, #ffcc2e 4%, #ffb80c 42%)', minHeight: '100vh'
					}}>

						<header _ngcontent-njs-c67="" className="login-head">
							<Link to="/" className="close ui-link" style={{ padding: 0 }} onClick={() => { props.checkShowLogin(false) }}></Link>


							<h1>bdexch</h1>
						</header>
						<dl _ngcontent-njs-c67="" className="form-login" >
							<dd _ngcontent-njs-c67="" id="loginNameErrorClass" >
								<input _ngcontent-njs-c67="" type="email" value={userid} onChange={(e) => { setId(e.target.value) }} id="loginName" data-role="none"
									placeholder="Username" />
							</dd>
							<dd _ngcontent-njs-c67="" id="passwordErrorClass">
								<input _ngcontent-njs-c67="" type={viewpass ? "text" : "password"} value={password} onChange={(e) => { setPassword(e.target.value) }}
									data-role="none" placeholder="Password" />

								{viewpass ? (
									<AiFillEye onClick={() => setViewpass(!viewpass)} style={{
										color: 'rgb(0, 0, 0)',
										position: 'absolute',
										top: '2.5vw',
										zIndex: '222222222',
										left: '77vw',
										fontSize: '6vw'
									}} />
								) : (


									<AiFillEyeInvisible onClick={() => setViewpass(!viewpass)}
										style={{
											color: 'rgb(0, 0, 0)',
											position: 'absolute',
											top: '2.5vw',
											zIndex: '222222222',
											left: '77vw',
											fontSize: '6vw'
										}} />
								)}
							</dd>
							<dd _ngcontent-njs-c67="" id="validCodeErrorClass">
								<input _ngcontent-njs-c67="" onChange={(e) => { validateCaptcha(e) }} type="text" id="validCode" inputMode="numeric" maxLength="4/" placeholder="Captcha" />
								<div id="popupcaptcha" className="CaptchaPopup">

								</div>
							</dd>

							<dd>
								<a _ngcontent-njs-c67="" onClick={handleLogin} className="btn-send ui-link" id="loginBtn">Login</a>
							</dd>
							<dd _ngcontent-njs-c67="" id="errorMsg" className="state"></dd>
						</dl>
						<ul _ngcontent-njs-c67="" class="mob-inform-link login-supports" style={{ display: ' block' }}>
							<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" onClick={() => { props.handlepolicy(4, true) }} >KYC</a></li>
							<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" onClick={() => { props.handlepolicy(6, true) }}>About Us</a></li>
							<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" onClick={() => { props.handlepolicy(1, true) }}>Privacy Policy</a></li>
							<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" onClick={() => { props.handlepolicy(5, true) }}>Responsible Gaming</a></li>
							<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" onClick={() => { props.handlepolicy(2, true) }}>Terms and Conditions</a></li>
							<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" onClick={() => { props.handlepolicy(8, true) }}>Underage Gaming Policy</a></li>
							<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" onClick={() => { props.handlepolicy(7, true) }}>Self-Exclusion Policy</a></li>
							<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" onClick={() => { props.handlepolicy(3, true) }}>Rules and Regulations</a></li>

						</ul>
						<div id="supportWrap" className="support-wrap">
							<div className="support-service">
								<a id="support_email" onClick={() => { setSocialicon(0) }} className={`support-mail ui-link ${(Socialicon === 0) ? "open" : "null"}`}>
									<img src={Transparent} title="Email" /></a>
								<a id="support_whatsapp" onClick={() => { setSocialicon(1) }} className={`support-whatsapp ui-link ${(Socialicon === 1) ? "open" : "null"}`}>
									<img src={Transparent} title="WhatsApp" /></a>
								<a
									id="support_telegram" onClick={() => { setSocialicon(2) }} className={`support-fb ui-link ${(Socialicon === 2) ? "open" : "null"}`}>
									<img src={Transparent} title="facebook" /></a>
								<a id="support_skype" onClick={() => { setSocialicon(3) }} className={`support-skype ui-link ${(Socialicon === 3) ? "open" : "null"}`} >
									<img src={Transparent} title="Skype" /></a>
								<a
									id="support_instagram" onClick={() => { setSocialicon(4) }} className={`support-ig ui-link ${(Socialicon === 4) ? "open" : "null"}`} >
									<img src={Transparent} title="Instagram" /></a>
							</div>
							<div className="support-info">
								<div id="supportDetail_email" className={`support-detail ${(Socialicon === 0) ? "open" : "null"}`}>
									{Socialicon === 0 && <a className="ui-link" href="https://t.me/bdexch_live"><span>bdexch_live</span></a>}
								</div>

								<div id="supportDetail_whatsapp" className={`support-detail ${(Socialicon === 1) ? "open" : "null"}`} >
									{Socialicon === 1 && <a class="ui-link" href="https://wa.me/0000"><span>0000</span></a>}
									{/* {Socialicon === 1 && <a class="ui-link" href="https://wa.me/000"><span>000</span></a>}
									{Socialicon === 1 && <a class="ui-link" href="https://wa.me/‪000"><span>‪000</span></a>} */}
								</div>
								<div id="supportDetail_telegram" className={`support-detail ${(Socialicon === 2) ? "open" : "null"}`} />
								{Socialicon === 2 && <a class="ui-link" href="#"><span>bdexchofficial</span></a>}
								<div id="supportDetail_skype" className={`support-detail ${(Socialicon === 3) ? "open" : "null"}`} >
									{Socialicon === 3 && <a class="ui-link"><span>bdexchofficial</span></a>}
								</div>
								<div id="supportDetail_instagram" className={`support-detail ${(Socialicon === 4) ? "open" : "null"}`} >
									{Socialicon === 4 && <a class="ui-link"><span>officialbdexch</span></a>}
								</div>
							</div>
						</div>
						{/* <div _ngcontent-njs-c67="" className="support-wrap extend-support">
							<div _ngcontent-njs-c67="" className="extend-btn">
								<img _ngcontent-njs-c67="" src={Transparent} title="customer" className="support-customer" />
								<a _ngcontent-njs-c67="" href="#" target="_blank" className="ui-link">Customer support1</a>
								<a _ngcontent-njs-c67="" href="#" target="_blank" className="split-line ui-link">support2</a>
							</div>
							<div _ngcontent-njs-c67="" className="extend-btn">
								<img _ngcontent-njs-c67="" src={Transparent} title="WhatsApp" className="support-whatsapp" />
								<a _ngcontent-njs-c67="" href="#" target="_blank" className="ui-link">+000000</a>
								<a _ngcontent-njs-c67="" href="#" target="_blank" className="split-line ui-link">+000000</a>
							</div>
							<div _ngcontent-njs-c67="" className="extend-btn">
								<img _ngcontent-njs-c67="" src={Transparent} title="Telegram" className="support-telegram" />
								<a _ngcontent-njs-c67="" href="#" target="_blank" className="ui-link">+0000000</a>
								<a _ngcontent-njs-c67="" href="#" target="_blank" className="split-line ui-link">+000000000</a>
							</div>
							<div _ngcontent-njs-c67="" className="support-social">
								<div _ngcontent-njs-c67="" className="social-btn">
									<img _ngcontent-njs-c67="" src={Transparent} title="Skype" className="support-skype" />
									<a _ngcontent-njs-c67="" href="#" target="_blank" className="ui-link">Skype</a>
								</div>
								<div _ngcontent-njs-c67="" className="social-btn">
									<img _ngcontent-njs-c67="" src={Transparent} title="Email" className="support-mail" />
									<a _ngcontent-njs-c67="" href="#" target="_blank" className="ui-link">Email</a>
								</div>
								<div _ngcontent-njs-c67="" className="social-btn">
									<img _ngcontent-njs-c67="" src={Transparent} title="Instagram" className="support-ig" />
									<a _ngcontent-njs-c67="" href="#" target="_blank" className="ui-link">Instagram</a>
								</div>
							</div>
						</div>
				 */}
					</body>

				</React.Fragment>
			}
			{(firstlogin == 1 || firstlogin == 2) &&

				<FirsttimeLogin password={password} firstlogin={firstlogin} userid={userid} utoken={tt} />

			}
		</React.Fragment>
	)

}
