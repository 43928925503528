import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import moment from 'moment'
import LoadingOverlay from "./components/loading-overlay/loading-overlay";
import InPlayListItem from "./components/in-play-list-item/in-play-list-item";
require('moment-timezone');

export default function Tommorrow(props) {

  const [cricket, setcricket] = useState([]);
  const [Soccer, setSoccer] = useState([]);
  const [Tennis, setTennis] = useState([]);
  const [Basketball, setBasketball] = useState([]);
  const [fancy, setFancy] = useState([]);
  const [refresh, setrefresh] = useState(true);
  const [cricketUpdate, setcricketUpdate] = useState(false);

  var MKPIN = "MKPIN", MKPINSC = "MKPINSC", MKPINTN = "MKPINTN";


  useEffect(() => {

    axios.get(
      `https://liveapi247.live/api8/basketballData`)

      .then(result => {
        result.data.map(item => {
          item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
          return item;
        })
        result.data.sort(function (a, b) {

          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });
        var obj = [];

        result.data.map((item) => {
          var tommorow = moment().add(1, 'day');


          if (moment(item.marketStartTime).isSame(tommorow, 'day')) {
            obj.push(item);
          }


        });


        setBasketball(obj);
      })
      .catch(error => console.log(error));
    axios.get(
      `https://liveapi247.live/api8/cricketData`)

      .then(result => {

        result.data.map(item => {
          item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
          return item;
        })
        result.data.sort(function (a, b) {

          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });
        var obj = [];

        result.data.map((item) => {

          var tommorow = moment().add(1, 'day');


          if (moment(item.marketStartTime).isSame(tommorow, 'day')) {
            obj.push(item);
          }

        });



        setcricket(obj);
      })
      .catch(error => console.log(error));

    axios.get(
      `https://liveapi247.live/api8/fancy`)

      .then(result => {

        setFancy(result.data);

      })


      .catch(error => console.log(error));

    axios.get(
      `https://liveapi247.live/api8/tennisData`)

      .then(result => {
        result.data.map(item => {
          item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
          return item;
        })
        result.data.sort(function (a, b) {

          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });
        var obj = [];

        result.data.map((item) => {
          var tommorow = moment().add(1, 'day');


          if (moment(item.marketStartTime).isSame(tommorow, 'day')) {
            obj.push(item);
          }


        });


        setTennis(obj);
      })
      .catch(error => console.log(error));

    axios.get(
      `https://liveapi247.live/api8/soccerData`)
      .then(result => {
        result.data.map(item => {
          item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
          return item;
        })
        result.data.sort(function (a, b) {
          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });
        var obj = [];

        result.data.map((item) => {
          var tommorow = moment().add(1, 'day');


          if (moment(item.marketStartTime).isSame(tommorow, 'day')) {
            obj.push(item);
          }


        });


        setSoccer(obj);
        setrefresh(false);
      })
      .catch(error => console.log(error));

  }, [])
  useEffect(() => {
    var list = JSON.parse(localStorage.getItem("multi") || "[]");

    if (props.isLoggedIn == true) {

      for (var i = 0; i < cricket.length; i++) {

        if (!document.getElementById(MKPIN + i)) {
          setcricketUpdate(!cricketUpdate);
          return;
        }
        if (list.includes(cricket[i].eventId)) {
          var mkpin = document.getElementById(MKPIN + i);
          mkpin.className = "pin-on"
        }
        else {
          var mkpin = document.getElementById(MKPIN + i);
          mkpin.className = "pin-off";
        }
      }
    }
    else {
      for (var i = 0; i < cricket.length; i++) {

        if (!document.getElementById(MKPIN + i)) {
          setcricketUpdate(!cricketUpdate);
          return;
        }
        if (list.includes(cricket[i].eventId)) {
          var mkpin = document.getElementById(MKPIN + i);
          mkpin.className = "pin-off"
        }
        else {
          var mkpin = document.getElementById(MKPIN + i);
          mkpin.className = "pin-off";
        }
      }
    }

  }, [cricket, cricketUpdate]);

  useEffect(() => {
    var list = JSON.parse(localStorage.getItem("multi") || "[]");

    if (props.isLoggedIn == true) {

      for (var i = 0; i < Soccer.length; i++) {

        if (!document.getElementById(MKPINSC + i)) {
          setcricketUpdate(!cricketUpdate);
          return;
        }
        if (list.includes(Soccer[i].eventId)) {
          var mkpin = document.getElementById(MKPINSC + i);
          mkpin.className = "pin-on"
        }
        else {
          var mkpin = document.getElementById(MKPINSC + i);
          mkpin.className = "pin-off";
        }
      }
    }
    else {
      for (var i = 0; i < Soccer.length; i++) {

        if (!document.getElementById(MKPINSC + i)) {
          setcricketUpdate(!cricketUpdate);
          return;
        }
        if (list.includes(Soccer[i].eventId)) {
          var mkpin = document.getElementById(MKPINSC + i);
          mkpin.className = "pin-off"
        }
        else {
          var mkpin = document.getElementById(MKPINSC + i);
          mkpin.className = "pin-off";
        }
      }
    }

  }, [Soccer, cricketUpdate]);

  useEffect(() => {
    var list = JSON.parse(localStorage.getItem("multi") || "[]");

    if (props.isLoggedIn == true) {

      for (var i = 0; i < Tennis.length; i++) {

        if (!document.getElementById(MKPINTN + i)) {
          setcricketUpdate(!cricketUpdate);
          return;
        }
        if (list.includes(Tennis[i].eventId)) {
          var mkpin = document.getElementById(MKPINTN + i);
          mkpin.className = "pin-on"
        }
        else {
          var mkpin = document.getElementById(MKPINTN + i);
          mkpin.className = "pin-off";
        }
      }
    }
    else {
      for (var i = 0; i < Tennis.length; i++) {

        if (!document.getElementById(MKPINTN + i)) {
          setcricketUpdate(!cricketUpdate);
          return;
        }
        if (list.includes(Tennis[i].eventId)) {
          var mkpin = document.getElementById(MKPINTN + i);
          mkpin.className = "pin-off"
        }
        else {
          var mkpin = document.getElementById(MKPINTN + i);
          mkpin.className = "pin-off";
        }
      }
    }

  }, [Tennis, cricketUpdate]);



  const Multimarketpin = (index, event_id) => {
    if (props.isLoggedIn === false) {
      props.checkShowLogin(true)
    } else {
      var list = JSON.parse(localStorage.getItem("multi") || "[]");
      let ctr = 0;
      for (let i = 0; i < list.length; i++) {
        if (list[i] == event_id) {
          let j = i + 1;
          ctr = 1;
          for (; i < list.length; j++, i++) {
            list[i] = list[j];
          }
          list.splice([i - 1]);
        }
      }
      if (ctr == 0) {
        list.push(event_id);
      }
      localStorage.setItem("multi", JSON.stringify(list));
      setcricketUpdate(!cricketUpdate);

      // console.log('2', list);
      //  localStorage.clear();
    }
  }


  return (

    <React.Fragment>

      <LoadingOverlay
        show={refresh === true}
      />

      {(cricket.length > 0 || Soccer.length > 0 || Tennis.length > 0 || Basketball.length > 0) && refresh === false && <div className="mob-nav-highlight_list" id="inPlayData">

        {cricket.length > 0 && <div id="inPlayEventType_4" style={{ display: 'block' }}>
          <h3>Cricket</h3>
          <ul className="mob-sports-highlight-list" id="inPlayList_4">
            {cricket.map(function (item, id) {

              let showtommorow = false, isFancy = false, showFancy = false, tommorow = moment().add(1, 'day');

              if (moment().diff(moment(item.marketStartTime), 'minutes') >= -30) {
                showFancy = true;
              }

              if (fancy.includes(item.eventId)) {
                isFancy = true;
              }

              if (moment(item.marketStartTime).isSame(tommorow, 'day')) {
                showtommorow = true;
              }


              return (
                <React.Fragment key={id}>

                  {
                    showtommorow
                    &&
                    <InPlayListItem
                      sportsId={4}
                      eventId={item.eventId}
                      marketId={item.marketId}
                      linkOnClick={() => props.changeState(true)}
                      isTv
                      isLowLiquidity
                      isPremium
                      isFancy={isFancy}
                      isBookmaker={isFancy}
                      isFancyClassName={showFancy ? "in-play" : "no-play"}
                      isBookmakerClassName={showFancy ? "in-play" : "no-play"}
                      runnerName1={item.eventName}
                      pinId={MKPIN + id}
                      pinOnClick={() => Multimarketpin(id, item.eventId)}
                      time="Tomorrow"
                      liClassName="inplay-off"
                    />
                  }
                </React.Fragment>
              )
            })}
          </ul>
        </div>}

        {Soccer.length > 0 && <div id="inPlayEventType_1" style={{ display: 'block' }}>
          <h3>Soccer</h3>
          <ul className="mob-sports-highlight-list" id="inPlayList_1">

            {Soccer.map(function (item, id) {
              let showtommorow = false, showFancy = false, tommorow = moment().add(1, 'day');

              if (moment().diff(moment(item.marketStartTime), 'minutes') >= -30) {
                showFancy = true;
              }

              if (moment(item.marketStartTime).isSame(tommorow, 'day')) {
                showtommorow = true;
              }

              return (
                <React.Fragment key={id}>
                  {showtommorow &&
                    <InPlayListItem
                      sportsId={1}
                      eventId={item.eventId}
                      marketId={item.marketId}
                      linkOnClick={() => props.changeState(true)}
                      isTv
                      isPremium
                      runnerName1={item.eventName}
                      pinId={MKPINSC + id}
                      pinOnClick={() => Multimarketpin(id, item.eventId)}
                      time="Tomorrow"
                      liClassName="inplay-off"
                    />
                  }
                </React.Fragment>
              )
            })}

          </ul>
        </div>}


        {Tennis.length > 0 && <div id="inPlayEventType_2" style={{ display: 'block' }}>
          <h3>Tennis</h3>
          <ul className="mob-sports-highlight-list" id="inPlayList_2">

            {Tennis.map(function (item, id) {
              // console.log('rendering');
              let showtommorow = false, showFancy = false, tommorow = moment().add(1, 'day');

              if (moment().diff(moment(item.marketStartTime), 'minutes') >= -30) {
                showFancy = true;
              }

              if (moment(item.marketStartTime).isSame(tommorow, 'day')) {
                showtommorow = true;
              }
              return (
                <React.Fragment key={id}>
                  {showtommorow &&
                    <InPlayListItem
                      sportsId={2}
                      eventId={item.eventId}
                      marketId={item.marketId}
                      linkOnClick={() => props.changeState(true)}
                      isPremium
                      runnerName1={item.eventName}
                      pinId={MKPINTN + id}
                      pinOnClick={() => Multimarketpin(id, item.eventId)}
                      time="Tomorrow"
                      liClassName="inplay-off"
                    />
                  }
                </React.Fragment>
              )
            })}

          </ul>
        </div>}


        {/* {Basketball.length > 0 && <div id="inPlayEventType_2" style={{ display: 'block' }}>
          <h3>Tennis</h3>
          <ul className="mob-sports-highlight-list" id="inPlayList_2">


            {Basketball.map(function (item, id) {
              console.log('rendering');
              var showtommorow = false;


              var showFancy = false;


              if (moment().diff(moment(item.marketStartTime), 'minutes') >= -30) {
                showFancy = true;
              }


              var tommorow = moment().add(1, 'day');


              if (moment(item.marketStartTime).isSame(tommorow, 'day')) {
                showtommorow = true;
              }
              return (
                <React.Fragment key={id}>
                  {showtommorow &&
                    <li  id="inPlayEvent_-10065496" key={id} className="inplay-off">
                      <Link  to={`/d/dash/fullmarket/7522/${item.eventId}/${item.marketId}`} id="info" onClick={() => { props.changeState(true); }}>
                        <dl  >
                          <dt  >

                          <span id="sportsBookIcon_2" className="game-sportsbook" style={{ display: "flex" }}>Premium Cricket</span>


                            <span  id="inPlayStatus_-10065496" className="time">Tomorrow</span>

                          </dt>
                          <dd  id="eventName">{item.eventName}</dd>
                        </dl>
                      </Link>
                      <a  id={MKPINTN + id} className="pin-off" onClick={() => Multimarketpin(id, item.eventId)}></a>
                    </li>
                  }
                </React.Fragment>
              )
            })}

          </ul>
        </div>} */}

      </div>}

      {(cricket.length === 0 && Soccer.length === 0 && Tennis.length === 0 && Basketball.length === 0) && refresh === false &&
        <div id="noMultiMarkets" className="no-data">
          <h3  >There are currently no matches tommorow.</h3>
          <p  >Please check upcoming matches.</p>
        </div>
      }
    </React.Fragment>
  )
}
