import React, { useEffect, useState } from 'react'
//import './style.css'
import axios from 'axios';
import Loading from '../images/sprite/loading40.gif'
import Transparent from '../images/sprite/transparent.gif'

export default function AccountStatement(props) {
   const [Agentid, setAgentid] = useState('');
   const [Coin, setCoin] = useState('');
   const [FixLimit, setFixLimit] = useState('');
   const [CurrentTime, setCurrentTime] = useState('')
   const [accountStatement, setaccountStatement] = useState([])

   useEffect(() => {

      axios.post('https://liveapi247.live/api1/accountstatement', {
         id: props.userid,
         token: props.utoken,

      })
         .then(result => {


            setaccountStatement(result.data)

            //console.log(result);
            //setAgentid(result.data[0].agentId);
            //setCoin(result.data[0].coin);
            //setFixLimit(result.data[0].fixLimit);
            //setCurrentTime(result.data[0].timeCurr);



         }

         ).catch(e => {
            //setIsError(true);
         });

   }, [])

   return (
      <React.Fragment>


         <div _ngcontent-lst-c67="" class="col-left">

            <div _ngcontent-lst-c67="" class="sub_path">

               <div _ngcontent-lst-c67="" class="path">
                  <ul _ngcontent-lst-c67="" >
                     <li _ngcontent-lst-c67="" class="path-last"><a >My Account</a></li>
                  </ul>
               </div>
               <ul _ngcontent-lst-c67="" id="sports-path-popup" class="sports-path-popup">
               </ul>

               <ul _ngcontent-lst-c67="" class="sports-side-menu-list">
                  <li _ngcontent-lst-c67="" ><a id="sub_menu_detail" href="/d/dash/myprofile">My Profile</a></li>
                  <li _ngcontent-lst-c67="" ><a id="sub_menu_summary" href="/d/dash/balance" >Balance Overview</a></li>
                  <li _ngcontent-lst-c67="" ><a id="sub_menu_accountCashStatement_log" href="/d/dash/AccountStatement" class="select">Account Statement
                  </a></li>
                  <li _ngcontent-lst-c67="" ><a id="sub_menu_my_bets" href="/d/dash/mybets">My Bets</a></li>
                  <li _ngcontent-lst-c67="" ><a id="sub_menu_activity_log" href="/d/dash/activitylog">Activity Log</a></li>
                  <li _ngcontent-lst-c67="" ><a id="sub_menu_activity_log" onClick={props.handlepassmodel} >Change password</a></li>
               </ul>
            </div>
         </div>

         <div _ngcontent-lst-c67="" class="centar-content report" >





            <div _ngcontent-lst-c67="" id="message" class="message-content success">
               <a _ngcontent-lst-c67="" class="close-button">Close</a>

            </div>

            <h2 _ngcontent-lst-c67="" >Account Statement</h2>

            <table _ngcontent-lst-c67="" id="table_log" class="table01">
               <tbody><tr>
                  <th _ngcontent-lst-c67="" width="15%" class="align-L">Date/Time</th>
                  <th _ngcontent-lst-c67="" width="18%">Deposit</th>
                  <th _ngcontent-lst-c67="" width="18%">Withdraw</th>
                  <th _ngcontent-lst-c67="" width="18%">Balance</th>
                  <th _ngcontent-lst-c67="" width="16%">Remark</th>
                  <th _ngcontent-lst-c67="" width="">From/To</th>
               </tr>

               </tbody>
               <tbody _ngcontent-lst-c67="" id="content">
                  {accountStatement.map(function (item, index) {

                     var obj1;
                     var obj2;
                     if (item.coin >= 0) {
                        obj1 = parseFloat(item.coin).toFixed(2);
                        obj2 = '-';
                     }
                     if (item.coin < 0) {
                        obj1 = '-';
                        obj2 = parseFloat(item.coin).toFixed(2);
                     }
                     var obj3 = parseFloat(item.creditLimit).toFixed(2);

                     return (
                        <tr _ngcontent-lst-c67="" id="tempTr" key={index}>
                           <td _ngcontent-lst-c67="" id="createDate" class="align-L">{item.currTime}</td>
                           <td _ngcontent-lst-c67="" id="deposit"> {obj1}</td>
                           <td _ngcontent-lst-c67="" id="withdraw">{obj2}</td>
                           <td _ngcontent-lst-c67="" id="balance"> {obj3}</td>
                           <td _ngcontent-lst-c67="" id="remark"></td>
                           <td _ngcontent-lst-c67="" >
                              <spen _ngcontent-lst-c67="" id="from">Master Agent</spen>
                              <img _ngcontent-lst-c67="" class="fromto" src={Transparent} />
                              <spen _ngcontent-lst-c67="" id="to">{item.agentId}</spen>
                           </td>
                        </tr>
                     )
                  })}
               </tbody>
            </table>

            <div _ngcontent-lst-c67="" >

               <ul _ngcontent-lst-c67="" id="pageNumberContent" class="pages">
                  <li _ngcontent-lst-c67="" id="prev">
                     <a _ngcontent-lst-c67="" href="javascript:void(0);" class="disable" style={{ pointerEvents: "none" }}>Prev</a></li>
                  <li _ngcontent-lst-c67="" id="pageNumber">
                     <a _ngcontent-lst-c67="" href="javascript:void(0);" class="select" style={{ pointerEvents: "none" }}>1</a></li>
                  <li _ngcontent-lst-c67="" id="next"><a href="javascript:void(0);" class="disable" style={{ pointerEvents: "none" }}>Next</a></li></ul>
            </div>
         </div>
      </React.Fragment>
   )
}
