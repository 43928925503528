import React, { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import BodyImage from "../images/bdexch/bg-login-skyEX.webp";
import AgeRestrictionDisclaimer from "./AgeRestrictionDisclaimer";

toast.configure();

export default function FirsttimeLogin(props) {
  const [Newpassword, setNewpassword] = useState("");
  const [confirmpassword, setconfirmpassword] = useState("");
  const [password, setpassword] = useState("");
  const [agerest, setagerest] = useState(props.firstlogin);


  const ChangePassword = (e) => {
    e.preventDefault();

    // var demoid1 = 'demo01';
    // //var demoid2 = 'demo02'
    // if(props.userid.toUpperCase() === demoid1.toUpperCase()){

    //   toast.warn("Demo account password can't be changed", {
    //     position: toast.POSITION.TOP_CENTER,
    //   });
    //   return;
    // }
    var strongRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,15}$/;
    var test = Newpassword.match(strongRegex);

    if (Newpassword === "" || confirmpassword === "" || password === "") {
      toast.warn("Password can not be Blank!", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else if (Newpassword != confirmpassword) {
      toast.warn(" password is not matched!", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else if (Newpassword === password) {
      toast.warn("Old password and new password are not same!", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else if (!test) {
      toast.warn(
        "Password must have 8 to 15 alphanumeric without white space; cannot be the same as username/nickname; must contain at least 1 capital letter, small letter and numbers; and must not contain any special characters (!,@,#,etc..)",
        { position: toast.POSITION.TOP_CENTER }
      );
    } else {
      axios
        .post("https://liveapi247.live/api1/changepassword", {
          id: props.userid,
          //token:props.utoken,
          newPassword: Newpassword,
          oldPassword: password,
        })
        .then((result) => {
          if (result.status === 210) {
            toast.success("Password Changed Successfully", {
              position: toast.POSITION.TOP_CENTER,
            });

            setagerest(2);
          }
          if (result.status === 220) {
            toast.warn("Your old password is wrong", {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        })
        .catch((e) => {
          //setIsError(true);
        });
    }
  };
  return (
    <React.Fragment>
      {agerest == 1 && (
        <React.Fragment>
          <body _ngcontent-njs-c64
            style={{
              background: `url(${BodyImage}) no-repeat center`,
              backgroundSize: "cover",
            }}
          >
            <div _ngcontent-njs-c64

              class="maintain-wrap"
              style={{
                top: "12%",
                position: "absolute",
                borderRadius: "8px",
                boxShadow: "0 5px 20px rgba(0,0,0,0.5)",
                width: "480px",
                left: "calc(50% - 480px/2)",
                height: "auto",
                backgroundColor: "rgb(122 50 209)",
              }}
            >
              <div _ngcontent-njs-c64 class="maintain-content change_pass">
                <ul _ngcontent-njs-c64 class="note">
                  <li _ngcontent-njs-c64>
                    Password must have 8 to 15 alphanumeric without white space
                  </li>
                  <li _ngcontent-njs-c64>Password cannot be the same as username/nickname</li>
                  <li _ngcontent-njs-c64>
                    Must contain at least 1 capital letter, 1 small letter and 1
                    number
                  </li>
                  <li _ngcontent-njs-c64>
                    Password must not contain any special characters
                    (!,@,#,etc..)
                  </li>
                </ul>

                <dl _ngcontent-njs-c64 class="login-box-content">
                  <form _ngcontent-njs-c64 name="passwordPinForm">
                    <dt _ngcontent-njs-c64>Change Password</dt>
                    <dd _ngcontent-njs-c64>
                      <input _ngcontent-njs-c64
                        id="newPwd"
                        type="password"
                        value={Newpassword}
                        onChange={(e) => {
                          setNewpassword(e.target.value);
                        }}
                        placeholder="New Password"
                      />
                    </dd>
                    <dd _ngcontent-njs-c64>
                      <input _ngcontent-njs-c64
                        id="confirmPwd"
                        type="password"
                        value={confirmpassword}
                        onChange={(e) => {
                          setconfirmpassword(e.target.value);
                        }}
                        placeholder="New Password Confirm"
                      />
                    </dd>
                    <dd _ngcontent-njs-c64>
                      <input _ngcontent-njs-c64
                        id="currentPwd"
                        type="password"
                        value={password}
                        onChange={(e) => {
                          setpassword(e.target.value);
                        }}
                        placeholder="Old Password"
                      />
                    </dd>
                    <dd _ngcontent-njs-c64>
                      <a _ngcontent-njs-c64
                        onClick={(e) => {
                          ChangePassword(e);
                        }}
                        id="submit"
                        class="btn-send"
                      >
                        Change
                      </a>
                    </dd>
                  </form>
                </dl>
              </div>
              <div _ngcontent-njs-c64 class="maintain-footer" style={{ background: '#000' }}>
                <h1 _ngcontent-njs-c64 style={{ height: '58px', width: '113px', backgroundSize: 'contain' }}></h1>
              </div>
            </div>
          </body>
        </React.Fragment>
      )}
      {agerest == 2 && (
        <AgeRestrictionDisclaimer
          firstlogin={props.firstlogin}
          password={props.password}
          Newpassword={Newpassword}
        />
      )}
    </React.Fragment>
  );
}
