import React from 'react';
import queryString from "query-string";
import {getCasinoObj} from "../../../casino-utils";

const CasinoCard = ({ title, navKey, img, isLoggedIn, history, openLogin, className, style}) => (
    <a
        className={className}
        onClick={() => navKey ? isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj(navKey))}`) : openLogin() : null}
        style={style ? style : {gridColumn: 'span 2'}}>
        <dl className="casino-title">
            <dt>{title}</dt>
            <dd><span>Play Now</span></dd>
        </dl>
        <img src={img} alt={title} draggable="false"/>
    </a>
)

export default CasinoCard;
