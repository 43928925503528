import React, { useState, useEffect } from 'react'
import Transparent from '../images/sprite/transparent.gif'
import axios from 'axios'
import { Link } from "react-router-dom";
import moment from 'moment'
import Cookies from 'universal-cookie';
require('moment-timezone');

const cookies = new Cookies();

export default function Kabaddi(props) {

  const [match, setMatch] = useState([]);
  const [newrefresh, setnewrefresh] = useState(true);

  useEffect(() => {

    axios.get(
      `https://liveapi247.live/api1/kabaddiData`)

      .then(result => {
        result.data.map(item => {
          item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
          return item;
        })
        result.data.sort(function (a, b) {

          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });
        setMatch(result.data);
        if (newrefresh) {
          setnewrefresh(false);
        }
      })
      .catch(error => console.log(error));

  }, []);


  return (

    <React.Fragment>

      {newrefresh === true &&
        <div _ngcontent-njs-c67="" class="loading-overlay" id="loading">
          <div _ngcontent-njs-c67="" class="loading-wrap" style={{ display: " flex" }}>
            <div _ngcontent-njs-c67="" class="loading">
              <div></div>
              <div></div>
            </div>
            <p>Loading...</p>
          </div>
        </div>}



      {newrefresh === false && <div>

        {match.map(function (item, id) {

          var showtv = false;
          var isbookmaker = false;

          var showInplay = false

          var matchTime;
          var today = moment();
          var tommorow = moment().add(1, 'day');

          if (moment(item.marketStartTime).isSame(today, 'day')) {
            matchTime = moment(item.marketStartTime).format('LT');
          }
          else if (moment(item.marketStartTime).isSame(tommorow, 'day')) {
            matchTime = 'Tommorow' + ' ' + moment(item.marketStartTime).format('LT');;
          }
          else {
            matchTime = item.marketStartTime;
          }

          if (moment().diff(moment(item.marketStartTime), 'seconds') >= 0) {
            showInplay = true;
          }
          return (

            <li _ngcontent-njs-c67="" key={id} id="highlightEvent_29869541" className={`inplay${showInplay ? "-on" : "-off"}`}>
              <Link to={`/d/dash/fullmarket/138/${item.eventId}/${item.marketId}`} onClick={() => { props.changeState(true); }}>
                <dl>
                  <dt>
                    <span _ngcontent-njs-c67="" id="fancyBetIcon" className="game-fancy in-play" style={{ display: "flex" }}><pre>in-play</pre>Fancy</span>
                    {moment() >= moment(item.marketStartTime) &&
                      <span _ngcontent-njs-c67="" id="dateTimeInfo" className="time">In-play</span>}

                    {moment() < moment(item.marketStartTime) &&
                      <span _ngcontent-njs-c67="" id="dateTimeInfo" className="time">{matchTime}</span>}
                  </dt>
                  <dd _ngcontent-njs-c67="" id="eventName">{item.eventName}</dd>
                </dl>
              </Link>
              <a _ngcontent-njs-c67="" class="pin-off" ></a>
            </li>

          )
        })}
      </div>}
    </React.Fragment>
  )
}
